import apiCall from './apiCall.js'
import buildGetQuery from './buildGetQuery.js'

// Handles all user requests.
class User {
  static all (token, params) {
    const data = buildGetQuery(params)
    return apiCall('GET', 'api/v1/users', token, data)
  }

  static findById (token, id) {
    return apiCall('GET', `api/v1/users/${id}`, token)
  }

  static findByName (token, name) {
    const data = buildGetQuery({ name: name })
    return apiCall('GET', 'api/v1/users', token, data)
  }

  static update (token, id, params) {
    const data = { user: params }
    return apiCall('PATCH', `api/v1/users/${id}`, token, data)
  }

  static destroy (token, id) {
    return apiCall('DELETE', `api/v1/users/${id}`, token)
  }
}

export default User
