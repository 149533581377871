import apiCall from './apiCall.js'
import buildGetQuery from './buildGetQuery.js'

// Handles all tag requests.
class Tag {
  static all (params) {
    const data = buildGetQuery(params)
    return apiCall('GET', 'api/v1/tags', null, data)
  }

  static show (id) {
    return apiCall('GET', `api/v1/tags/${id}`)
  }

  static create (token, params) {
    const data = { tag: params }
    return apiCall('POST', 'api/v1/tags', token, data)
  }

  static update (token, id, params) {
    const data = { tag: params }
    return apiCall('PATCH', `api/v1/tags/${id}`, token, data)
  }

  static destroy (token, id) {
    return apiCall('DELETE', `api/v1/tags/${id}`, token)
  }
}

export default Tag
