import React from 'react'
import './StandardLayout.css'

import ErrorMessage from '../ErrorMessage/ErrorMessage.js'
import Navbar from '../NavBar/NavBar.js'
import PageFooter from '../PageFooter/PageFooter.js'

// Basically just wraps a given page to add the standard navbar, etc.
function StandardLayout (props) {
  const e = React.createElement

  return (
    e('div', { className: 'standard-layout' },
      e(Navbar, props),
      e(ErrorMessage, { message: props.error, onClose: props.onClearError }),
      e('div', { className: 'standard-layout-content' }, props.children),
      e(PageFooter, props)
    )
  )
}

export default StandardLayout
