import React from 'react'
import './ChangePasswordForm.css'

import SimpleButton from '../SimpleButton/SimpleButton.js'
import InputLabel from '../InputLabel/InputLabel.js'
import SimpleInput from '../SimpleInput/SimpleInput.js'

import AccountApi from '../../api/Account.js'

// writeme
class ChangePasswordForm extends React.Component {
  constructor (props) {
    super(props)

    this.state = {
      oldPassword: '',
      newPassword: '',
      confirm: ''
    }

    this.handleOldPasswordChange = this.handleOldPasswordChange.bind(this)
    this.handleNewPasswordChange = this.handleNewPasswordChange.bind(this)
    this.handleConfirmChange = this.handleConfirmChange.bind(this)
    this.handleSubmit = this.handleSubmit.bind(this)
  }

  handleOldPasswordChange (event) {
    this.setState({ oldPassword: event.target.value })
  }

  handleNewPasswordChange (event) {
    this.setState({ newPassword: event.target.value })
  }

  handleConfirmChange (event) {
    this.setState({ confirm: event.target.value })
  }

  handleSubmit (event) {
    event.preventDefault()

    if (this.state.newPassword !== this.state.confirm) {
      return this.props.onError({ error: 'Confirmation does not match' })
    }

    const params = {
      current_password: this.state.oldPassword,
      password: this.state.newPassword
    }

    AccountApi.update(this.props.token, params).then(
      () => this.setState({ oldPassword: '', newPassword: '', confirm: '' }),
      this.props.onError
    )
  }

  render () {
    const e = React.createElement

    return (
      e('form', { className: 'change-password-form', onSubmit: this.handleSubmit },
        e('div', { className: 'change-password-form-old-password' },
          e(InputLabel, { label: 'Old password' },
            e(SimpleInput, {
              type: 'password',
              value: this.state.oldPassword,
              onChange: this.handleOldPasswordChange
            })
          )
        ),
        e('div', { className: 'change-password-form-new-password' },
          e(InputLabel, { label: 'New password' },
            e(SimpleInput, {
              type: 'password',
              value: this.state.newPassword,
              onChange: this.handleNewPasswordChange
            })
          )
        ),
        e('div', { className: 'change-password-form-confirm' },
          e(InputLabel, { label: 'Confirm password' },
            e(SimpleInput, {
              type: 'password',
              value: this.state.confirm,
              onChange: this.handleConfirmChange
            })
          )
        ),
        e('div', { className: 'change-password-form-submit' },
          e(SimpleButton, {
            theme: 'primary',
            disabled: !this.state.newPassword
          }, 'Save')
        )
      )
    )
  }
}

export default ChangePasswordForm
