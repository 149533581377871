import React from 'react'
import './StaffSearch.css'

import StaffCard from '../StaffCard/StaffCard'
import InputLabel from '../InputLabel/InputLabel.js'
import PleaseWait from '../PleaseWait/PleaseWait.js'
import SimpleButton from '../SimpleButton/SimpleButton.js'
import SimpleInput from '../SimpleInput/SimpleInput.js'

import safeConcat from '../../helpers/safeConcat.js'

import StaffApi from '../../api/Staff.js'

// writeme
class StaffSearch extends React.Component {
  constructor (props) {
    super(props)

    this.state = {
      search: '',
      page: 0,
      staff: null,
      more: false,
      loading: true
    }

    this.fetchData = this.fetchData.bind(this)
    this.handleSearchChange = this.handleSearchChange.bind(this)
    this.handleMore = this.handleMore.bind(this)
  }

  componentDidMount () {
    this.fetchData()
  }

  componentWillUnmount () {
    window.clearTimeout(this.typingTimeout)
  }

  fetchData () {
    const perPage = 18

    const params = {
      media: this.props.mediaId || '',
      search: this.state.search,
      offset: this.state.page * perPage,
      limit: perPage
    }

    this.setState({ loading: true })
    StaffApi.all(params).then(
      json => {
        const data = safeConcat(this.state.staff, json.items)
        const more = json.items.length === perPage
        this.setState({ staff: data, more: more, loading: false })
      },
      this.props.onError
    )
  }

  handleSearchChange (event) {
    this.setState({ search: event.target.value })

    // Wait for a short time, to verify that the user has finished typing:
    window.clearTimeout(this.typingTimeout)
    this.typingTimeout = window.setTimeout(() => {
      this.setState(
        { page: 0, staff: null, more: false },
        this.fetchData
      )
    }, 500)
  }

  handleMore () {
    this.setState({ page: this.state.page + 1 }, this.fetchData)
  }

  render () {
    const items = this.state.staff ? (
      this.state.staff.map(staff => (
        <StaffCard
          key={staff.id}
          staff={staff}
          mediaId={this.props.mediaId}
          onClick={() => this.props.onClick(staff)}
        />
      ))
    ) : <PleaseWait />

    const moreContent = this.state.more ? (
      <SimpleButton
        disabled={this.state.loading}
        onClick={this.handleMore}
      >
        More...
      </SimpleButton>
    ) : null

    return (
      <div className='staff-search'>
        <div className='staff-search-filter'>
          <div className='staff-search-filter-search'>
            <InputLabel label='Search'>
              <SimpleInput
                label='Search'
                value={this.state.search}
                autoFocus={true}
                onChange={this.handleSearchChange}
              />
            </InputLabel>
          </div>
          <div className='staff-search-extras'>{this.props.children}</div>
        </div>
        <div className='staff-search-items'>{items}</div>
        <div className='staff-search-more'>{moreContent}</div>
      </div>
    )
  }
}

export default StaffSearch
