import React from 'react'
import './CharacterRoleForm.css'

import InputLabel from '../InputLabel/InputLabel'
import SimpleButton from '../SimpleButton/SimpleButton'
import SimpleDropdown from '../SimpleDropdown/SimpleDropdown'
import SimpleInput from '../SimpleInput/SimpleInput'

import { associatedCharacterKinds } from '../../helpers/commonEnums'
import localName from '../../helpers/localName'

// writeme
function CharacterRoleForm (props) {
  if (props.role.destroy) { return null }

  return (
    <div className='character-role-form'>
      <div className='character-role-form-media'>
        <InputLabel label='Appears in'>
          <SimpleInput value={localName(props.role.media)} disabled={true} />
        </InputLabel>
      </div>
      <div className='character-role-form-kind'>
        <InputLabel label='Role'>
          <SimpleDropdown
            value={props.role.kind}
            options={associatedCharacterKinds}
            onChange={props.onChange}
          />
        </InputLabel>
      </div>
      <div className='character-role-form-remove'>
        <SimpleButton theme='delete' onClick={props.onRemove}>
          Remove
        </SimpleButton>
      </div>
    </div>
  )
}

export default CharacterRoleForm
