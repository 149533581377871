import React from 'react'
import './CreateUserReportPage.css'

// Components:
import SimpleButton from '../../components/SimpleButton/SimpleButton.js'
import InputLabel from '../../components/InputLabel/InputLabel.js'
import PleaseWait from '../../components/PleaseWait/PleaseWait.js'
import SimpleInput from '../../components/SimpleInput/SimpleInput.js'
import StatusPost from '../../components/StatusPost/StatusPost.js'

// APIs:
import StatusPostApi from '../../api/StatusPost.js'
import UserReportApi from '../../api/UserReport.js'

// writeme
class CreateUserReportPage extends React.Component {
  constructor (props) {
    super(props)

    this.state = {
      statusPost: null,
      reason: ''
    }

    this.handleReasonChange = this.handleReasonChange.bind(this)
    this.handleSubmit = this.handleSubmit.bind(this)
  }

  componentDidMount () {
    StatusPostApi.show(this.props.token, this.props.id).then(
      json => this.setState({ statusPost: json }),
      this.props.onError
    )
  }

  handleReasonChange (event) {
    this.setState({ reason: event.target.value })
  }

  handleSubmit () {
    const params = {
      statusPostId: this.props.id,
      reason: this.state.reason
    }

    UserReportApi.create(this.props.token, params).then(
      () => this.props.onPageChange(),
      this.props.onError
    )
  }

  render () {
    const e = React.createElement

    const statusPost = this.state.statusPost ? (
      e(StatusPost, {
        post: this.state.statusPost,
        onPageChange: this.props.onPageChange
      })
    ) : e(PleaseWait)

    return (
      e('div', { className: 'create-user-report-page' },
        e('div', { className: 'create-user-report-page-reason' },
          e(InputLabel, { label: 'Why are you reporting this post?' },
            e(SimpleInput, {
              value: this.state.reason,
              onChange: this.handleReasonChange,
              autoFocus: true
            })
          )
        ),
        e('div', { className: 'create-user-report-page-submit' },
          e(SimpleButton, { onClick: this.handleSubmit, theme: 'primary' }, 'Report')
        ),
        e('div', { className: 'create-user-report-page-cancel' },
          e(SimpleButton, { onClick: () => this.props.onPageChange() }, 'Cancel')
        ),
        e('div', { className: 'create-user-report-page-status-post' },
          statusPost
        )
      )
    )
  }
}

export default CreateUserReportPage
