import apiCall from './apiCall.js'
import buildGetQuery from './buildGetQuery.js'
import buildFormData from './buildFormData.js'

// Handles all staff requests.
class Staff {
  static all (params) {
    const data = buildGetQuery(params)
    return apiCall('GET', 'api/v1/staff', null, data)
  }

  static show (id) {
    return apiCall('GET', `api/v1/staff/${id}`)
  }

  static create (token, params) {
    const data = buildFormData('staff', params)
    return apiCall('POST', 'api/v1/staff', token, data)
  }

  static update (token, id, params) {
    const data = buildFormData('staff', params)
    return apiCall('PATCH', `api/v1/staff/${id}`, token, data)
  }

  static destroy (token, id) {
    return apiCall('DELETE', `api/v1/staff/${id}`, token)
  }
}

export default Staff
