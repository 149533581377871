import React from 'react'

// Components:
import PleaseWait from './components/PleaseWait/PleaseWait.js'
import SiteRouter from './SiteRouter.js'

// APIs:
import ProfileApi from './api/Profile.js'

// This is the top-level Application component; it basically just manages JWTs.
class App extends React.Component {
  constructor (props) {
    super(props)

    this.state = {
      user: null,
      token: null,
      error: null,
      loading: true
    }

    this.handleError = this.handleError.bind(this)
    this.handleClearError = this.handleClearError.bind(this)
    this.handleUserChange = this.handleUserChange.bind(this)
    this.handleLogIn = this.handleLogIn.bind(this)
    this.handleLogOut = this.handleLogOut.bind(this)
  }

  // If we're re-loading a page after a previous visit, restore the token/user:
  componentDidMount () {
    const oldToken = localStorage.getItem('token')
    if (!oldToken) { return this.setState({ loading: false }) }

    ProfileApi.show(oldToken).then(
      json => this.setState({ loading: false, user: json, token: oldToken }),
      () => this.setState({ loading: false }) // Just ignore the error JSON.
    )
  }

  handleError (json) {
    // If our token has expired, clear it (and the user data) from our state:
    if (json.error === 'You are not logged in') { this.handleLogOut() }

    // Errors come in two forms: A single message, or an array of strings:
    this.setState({ error: json.error || json.errors.join(' / ') })
  }

  handleClearError () {
    this.setState({ error: null })
  }

  handleUserChange (json) {
    this.setState({ user: json })
  }

  handleLogIn (json) {
    this.setState({ user: json, token: json.token })
    localStorage.setItem('token', json.token)
  }

  handleLogOut () {
    this.setState({ user: null, token: null })
    localStorage.clear()
  }

  render () {
    const e = React.createElement

    // Avoid displaying pages where API calls may return user-specific content:
    if (this.state.loading) { return e(PleaseWait) }

    return (
      e('div', { className: 'app' },
        e(SiteRouter, {
          currentUser: this.state.user,
          error: this.state.error,
          token: this.state.token,
          onClearError: this.handleClearError,
          onError: this.handleError,
          onLogIn: this.handleLogIn,
          onLogOut: this.handleLogOut,
          onUserChange: this.handleUserChange
        })
      )
    )
  }
}

export default App
