/* eslint camelcase: 0 */

// Contains various enums which are unlikely to change, to avoid API requests.

const animeSubtypes = ['tv', 'movie', 'ova', 'ona', 'music', 'special']
const mangaSubtypes = ['published', 'light_novel', 'doujin', 'oneshot']

const mediaCountries = ['jp', 'kr', 'cn'] // A limited subset of ISO-3166.
const mediaKinds = ['anime', 'manga']
const mediaSortOptions = ['name', 'score', 'dateAdded']
const mediaStatuses = ['unreleased', 'releasing', 'complete', 'cancelled']

const associatedCharacterKinds = ['main', 'supporting', 'cameo']

const associatedMediaKinds = [
  'sequel',
  'spin_off',
  'alternative',
  'adaptation',
  'compilation'
]

const entryStatuses = [
  'planning',
  'in_progress',
  'paused',
  'complete',
  'dropped',
  'ignored'
]

// 2-character language codes, used for specifying the language of a name:
const iso639_1 = [
  'aa', 'ab', 'ae', 'af', 'ak', 'am', 'an', 'ar', 'as', 'av', 'ay', 'az', 'ba',
  'be', 'bg', 'bh', 'bi', 'bm', 'bn', 'bo', 'br', 'bs', 'ca', 'ce', 'ch', 'co',
  'cr', 'cs', 'cu', 'cv', 'cy', 'da', 'de', 'dv', 'dz', 'ee', 'el', 'en', 'eo',
  'es', 'et', 'eu', 'fa', 'ff', 'fi', 'fj', 'fo', 'fr', 'fy', 'ga', 'gd', 'gl',
  'gn', 'gu', 'gv', 'ha', 'he', 'hi', 'ho', 'hr', 'ht', 'hu', 'hy', 'hz', 'ia',
  'id', 'ie', 'ig', 'ii', 'ik', 'io', 'is', 'it', 'iu', 'ja', 'jv', 'ka', 'kg',
  'ki', 'kj', 'kk', 'kl', 'km', 'kn', 'ko', 'kr', 'ks', 'ku', 'kv', 'kw', 'ky',
  'la', 'lb', 'lg', 'li', 'ln', 'lo', 'lt', 'lu', 'lv', 'mg', 'mh', 'mi', 'mk',
  'ml', 'mn', 'mr', 'ms', 'mt', 'my', 'na', 'nb', 'nd', 'ne', 'ng', 'nl', 'nn',
  'no', 'nr', 'nv', 'ny', 'oc', 'oj', 'om', 'or', 'os', 'pa', 'pi', 'pl', 'ps',
  'pt', 'qu', 'rm', 'rn', 'ro', 'ru', 'rw', 'sa', 'sc', 'sd', 'se', 'sg', 'si',
  'sk', 'sl', 'sm', 'sn', 'so', 'sq', 'sr', 'ss', 'st', 'su', 'sv', 'sw', 'ta',
  'te', 'tg', 'th', 'ti', 'tk', 'tl', 'tn', 'to', 'tr', 'ts', 'tt', 'tw', 'ty',
  'ug', 'uk', 'ur', 'uz', 've', 'vi', 'vo', 'wa', 'wo', 'xh', 'yi', 'yo', 'za',
  'zh', 'zu'
]

export {
  animeSubtypes,
  associatedCharacterKinds,
  associatedMediaKinds,
  iso639_1,
  mangaSubtypes,
  mediaCountries,
  mediaKinds,
  mediaSortOptions,
  mediaStatuses,
  entryStatuses
}
