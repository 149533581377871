import React from 'react'
import './Avatar.css'

import RemoteImage from '../RemoteImage/RemoteImage.js'

// Represents a user's profile picture.
function Avatar (props) {
  const e = React.createElement

  return e(RemoteImage, {
    className: props.large ? 'avatar large' : 'avatar small',
    remotePath: props.user.avatar,
    fallback: 'avatar.png',
    alt: props.user.name
  })
}

export default Avatar
