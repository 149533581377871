import React from 'react'
import './MarkdownContent.css'

import autolinkNames from '../../helpers/autolinkNames'
import parseMarkdown from '../../helpers/parseMarkdown'

// Converts markdown code into HTML via CommonMark.
class MarkdownContent extends React.Component {
  constructor (props) {
    super(props)

    this.content = React.createRef()
  }

  componentDidMount () { this.specialCases() }
  componentDidUpdate () { this.specialCases() }

  specialCases () {
    const elem = this.content.current
    if (!elem) { return }

    Array.prototype.forEach.call(elem.querySelectorAll('a[href]'), a => {
      a.target = '_blank' // All "general" links should open in new tabs...
      a.rel = 'noopener' // ...and don't give those tabs access to our DOM!

      // Extra logic for `<https://example.com>` links but not `[these](ones)`:
      if (a.innerText === a.href) {
        this.tryYoutubeEmbed(a) // Replace links to YouTube with video embeds.
      }
    })

    Array.prototype.forEach.call(elem.querySelectorAll('img[alt]'), img => {
      if (/^\d+$/.test(img.alt)) { img.width = img.alt } // A number = width.
    })

    autolinkNames(elem)
  }

  tryYoutubeEmbed (a) {
    let v = null

    if (a.href.startsWith('https://www.youtube.com/watch?')) {
      // https://www.youtube.com/watch?v=_g15ANx5T7s&feature=emb_logo
      v = new URL(a.href).searchParams.get('v')
    } else if (a.href.startsWith('https://youtu.be/')) {
      // https://youtu.be/BW3D9WwalQE?t=1017
      v = new URL(a.href).pathname.slice(1)
    }

    if (!v) { return false }
    /*
    const features = [
      'accelerometer',
      'autoplay',
      'encrypted-media',
      'gyroscope',
      'picture-in-picture'
    ]
    */
    const embed = document.createElement('iframe')
    embed.width = 560
    embed.height = 315
    embed.src = `https://www.youtube-nocookie.com/embed/${v}`
    embed.frameBorder = 0
    // embed.allow = features.join('; ')
    embed.allowFullscreen = true

    a.parentElement.replaceChild(embed, a)
    return true
  }

  render () {
    if (!this.props.text) { return null }

    const e = React.createElement
    return (
      e('div', {
        ref: this.content,
        className: 'markdown-content',
        dangerouslySetInnerHTML: { __html: parseMarkdown(this.props.text) }
      })
    )
  }
}

export default MarkdownContent
