import React from 'react'

import EditCharacterForm from '../../components/EditCharacterForm/EditCharacterForm'
import PleaseWait from '../../components/PleaseWait/PleaseWait'

import CharacterApi from '../../api/Character'

// writeme
class EditCharacterPage extends React.Component {
  constructor (props) {
    super(props)

    this.state = { character: null }

    this.handleSubmit = this.handleSubmit.bind(this)
  }

  componentDidMount () {
    CharacterApi.show(this.props.id).then(
      json => this.setState({ character: json }),
      this.props.onError
    )
  }

  handleSubmit (params) {
    CharacterApi.update(this.props.token, this.props.id, params).then(
      json => this.props.onPageChange('characters', json.id),
      this.props.onError
    )
  }

  render () {
    if (!this.state.character) { return <PleaseWait /> }

    return (
      <div className='edit-character-page'>
        <EditCharacterForm
          character={this.state.character}
          onSubmit={this.handleSubmit}
          onCancel={() => this.props.onPageChange('characters', this.props.id)}
        />
      </div>
    )
  }
}

export default EditCharacterPage
