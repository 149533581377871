import React from 'react'

import MediaAssociationForm from '../MediaAssociationForm/MediaAssociationForm'
import PleaseWait from '../PleaseWait/PleaseWait'
import SimpleButton from '../SimpleButton/SimpleButton'

import MediaApi from '../../api/Media'

// writeme
class EditAssociatedMediaForm extends React.Component {
  constructor (props) {
    super(props)

    this.state = { associations: null }

    this.handleChange = this.handleChange.bind(this)
    this.handleRemove = this.handleRemove.bind(this)
    this.handleSubmit = this.handleSubmit.bind(this)
  }

  componentDidMount () {
    MediaApi.associatedMedia(this.props.mediaId).then(
      json => {
        const associations = json.map(association => ({
          id: association.id,
          media: association.media,
          kind: association.kind,
          inverse: association.inverse,
          destroy: false
        }))
        this.setState({ associations: associations })
      },
      this.props.onError
    )
  }

  handleChange (idx, newKind) {
    const newData = this.state.associations.map((association, num) => (
      { ...association, kind: num === idx ? newKind : association.kind }
    ))

    this.setState({ associations: newData })
  }

  handleRemove (idx) {
    const newData = this.state.associations.map((association, num) => (
      { ...association, destroy: association.destroy || num === idx }
    ))

    this.setState({ associations: newData })
  }

  handleSubmit () {
    const data = this.state.associations.filter(a => !a.inverse).map(link => ({
      id: link.id,
      kind: link.kind,
      _destroy: link.destroy
    }))

    const params = { associatedMediaAttributes: data }
    MediaApi.update(this.props.token, this.props.mediaId, params).then(
      this.props.onComplete,
      this.props.onError
    )
  }

  render () {
    if (!this.state.associations) { return <PleaseWait /> }

    const items = this.state.associations.map((association, idx) => (
      <MediaAssociationForm
        key={association.id}
        association={association}
        onChange={(kind) => this.handleChange(idx, kind)}
        onRemove={() => this.handleRemove(idx)}
      />
    ))

    return (
      <div className='edit-associated-media-form'>
        <div className='edit-associated-media-form-items'>
          {items}
        </div>
        <div className='edit-associated-media-form-actions'>
          <SimpleButton onClick={this.props.onComplete}>
            Cancel
          </SimpleButton>
          <SimpleButton onClick={this.handleSubmit} theme='primary'>
            Save changes
          </SimpleButton>
        </div>
      </div>
    )
  }
}

export default EditAssociatedMediaForm
