import React from 'react'
import './UserReportSummary.css'

// writeme
function UserReportSummary (props) {
  const e = React.createElement

  const statusPost = props.report.statusPost
  const threadId = statusPost.parentId || statusPost.id
  const badUser = props.report.statusPost.user
  const niceUser = props.report.user

  return (
    e('div', { className: 'user-report-summary' },
      e('button', {
        className: 'user-report-summary-link',
        onClick: () => props.onPageChange('status-posts', threadId)
      }, `Status Post ID: ${statusPost.id}`),
      e('button', {
        className: 'user-report-summary-poster',
        onClick: () => props.onPageChange('users', badUser.name)
      }, `Posted by ${badUser.name}`),
      e('button', {
        className: 'user-report-summary-reporter',
        onClick: () => props.onPageChange('users', niceUser.name)
      }, `Reported by ${niceUser.name}`),
      e('button', {
        className: 'user-report-summary-dismiss',
        onClick: props.onDismiss
      }, 'Dismiss'),
      e('div', { className: 'user-report-summary-reason' },
        props.report.reason
      )
    )
  )
}

export default UserReportSummary
