import React from 'react'
import './CharacterRole.css'

import localName from '../../helpers/localName'

// writeme
function CharacterRole (props) {
  const nsfw = props.role.media.adult

  return (
    <button className='character-role' onClick={props.onClick}>
      <div className='character-role-media-name'>
        {localName(props.role.media)}
      </div>
      <div className='character-role-media-kind'>
        {props.role.media.kind}
      </div>
      <div className='character-role-kind'>
        {props.role.kind}
      </div>
      <div className={`character-role-nsfw ${nsfw ? 'yes' : 'no'}`}>
        {nsfw ? '🔞' : ''}
      </div>
    </button>
  )
}

export default CharacterRole
