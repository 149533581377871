import React from 'react'
import './ReviewSummary.css'

// A summary of a review for "neutral" contexts (i.e. no obvious user/media).
function ReviewSummary (props) {
  const e = React.createElement

  return (
    e('div', { className: 'review-summary' },
      e('button', {
        className: 'summary',
        onClick: () => props.onPageChange('reviews', props.review.id)
      }, props.review.summary),
      e('button', {
        className: 'user-name',
        onClick: () => props.onPageChange('users', props.review.user.name)
      }, props.review.user.name)
    )
  )
}

export default ReviewSummary
