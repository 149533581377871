import React from 'react'
import './UserCard.css'

import Avatar from '../Avatar/Avatar.js'

// writeme
function UserCard (props) {
  const e = React.createElement

  return (
    e('div', { className: 'user-card' },
      e('button', {
        className: 'user-card-avatar',
        onClick: () => props.onPageChange('users', props.user.name)
      },
      e(Avatar, { user: props.user, large: true })
      ),
      e('button', {
        className: 'user-card-name',
        onClick: () => props.onPageChange('users', props.user.name)
      },
      props.user.name
      )
    )
  )
}

export default UserCard
