// Picks the "preferred" name from a list of possible alternatives.
function localName (media) {
  const native = { cn: 'zh', jp: 'ja', kr: 'ko' }[media.country]

  const localName = media.names.find(n => n.language === 'en')
  const latinName = media.names.find(n => n.language === native && n.latin)
  const nativeName = media.names.find(n => n.language === native && !n.latin)

  return (localName || latinName || nativeName || { text: '?????' }).text
}

export default localName
