import React from 'react'
import './EditReviewForm.css'

import SimpleButton from '../SimpleButton/SimpleButton.js'
import InputLabel from '../InputLabel/InputLabel.js'
import MarkdownEditor from '../MarkdownEditor/MarkdownEditor.js'
import SimpleInput from '../SimpleInput/SimpleInput.js'

import localName from '../../helpers/localName.js'

import MediaApi from '../../api/Media.js'

// writeme
class EditReviewForm extends React.Component {
  constructor (props) {
    super(props)

    // If we're editing an existing review, use its details to set defaults:
    this.state = {
      mediaId: props.review ? props.review.media.id : props.mediaId,
      summary: props.review ? props.review.summary : '',
      media: null // We know this on edit, but not on create, so assume "not".
    }

    this.handleSummaryChange = this.handleSummaryChange.bind(this)
    this.handleSubmit = this.handleSubmit.bind(this)
  }

  componentDidMount () {
    MediaApi.show(this.state.mediaId).then(
      json => this.setState({ media: json }),
      this.props.onError
    )
  }

  handleSummaryChange (event) {
    this.setState({ summary: event.target.value })
  }

  handleSubmit (text) {
    const params = {
      mediaId: this.state.mediaId,
      summary: this.state.summary,
      content: text
    }

    this.props.onSubmit(params)
  }

  render () {
    const e = React.createElement

    return (
      e('div', { className: 'edit-review-form' },
        e('div', { className: 'edit-review-form-media-name' },
          localName(this.state.media || { names: [] })
        ),
        e('div', { className: 'edit-review-form-media-kind' },
          (this.state.media || {}).kind
        ),
        e('div', { className: 'edit-review-form-summary' },
          e(InputLabel, { label: 'Summary' },
            e(SimpleInput, {
              value: this.state.summary,
              onChange: this.handleSummaryChange
            })
          )
        ),
        e('div', { className: 'edit-review-form-content' },
          e(InputLabel, { label: 'Content' },
            e(MarkdownEditor, {
              initialText: (this.props.review || {}).content,
              onSubmit: this.handleSubmit
            },
            e(SimpleButton, { onClick: this.props.onCancel }, 'Cancel')
            )
          )
        )
      )
    )
  }
}

export default EditReviewForm
