import React from 'react'
import './AllModeratorsPage.css'

import PleaseWait from '../../components/PleaseWait/PleaseWait.js'
import UserCard from '../../components/UserCard/UserCard.js'

import UserApi from '../../api/User.js'

// writeme
class AllModeratorsPage extends React.Component {
  constructor (props) {
    super(props)

    this.state = {
      users: null
    }
  }

  componentDidMount () {
    UserApi.all(this.props.token, { role: 'administrator' }).then(
      json => this.setState({ users: json.items }),
      this.props.onError
    )
  }

  render () {
    const e = React.createElement

    if (!this.state.users) { return e(PleaseWait) }

    const users = this.state.users.map(user => (
      e(UserCard, {
        key: user.id,
        user: user,
        onPageChange: this.props.onPageChange
      })
    ))

    return (
      e('div', { className: 'all-moderators-page' },
        e('div', { className: 'all-moderators-page-title' }, 'Moderators'),
        e('div', { className: 'all-moderators-page-user-list' }, users)
      )
    )
  }
}

export default AllModeratorsPage
