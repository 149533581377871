// Space/start-of-string followed by "@something", but don't INCLUDE the space!
const userMention = /(?<=(^|\s))@\w+/g

// Replace any instances of "@someone" with a link to that user's profile page.
function autolinkNames (elem) {
  let names = []

  Array.prototype.forEach.call(elem.childNodes, node => {
    const kind = node.nodeName.toLowerCase()

    // Don't try to match text within links, code blocks, or comments:
    if (kind === 'a' || kind === 'code' || kind === '#comment') { return }

    // For any other non-#text nodes - such as <b> or <p> - check the children:
    if (kind !== '#text') {
      names = names.concat(autolinkNames(node))
      return
    }

    // Leave #text nodes alone UNLESS they contain at least one mention...
    const newNames = node.textContent.match(userMention)
    if (!newNames) { return }
    names = names.concat(newNames)

    // ...in which case, replace the text with a link to their profile page:
    const newNode = document.createElement('span')
    newNode.innerHTML = node.textContent.replace(userMention, match => (
      `<a href="/users/${match.slice(1)}">${match}</a>`
    ))
    node.parentNode.replaceChild(newNode, node)
  })

  return names
}

export default autolinkNames
