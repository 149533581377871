import React from 'react'
import './EditStaffForm.css'

import ImagePicker from '../ImagePicker/ImagePicker'
import InputLabel from '../InputLabel/InputLabel'
import MarkdownEditor from '../MarkdownEditor/MarkdownEditor'
import SimpleButton from '../SimpleButton/SimpleButton'
import SimpleInput from '../SimpleInput/SimpleInput'

// writeme
class EditStaffForm extends React.Component {
  constructor (props) {
    super(props)

    const staff = props.staff || {}

    this.state = {
      name: staff.name || '',
      picture: null
    }

    this.handleNameChange = this.handleNameChange.bind(this)
    this.handlePictureChange = this.handlePictureChange.bind(this)
    this.handleSubmit = this.handleSubmit.bind(this)
  }

  handleNameChange (event) { this.setState({ name: event.target.value }) }
  handlePictureChange (file) { this.setState({ picture: file }) }

  handleSubmit (text) {
    const params = { ...this.state, about: text }

    // Only include a "picture" attribute if a new image has been specified:
    if (!params.picture) { delete params.picture }

    this.props.onSubmit(params)
  }

  render () {
    return (
      <div className='edit-staff-form'>
        <div className='edit-staff-form-picture'>
          <ImagePicker
            width={240}
            height={240}
            fileSize='3MB'
            onChange={this.handlePictureChange}
          />
        </div>
        <div className='edit-staff-form-name'>
          <InputLabel label='Name'>
            <SimpleInput
              autoFocus={true}
              value={this.state.name}
              onChange={this.handleNameChange}
            />
          </InputLabel>
        </div>
        <div className='edit-staff-form-about'>
          <InputLabel label='Description'>
            <MarkdownEditor
              initialText={(this.props.staff || {}).about}
              onSubmit={this.handleSubmit}
            >
              <SimpleButton onClick={this.props.onCancel}>
                Cancel
              </SimpleButton>
            </MarkdownEditor>
          </InputLabel>
        </div>
      </div>
    )
  }
}

export default EditStaffForm
