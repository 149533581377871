import React from 'react'
import './CreateAccountPage.css'

// Components:
import SimpleButton from '../../components/SimpleButton/SimpleButton.js'
import InputLabel from '../../components/InputLabel/InputLabel.js'
import SimpleCheckbox from '../../components/SimpleCheckbox/SimpleCheckbox.js'
import SimpleInput from '../../components/SimpleInput/SimpleInput.js'

// APIs:
import AccountApi from '../../api/Account.js'

class CreateAccountPage extends React.Component {
  constructor (props) {
    super(props)

    this.state = {
      name: '',
      email: '',
      password: '',
      confirmPassword: '',
      // viewedRules: false,
      acceptRules: false
    }

    this.handleNameChange = this.handleNameChange.bind(this)
    this.handleEmailChange = this.handleEmailChange.bind(this)
    this.handlePasswordChange = this.handlePasswordChange.bind(this)
    this.handleConfirmPasswordChange = this.handleConfirmPasswordChange.bind(this)
    // this.handleViewRules = this.handleViewRules.bind(this)
    this.handleAcceptRulesToggle = this.handleAcceptRulesToggle.bind(this)
    this.handleSubmit = this.handleSubmit.bind(this)
  }

  handleNameChange (event) {
    this.setState({ name: event.target.value })
  }

  handleEmailChange (event) {
    this.setState({ email: event.target.value })
  }

  handlePasswordChange (event) {
    this.setState({ password: event.target.value })
  }

  handleConfirmPasswordChange (event) {
    this.setState({ confirmPassword: event.target.value })
  }

  // handleViewRules () {
  //   this.setState({ viewedRules: true })
  // }

  handleAcceptRulesToggle (event) {
    event.preventDefault() // It's technically a button; don't submit the form!

    this.setState({ acceptRules: !this.state.acceptRules })
  }

  handleSubmit (event) {
    event.preventDefault()

    if (this.state.password !== this.state.confirmPassword) {
      return this.props.onError({ error: 'Confirmation does not match' })
    } else if (!this.state.acceptRules) {
      return this.props.onError({ error: 'You have not accepted the rules' })
    // } else if (!this.state.viewedRules) {
    //   return this.props.onError({ error: "You haven't actually read the rules..." })
    }

    const params = {
      name: this.state.name,
      email: this.state.email,
      password: this.state.password
    }

    AccountApi.create(params).then(
      json => {
        this.props.onLogIn(json)
        this.props.onPageChange() // Don't stay on the log-in screen!
      },
      this.props.onError
    )
  }

  render () {
    const e = React.createElement

    const rulesLabel = (
      e('span', null,
        'I have read ',
        e('a', {
          href: 'rules.html',
          target: '_blank'
          // onClick: this.handleViewRules
        }, 'the rules'),
        ' and agree to follow them'
      )
    )

    return (
      e('form', { className: 'create-account', onSubmit: this.handleSubmit },
        e('div', { className: 'create-account-header' },
          'Create account'
        ),
        e('div', { className: 'create-account-name' },
          e(InputLabel, { label: 'Display Name' },
            e(SimpleInput, {
              value: this.state.name,
              onChange: this.handleNameChange,
              autoFocus: true
            })
          )
        ),
        e('div', { className: 'create-account-email' },
          e(InputLabel, { label: 'Email Address' },
            e(SimpleInput, {
              type: 'email',
              value: this.state.email,
              onChange: this.handleEmailChange
            })
          )
        ),
        e('div', { className: 'create-account-password' },
          e(InputLabel, { label: 'Password' },
            e(SimpleInput, {
              type: 'password',
              value: this.state.password,
              onChange: this.handlePasswordChange
            })
          )
        ),
        e('div', { className: 'create-account-confirm' },
          e(InputLabel, { label: 'Confirm Password' },
            e(SimpleInput, {
              type: 'password',
              value: this.state.confirmPassword,
              onChange: this.handleConfirmPasswordChange
            })
          )
        ),
        e('div', { className: 'create-account-footer' },
          e('div', { className: 'create-account-submit-button' },
            e(SimpleButton, { theme: 'primary' }, 'Continue')
          ),
          e('div', { className: 'create-account-rules-checkbox' },
            e(SimpleCheckbox, {
              label: rulesLabel,
              checked: this.state.acceptRules,
              onChange: this.handleAcceptRulesToggle
            })
          )
        )
      )
    )
  }
}

export default CreateAccountPage
