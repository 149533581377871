import React from 'react'
import './MediaReviewSummary.css'

// A summary of a review, shown in the context of that media.
function MediaReviewSummary (props) {
  const e = React.createElement

  return (
    e('div', { className: 'media-review-summary' },
      e('button', {
        className: 'summary',
        onClick: () => props.onPageChange('reviews', props.review.id)
      }, props.review.summary),
      e('button', {
        className: 'user-name',
        onClick: () => props.onPageChange('users', props.review.user.name)
      }, props.review.user.name)
    )
  )
}

export default MediaReviewSummary
