import React from 'react'
import './SimpleButton.css'

// A simple button.
function SimpleButton (props) {
  const e = React.createElement

  return (
    e('button', {
      className: `simple-button ${props.theme || 'general'}`,
      disabled: props.disabled,
      onClick: props.onClick
    }, props.children)
  )
}

export default SimpleButton
