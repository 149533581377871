import React from 'react'
import './EditCharacterForm.css'

import CharacterRoleForm from '../CharacterRoleForm/CharacterRoleForm'
import CharacterActorForm from '../CharacterActorForm/CharacterActorForm'
import ImagePicker from '../ImagePicker/ImagePicker'
import InputLabel from '../InputLabel/InputLabel'
import MarkdownEditor from '../MarkdownEditor/MarkdownEditor'
import SimpleButton from '../SimpleButton/SimpleButton'
import SimpleInput from '../SimpleInput/SimpleInput'

import { associatedCharacterKinds } from '../../helpers/commonEnums'

// writeme
class EditCharacterForm extends React.Component {
  constructor (props) {
    super(props)

    const roles = props.character.associatedCharacters.map(role => ({
      id: role.id || '',
      media: role.media || {},
      kind: role.kind || associatedCharacterKinds[0],
      destroy: false
    }))

    const actors = props.character.voiceActingRoles.map(role => ({
      id: role.id || '',
      staff: role.staff || {},
      language: role.language || 'ja',
      destroy: false
    }))

    this.state = {
      name: props.character.name || '',
      roles: roles,
      actors: actors,
      picture: null
    }

    this.handleNameChange = this.handleNameChange.bind(this)
    this.handlePictureChange = this.handlePictureChange.bind(this)
    this.handleRoleChange = this.handleRoleChange.bind(this)
    this.handleRoleRemove = this.handleRoleRemove.bind(this)
    this.handleActorChange = this.handleActorChange.bind(this)
    this.handleActorRemove = this.handleActorRemove.bind(this)
    this.handleSubmit = this.handleSubmit.bind(this)
  }

  handleNameChange (event) { this.setState({ name: event.target.value }) }
  handlePictureChange (file) { this.setState({ picture: file }) }

  handleRoleChange (idx, value) {
    const newRoles = this.state.roles.map((role, num) => (
      { ...role, kind: num === idx ? value : role.kind }
    ))

    this.setState({ roles: newRoles })
  }

  handleRoleRemove (idx) {
    const newRoles = this.state.roles.map((role, num) => (
      { ...role, destroy: role.destroy || num === idx }
    ))

    this.setState({ roles: newRoles })
  }

  handleActorChange (idx, value) {
    const newActors = this.state.actors.map((role, num) => (
      { ...role, language: num === idx ? value : role.language }
    ))

    this.setState({ actors: newActors })
  }

  handleActorRemove (idx) {
    const newActors = this.state.actors.map((role, num) => (
      { ...role, destroy: role.destroy || num === idx }
    ))

    this.setState({ actors: newActors })
  }

  handleSubmit (text) {
    const params = { ...this.state, about: text }

    delete params.roles
    params.associatedCharactersAttributes = this.state.roles.map(role => ({
      id: role.id,
      kind: role.kind,
      // mediaId: role.media.id,
      _destroy: role.destroy
    }))

    delete params.actors
    params.voiceActingRolesAttributes = this.state.actors.map(role => ({
      id: role.id,
      language: role.language,
      // staffId: role.staff.id,
      _destroy: role.destroy
    }))

    // Only include a "picture" attribute if a new image has been specified:
    if (!params.picture) { delete params.picture }

    this.props.onSubmit(params)
  }

  render () {
    const roles = this.state.roles.map((role, idx) => (
      <CharacterRoleForm
        key={idx}
        role={role}
        onChange={(value) => this.handleRoleChange(idx, value)}
        onRemove={() => this.handleRoleRemove(idx)}
      />
    ))

    const actors = this.state.actors.map((role, idx) => (
      <CharacterActorForm
        key={idx}
        role={role}
        onChange={(value) => this.handleActorChange(idx, value)}
        onRemove={() => this.handleActorRemove(idx)}
      />
    ))

    return (
      <div className='edit-character-form'>
        <div className='edit-character-form-picture'>
          <ImagePicker
            width={240}
            height={240}
            fileSize='3MB'
            onChange={this.handlePictureChange}
          />
        </div>
        <div className='edit-character-form-name'>
          <InputLabel label='Name'>
            <SimpleInput
              autoFocus={true}
              value={this.state.name}
              onChange={this.handleNameChange}
            />
          </InputLabel>
        </div>
        <div className='edit-character-form-roles'>
          {roles}
        </div>
        <div className='edit-character-form-actors'>
          {actors}
        </div>
        <div className='edit-character-form-about'>
          <InputLabel label='Description'>
            <MarkdownEditor
              initialText={(this.props.character || {}).about}
              onSubmit={this.handleSubmit}
            >
              <SimpleButton onClick={this.props.onCancel}>
                Cancel
              </SimpleButton>
            </MarkdownEditor>
          </InputLabel>
        </div>
      </div>
    )
  }
}

export default EditCharacterForm
