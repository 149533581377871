import React from 'react'
import './UserReportIcon.css'

import UserReportApi from '../../api/UserReport.js'

// writeme
class UserReportIcon extends React.Component {
  constructor (props) {
    super(props)

    this.state = { count: '' }

    this.fetchData = this.fetchData.bind(this)
  }

  // TODO: Subscribe to websocket notifications instead.
  componentDidMount () {
    this.fetchData()
    this.timer = window.setInterval(this.fetchData, 120000)
  }

  // TODO: Unsubscribe from websocket notifications instead.
  componentWillUnmount () {
    window.clearTimeout(this.timer)
  }

  fetchData () {
    UserReportApi.all(this.props.token).then(
      json => this.setState({ count: json.items.length }),
      this.props.onError
    )
  }

  render () {
    const e = React.createElement

    const css = `user-report-icon ${this.state.count ? 'some' : 'none'}`
    const plural = this.state.count === 1 ? 'report' : 'reports'
    const text = `${this.state.count || 'No'} ${plural}`

    return e('button', { className: css, onClick: this.props.onClick }, text)
  }
}

export default UserReportIcon
