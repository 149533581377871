// Determines whether or not to show an edit/delete link for something.
function canEdit (currentUser, thing) {
  // Not logged in = can't edit anything.
  if (!currentUser) { return false }
  // Administrator = can edit everything.
  if (currentUser.administrator) { return true }
  // Normal user = can only edit things that belong to them.
  return thing.user ? currentUser.id === thing.user.id : false
}

export default canEdit
