import React from 'react'
import './InputLabel.css'

// Labels an input.
function InputLabel (props) {
  const e = React.createElement

  return (
    e('label', { className: 'input-label' },
      e('span', { className: 'input-label-text' }, props.label),
      props.children
    )
  )
}

export default InputLabel
