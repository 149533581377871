import React from 'react'
import './SocialPage.css'

import RecentMedia from '../../components/RecentMedia/RecentMedia'
import RecentReviews from '../../components/RecentReviews/RecentReviews'
import StatusPostList from '../../components/StatusPostList/StatusPostList'

// This is the "main" (social) page.
function SocialPage (props) {
  return (
    <div className='social-page'>
      <div className='social-page-status-posts'>
        <StatusPostList {...props} />
      </div>
      <div className='social-page-recent-anime-reviews'>
        <RecentReviews kind='anime' onPageChange={props.onPageChange} />
      </div>
      <div className='social-page-recent-manga-reviews'>
        <RecentReviews kind='manga' onPageChange={props.onPageChange} />
      </div>
      <div className='social-page-recent-anime'>
        <RecentMedia kind='anime' onPageChange={props.onPageChange} />
      </div>
      <div className='social-page-recent-manga'>
        <RecentMedia kind='manga' onPageChange={props.onPageChange} />
      </div>
    </div>
  )
}

export default SocialPage
