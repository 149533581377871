import apiCall from './apiCall.js'

// Flatten a Rails-style errors object into a simple array:
function flatten (errors) {
  const result = []
  Object.keys(errors || {}).forEach(key => {
    errors[key].forEach(text => result.push([key, text].join(' ')))
  })
  return { errors: result }
}

// Handles all sign in/out/up requests.
class Account {
  static create (params) {
    return new Promise((resolve, reject) => {
      apiCall('POST', 'users', null, { user: params }).then(
        json => resolve(json),
        json => json.error ? reject(json) : reject(flatten(json.errors))
      )
    })
  }

  static update (token, params) {
    return new Promise((resolve, reject) => {
      apiCall('PATCH', 'users', token, { user: params }).then(
        json => resolve(json),
        json => json.error ? reject(json) : reject(flatten(json.errors))
      )
    })
  }

  static destroy (token) {
    return apiCall('DELETE', 'users', token)
  }

  static logIn (email, password) {
    const params = {
      email: email || '',
      password: password || ''
    }

    return apiCall('POST', 'users/sign_in', null, { user: params })
  }

  static logOut (token) {
    return apiCall('DELETE', 'users/sign_out', token)
  }
}

export default Account
