import React from 'react'
import './AllStudiosPage.css'

import PleaseWait from '../../components/PleaseWait/PleaseWait.js'
import StudioCard from '../../components/StudioCard/StudioCard.js'

import StudioApi from '../../api/Studio.js'

// Displays a list of all studios.
class AllStudiosPage extends React.Component {
  constructor (props) {
    super(props)

    this.state = { studios: null }
  }

  componentDidMount () {
    StudioApi.all().then(
      json => this.setState({ studios: json.items }),
      this.props.onError
    )
  }

  render () {
    const e = React.createElement

    if (!this.state.studios) { return e(PleaseWait) }

    const items = this.state.studios.map(studio => (
      e(StudioCard, {
        key: studio.id,
        studio: studio,
        onClick: () => this.props.onPageChange('studios', studio.id)
      })
    ))

    return e('div', { className: 'all-studios-page' }, items)
  }
}

export default AllStudiosPage
