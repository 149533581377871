import React from 'react'

import LocalImage from '../LocalImage/LocalImage.js'

// Used for images which (might be) stored on a remote data server.
function RemoteImage (props) {
  const e = React.createElement

  // Uploaded images file host. JSON from the API gives paths relative to this:
  const host = process.env.REACT_APP_API_SERVER

  // If we don't have a remote file, use a local one such as `images/blah.png`:
  const src = props.remotePath ? `${host}/${props.remotePath}` : props.fallback

  // Remove the remotePath and fallback props, but use any other ones normally:
  const attributes = { ...props, src: src }
  delete attributes.remotePath
  delete attributes.fallback

  // The end result is just a regular <img> tag; we just fiddle with its `src`:
  return e(props.remotePath ? 'img' : LocalImage, attributes)
}

export default RemoteImage
