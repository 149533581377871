import apiCall from './apiCall.js'
import buildGetQuery from './buildGetQuery.js'
import buildFormData from './buildFormData.js'

// Handles all character requests.
class Character {
  static all (params) {
    const data = buildGetQuery(params)
    return apiCall('GET', 'api/v1/characters', null, data)
  }

  static show (id) {
    return apiCall('GET', `api/v1/characters/${id}`)
  }

  static create (token, params) {
    const data = buildFormData('character', params)
    return apiCall('POST', 'api/v1/characters', token, data)
  }

  static update (token, id, params) {
    const data = buildFormData('character', params)
    return apiCall('PATCH', `api/v1/characters/${id}`, token, data)
  }

  static destroy (token, id) {
    return apiCall('DELETE', `api/v1/characters/${id}`, token)
  }
}

export default Character
