import React from 'react'

import SimpleDropdown from '../SimpleDropdown/SimpleDropdown.js'

import { entryStatuses } from '../../helpers/commonEnums.js'

import ListEntryApi from '../../api/ListEntry.js'

// A quick way to add/update/remove a media item to the current user's list.
class UserMediaStatus extends React.Component {
  constructor (props) {
    super(props)

    this.state = {
      entry: null,
      loading: true
    }

    this.handleSelect = this.handleSelect.bind(this)
  }

  componentDidMount () {
    const params = { user: this.props.userId, media: this.props.mediaId }

    ListEntryApi.all(params).then(
      json => this.setState({ entry: json.items[0], loading: false }),
      this.props.onError
    )
  }

  handleSelect (value) {
    if (!this.state.entry && value) {
      this.handleCreate(value)
    } else if (this.state.entry && value && this.state.entry.status !== value) {
      this.handleUpdate(value)
    } else if (this.state.entry && !value) {
      this.handleDestroy()
    }
  }

  handleCreate (value) {
    const params = { status: value, mediaId: this.props.mediaId }
    this.setState({ loading: true })

    ListEntryApi.create(this.props.token, params).then(
      json => this.setState({ entry: json, loading: false }),
      this.props.onError
    )
  }

  handleUpdate (value) {
    const params = { status: value }
    this.setState({ loading: true })

    ListEntryApi.update(this.props.token, this.state.entry.id, params).then(
      json => this.setState({ entry: json, loading: false }),
      this.props.onError
    )
  }

  handleDestroy (value) {
    if (!window.confirm('Remove this entry from your list?')) { return }

    this.setState({ loading: true })

    ListEntryApi.destroy(this.props.token, this.state.entry.id).then(
      () => this.setState({ entry: null, loading: false }),
      this.props.onError
    )
  }

  render () {
    const e = React.createElement

    return (
      e(SimpleDropdown, {
        value: (this.state.entry || { status: '' }).status,
        options: entryStatuses,
        allowClear: true,
        disabled: this.state.loading,
        onChange: this.handleSelect
      })
    )
  }
}

export default UserMediaStatus
