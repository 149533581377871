import React from 'react'
import './LinkVoiceActorForm.css'

import StaffSearch from '../StaffSearch/StaffSearch'
import InputLabel from '../InputLabel/InputLabel'
import SimpleButton from '../SimpleButton/SimpleButton'
import SimpleDropdown from '../SimpleDropdown/SimpleDropdown'

import CharacterApi from '../../api/Character'

// writeme
class LinkVoiceActorForm extends React.Component {
  constructor (props) {
    super(props)

    this.state = { language: 'ja' }

    this.handleLanguageChange = this.handleLanguageChange.bind(this)
    this.handleSubmit = this.handleSubmit.bind(this)
  }

  handleLanguageChange (value) {
    this.setState({ language: value })
  }

  handleSubmit (staff) {
    const params = {
      voiceActingRolesAttributes: [
        { staffId: staff.id, language: this.state.language }
      ]
    }

    CharacterApi.update(this.props.token, this.props.characterId, params).then(
      this.props.onSuccess,
      this.props.onError
    )
  }

  render () {
    return (
      <StaffSearch
        onError={this.props.onError}
        onClick={this.handleSubmit}
      >
        <div className='link-voice-actor-form-actions'>
          <InputLabel label='Language'>
            <SimpleDropdown
              value={this.state.language}
              options={['ja', 'en']}
              onChange={this.handleLanguageChange}
            />
          </InputLabel>
          <SimpleButton onClick={this.props.onCancel}>Cancel</SimpleButton>
        </div>

      </StaffSearch>
    )
  }
}

export default LinkVoiceActorForm
