import React from 'react'
import './TabGroup.css'

// A generic "tabs" container.
class TabGroup extends React.Component {
  constructor (props) {
    super(props)
    this.state = { active: 0 }

    this.handleClick = this.handleClick.bind(this)
  }

  handleClick (event, index) {
    event.preventDefault()
    this.setState({ active: index })
  }

  render () {
    const e = React.createElement
    const children = React.Children.toArray(this.props.children)

    const tabs = children.map((_, i) => e('button', {
      key: i,
      className: `tab ${i === this.state.active ? 'active' : ''}`,
      onClick: e => this.handleClick(e, i)
    }, (this.props.labels || [])[i] || i + 1))

    return e('div', { className: 'tab-group' },
      e('div', { className: 'tabs' }, tabs),
      e('div', { className: 'body' }, children[this.state.active])
    )
  }
}

export default TabGroup
