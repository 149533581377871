import React from 'react'
import './SimpleInput.css'

// Basically just a regular <input> tag, but with extra CSS.
function SimpleInput (props) {
  const e = React.createElement

  return e('input', {
    ...props,
    value: props.value || '',
    className: 'simple-input'
  })
}

export default SimpleInput
