import React from 'react'

import EditStudioForm from '../../components/EditStudioForm/EditStudioForm.js'
import PleaseWait from '../../components/PleaseWait/PleaseWait.js'

import StudioApi from '../../api/Studio.js'

// writeme
class EditStudioPage extends React.Component {
  constructor (props) {
    super(props)

    this.state = {
      studio: null
    }

    this.handleSubmit = this.handleSubmit.bind(this)
  }

  componentDidMount () {
    StudioApi.show(this.props.id).then(
      json => this.setState({ studio: json }),
      this.props.onError
    )
  }

  handleSubmit (params) {
    StudioApi.update(this.props.token, this.props.id, params).then(
      () => this.props.onPageChange('studios', this.props.id),
      this.props.onError
    )
  }

  render () {
    const e = React.createElement

    if (!this.state.studio) { return e(PleaseWait) }

    return e('div', { className: 'edit-studio-page' },
      e(EditStudioForm, {
        studio: this.state.studio,
        onSubmit: this.handleSubmit,
        onCancel: () => this.props.onPageChange('studios', this.props.id)
      })
    )
  }
}

export default EditStudioPage
