import React from 'react'
import './TrackingSiteLink.css'

// writeme
function anidbData (media) {
  return media.anidbCode ? {
    text: 'AniDB',
    href: `https://anidb.net/${media.kind}/${media.anidbCode}`
  } : null
}

// writeme
function anilistData (media) {
  return media.anilistCode ? {
    text: 'Anilist',
    href: `http://anilist.co/${media.kind}/${media.anilistCode}`
  } : null
}

// writeme
function animePlanetData (media) {
  return media.animePlanetCode ? {
    text: 'Anime-Planet',
    href: `http://anime-planet.com/${media.kind}/${media.animePlanetCode}`
  } : null
}

// writeme
function kitsuData (media) {
  return media.kitsuCode ? {
    text: 'Kitsu',
    href: `http://kitsu.io/${media.kind}/${media.kitsuCode}`
  } : null
}

// writeme
function myAnimeListData (media) {
  return media.malCode ? {
    text: 'MyAnimeList',
    href: `http://myanimelist.net/${media.kind}/${media.malCode}`
  } : null
}

// writeme
function TrackingSiteLink (props) {
  const e = React.createElement

  let data = null

  if (props.site === 'anidb') {
    data = anidbData(props.media)
  } else if (props.site === 'anilist') {
    data = anilistData(props.media)
  } else if (props.site === 'animePlanet') {
    data = animePlanetData(props.media)
  } else if (props.site === 'kitsu') {
    data = kitsuData(props.media)
  } else if (props.site === 'mal') {
    data = myAnimeListData(props.media)
  }

  if (!data) { return null } // Unknown site, or we don't have data for it.

  return (
    e('a', {
      className: `tracking-site-link ${props.site}`,
      href: data.href,
      target: '_blank',
      rel: 'noopener noreferrer'
    }, data.text)
  )
}

export default TrackingSiteLink
