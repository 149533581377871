import React from 'react'
import './MarkdownEditor.css'

import SimpleButton from '../SimpleButton/SimpleButton.js'
import MarkdownContent from '../MarkdownContent/MarkdownContent.js'

class MarkdownEditor extends React.Component {
  constructor (props) {
    super(props)

    this.state = {
      text: props.initialText || ''
    }

    this.handleTextChange = this.handleTextChange.bind(this)
    this.handleSubmit = this.handleSubmit.bind(this)
  }

  handleTextChange (event) {
    this.setState({ text: event.target.value })
  }

  handleSubmit (event) {
    this.props.onSubmit(this.state.text)
  }

  render () {
    const e = React.createElement

    return (
      e('div', { className: 'markdown-editor' },
        e('textarea', {
          className: 'editor',
          value: this.state.text,
          onChange: this.handleTextChange
        }),
        e('div', { className: 'preview' },
          e(MarkdownContent, { text: this.state.text })
        ),
        e('div', { className: 'help-text' },
          '\u2139 You can use ',
          e('a', {
            href: 'https://commonmark.org/help/',
            target: '_blank'
          },
          'Markdown'
          ),
          ' for formatting.'
        ),
        e('div', { className: 'extras' },
          this.props.children
        ),
        e('div', { className: 'actions' },
          e(SimpleButton, { theme: 'primary', onClick: this.handleSubmit }, 'Submit')
        )
      )
    )
  }
}

export default MarkdownEditor
