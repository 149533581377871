import React from 'react'
import './SimpleDropdown.css'

// A very simple drop-down list.
function SimpleDropdown (props) {
  const e = React.createElement

  const css = props.disabled ? 'simple-dropdown disabled' : 'simple-dropdown'

  const options = props.options.map(option => (
    e('option', { key: option, className: 'simple-dropdown-option' }, option)
  ))

  return (
    e('div', { className: css },
      e('select', {
        className: 'simple-dropdown-value',
        disabled: props.disabled,
        value: props.value || '',
        onChange: (event) => props.onChange(event.target.value)
      },
      props.allowClear || !props.value ? (
        e('option', { className: 'simple-dropdown-option blank' })
      ) : null,
      options
      ),
      props.allowClear ? (
        e('button', {
          className: 'simple-dropdown-clear',
          disabled: props.disabled,
          onClick: (event) => props.value && props.onChange('')
        }, 'Clear')
      ) : null
    )
  )
}

export default SimpleDropdown
