import React from 'react'
import './MediaSearch.css'

import SimpleButton from '../SimpleButton/SimpleButton.js'
import InputLabel from '../InputLabel/InputLabel.js'
import MediaCard from '../MediaCard/MediaCard'
import PleaseWait from '../PleaseWait/PleaseWait.js'
import SimpleCheckbox from '../SimpleCheckbox/SimpleCheckbox.js'
import SimpleDropdown from '../SimpleDropdown/SimpleDropdown.js'
import SimpleInput from '../SimpleInput/SimpleInput.js'

import { mediaKinds, mediaSortOptions, mediaStatuses } from '../../helpers/commonEnums.js'
import safeConcat from '../../helpers/safeConcat.js'

import MediaApi from '../../api/Media.js'

// General media search page.
class MediaSearch extends React.Component {
  constructor (props) {
    super(props)

    this.state = {
      search: '',
      sortField: mediaSortOptions[0],
      ascending: true,
      kind: mediaKinds[0],
      status: '',
      studio: props.studioId || '',
      page: 0,
      media: null,
      more: false,
      loading: true
    }

    this.fetchData = this.fetchData.bind(this)
    this.handleSearchChange = this.handleSearchChange.bind(this)
    this.handleSortFieldChange = this.handleSortFieldChange.bind(this)
    this.handleDirectionToggle = this.handleDirectionToggle.bind(this)
    this.handleKindChange = this.handleKindChange.bind(this)
    this.handleStatusChange = this.handleStatusChange.bind(this)
    this.handleMore = this.handleMore.bind(this)
  }

  componentDidMount () {
    this.fetchData()
  }

  componentWillUnmount () {
    window.clearTimeout(this.typingTimeout)
  }

  fetchData () {
    const perPage = 18

    const params = {
      search: this.state.search,
      sortField: this.state.sortField,
      sortOrder: this.state.ascending ? 'ASC' : 'DESC',
      kind: this.state.kind,
      status: this.state.status,
      studio: this.state.studio,
      offset: this.state.page * perPage,
      limit: perPage
    }

    this.setState({ loading: true })
    MediaApi.all(params).then(
      json => {
        const data = safeConcat(this.state.media, json.items)
        const more = json.items.length === perPage
        this.setState({ media: data, more: more, loading: false })
      },
      this.props.onError
    )
  }

  handleSearchChange (event) {
    this.setState({ search: event.target.value })

    // Wait for a short time, to verify that the user has finished typing:
    window.clearTimeout(this.typingTimeout)
    this.typingTimeout = window.setTimeout(() => {
      this.setState(
        { page: 0, media: null, more: false },
        this.fetchData
      )
    }, 500)
  }

  handleSortFieldChange (value) {
    this.setState(
      { page: 0, media: null, more: false, sortField: value },
      this.fetchData
    )
  }

  handleDirectionToggle (value) {
    this.setState(
      { page: 0, media: null, more: false, ascending: !this.state.ascending },
      this.fetchData
    )
  }

  handleKindChange (value) {
    this.setState(
      { page: 0, media: null, more: false, kind: value },
      this.fetchData
    )
  }

  handleStatusChange (value) {
    this.setState(
      { page: 0, media: null, more: false, status: value },
      this.fetchData
    )
  }

  handleMore () {
    this.setState(
      { page: this.state.page + 1 },
      this.fetchData
    )
  }

  render () {
    const e = React.createElement

    const items = this.state.media ? (
      this.state.media.map(media => (
        e(MediaCard, {
          key: media.id,
          media: media,
          onClick: () => this.props.onClick(media)
        })
      ))
    ) : e(PleaseWait)

    const moreContent = this.state.more ? (
      e(SimpleButton, {
        disabled: this.state.loading,
        onClick: this.handleMore
      }, 'More...')
    ) : null

    return (
      e('div', { className: 'media-search' },
        e('div', { className: 'media-search-filter' },
          e('div', { className: 'media-search-filter-search' },
            e(InputLabel, { label: 'Search' },
              e(SimpleInput, {
                label: 'Search',
                value: this.state.search,
                autoFocus: true,
                onChange: this.handleSearchChange
              })
            )
          ),
          e('div', { className: 'media-search-filter-kind' },
            e(InputLabel, { label: 'Media type' },
              e(SimpleDropdown, {
                disabled: this.props.studioId,
                value: this.state.kind,
                options: mediaKinds,
                onChange: this.handleKindChange
              })
            )
          ),
          e('div', { className: 'media-search-filter-status' },
            e(InputLabel, { label: 'Status' },
              e(SimpleDropdown, {
                value: this.state.status,
                options: mediaStatuses,
                allowClear: true,
                onChange: this.handleStatusChange
              })
            )
          ),
          e('div', { className: 'media-search-filter-order' },
            e(InputLabel, { label: 'Order by' },
              e(SimpleDropdown, {
                value: this.state.sortField,
                options: mediaSortOptions,
                onChange: this.handleSortFieldChange
              })
            )
          ),
          e('div', { className: 'media-search-filter-direction' },
            e(InputLabel, { label: 'Sort' },
              e('button', {
                className: 'media-search-filter-direction-icon',
                onClick: this.handleDirectionToggle
              },
              this.state.ascending ? '⬆️' : '⬇️'
              )
            )
          ),
          e('div', { className: 'media-search-filter-ignore' },
            e(SimpleCheckbox, {
              label: 'Not on my list',
              checked: false,
              disabled: true,
              onChange: () => console.log('Not implemented yet...')
            })
          ),
          e('div', { className: 'media-search-extras' }, this.props.children)
        ),
        e('div', { className: 'media-search-items' }, items),
        e('div', { className: 'media-search-more' }, moreContent)
      )
    )
  }
}

export default MediaSearch
