import React from 'react'
import './UserMediaList.css'

import PleaseWait from '../PleaseWait/PleaseWait.js'
import UserMediaListItem from '../UserMediaListItem/UserMediaListItem.js'

import { entryStatuses } from '../../helpers/commonEnums.js'

import ListEntryApi from '../../api/ListEntry.js'

// writeme
class UserMediaList extends React.Component {
  constructor (props) {
    super(props)

    this.state = {
      status: 'in_progress',
      entries: null
    }

    this.fetchData = this.fetchData.bind(this)
    this.handleStatusChange = this.handleStatusChange.bind(this)
    this.handleEntryChange = this.handleEntryChange.bind(this)
    this.handleEntryRemove = this.handleEntryRemove.bind(this)
  }

  componentDidMount () {
    this.fetchData()
  }

  fetchData () {
    const params = {
      user: this.props.userId,
      kind: this.props.kind,
      status: this.state.status
    }

    this.setState({ entries: null })
    ListEntryApi.all(params).then(
      json => this.setState({ entries: json.items }),
      this.props.onError
    )
  }

  handleStatusChange (value) {
    this.setState({ status: value }, this.fetchData)
  }

  handleEntryChange (entry) {
    const statusChanged = entry.status !== this.state.status
    if (statusChanged) { return this.handleEntryRemove(entry) }

    const newList = this.state.entries.map(i => i.id === entry.id ? entry : i)
    this.setState({ entries: newList })
  }

  handleEntryRemove (entry) {
    const newList = this.state.entries.filter(item => item.id !== entry.id)
    this.setState({ entries: newList })
  }

  render () {
    const e = React.createElement

    const buttons = entryStatuses.map(text => {
      const active = text === this.state.status

      return (
        e('button', {
          key: text,
          className: `user-media-list-filter-option${active ? ' active' : ''}`,
          disabled: !this.state.entries,
          onClick: () => this.handleStatusChange(text)
        }, text.replace('_', '-'))
      )
    })

    const items = this.state.entries ? (
      this.state.entries.map(entry => (
        e(UserMediaListItem, {
          key: entry.id,
          token: this.props.token,
          entry: entry,
          allowEdit: this.props.userId === (this.props.currentUser || {}).id,
          onError: this.props.onError,
          onChange: this.handleEntryChange,
          onRemove: this.handleEntryRemove,
          onPageChange: this.props.onPageChange
        })
      ))
    ) : e(PleaseWait)

    return (
      e('div', { className: 'user-media-list' },
        e('div', { className: 'user-media-list-filter' }, buttons),
        e('div', { className: 'user-media-list-items' }, items)
      )
    )
  }
}

export default UserMediaList
