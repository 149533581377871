import React from 'react'
import './NotificationIcon.css'

import NotificationSummary from '../../components/NotificationSummary/NotificationSummary.js'
import PleaseWait from '../../components/PleaseWait/PleaseWait.js'

import NotificationApi from '../../api/Notification.js'

// writeme
class NotificationIcon extends React.Component {
  constructor (props) {
    super(props)

    this.state = {
      expanded: false,
      notifications: null
    }

    this.fetchData = this.fetchData.bind(this)
    this.handleToggle = this.handleToggle.bind(this)
    this.handleDestroy = this.handleDestroy.bind(this)
  }

  // TODO: Subscribe to websocket notifications instead.
  componentDidMount () {
    this.fetchData()
    this.timer = window.setInterval(this.fetchData, 60000)
  }

  // TODO: Unsubscribe from websocket notifications instead.
  componentWillUnmount () {
    window.clearTimeout(this.timer)
  }

  fetchData () {
    NotificationApi.all(this.props.token).then(
      json => this.setState({ notifications: json.items }),
      this.props.onError
    )
  }

  handleToggle () {
    this.setState({ expanded: !this.state.expanded })
  }

  handleDestroy (notification) {
    const recordId = notification.id

    NotificationApi.destroy(this.props.token, recordId).then(
      () => {
        const items = this.state.notifications.filter(n => n.id !== recordId)
        this.setState({ notifications: items }) // Avoid a fetchData API call.
      },
      this.props.onError
    )
  }

  render () {
    const e = React.createElement

    const some = (this.state.notifications || []).length > 0

    const count = this.state.notifications ? (
      e('div', { className: `notification-count ${some ? 'some' : 'none'}` },
        String(this.state.notifications.length)
      )
    ) : (
      e('div', { className: 'notification-count null' }, '-')
    )

    const items = this.state.notifications ? (
      e(React.Fragment, null,
        this.state.notifications.map(notification => (
          e(NotificationSummary, {
            key: notification.id,
            notification: notification,
            onPageChange: this.props.onPageChange,
            onDestroy: () => this.handleDestroy(notification)
          })
        ))
      )
    ) : (
      e(PleaseWait)
    )

    return (
      e('div', { className: 'notification-icon' },
        e('button', {
          className: 'notification-icon-head',
          onClick: this.handleToggle
        }, count),
        this.state.expanded ? (
          e('div', { className: 'notification-icon-body' }, items)
        ) : null
      )
    )
  }
}

export default NotificationIcon
