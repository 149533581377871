import React from 'react'

import EditStaffForm from '../../components/EditStaffForm/EditStaffForm'

import StaffApi from '../../api/Staff'

// writeme
class CreateStaffPage extends React.Component {
  constructor (props) {
    super(props)

    this.handleSubmit = this.handleSubmit.bind(this)
  }

  handleSubmit (params) {
    StaffApi.create(this.props.token, params).then(
      json => this.props.onPageChange('staff', json.id),
      this.props.onError
    )
  }

  render () {
    return (
      <EditStaffForm
        onSubmit={this.handleSubmit}
        onCancel={() => this.props.onPageChange('staff')}
      />
    )
  }
}

export default CreateStaffPage
