import React from 'react'
import './CharacterList.css'

import AddExistingCharacter from '../AddExistingCharacter/AddExistingCharacter'
import CharacterSearch from '../CharacterSearch/CharacterSearch'
import SimpleButton from '../SimpleButton/SimpleButton'

// writeme
class CharacterList extends React.Component {
  constructor (props) {
    super(props)

    this.state = { showPotential: false }

    this.handleShowCurrent = this.handleShowCurrent.bind(this)
    this.handleShowPotential = this.handleShowPotential.bind(this)
    this.handleCreateNew = this.handleCreateNew.bind(this)
  }

  handleShowCurrent () {
    this.setState({ showPotential: false })
  }

  handleShowPotential () {
    this.setState({ showPotential: true })
  }

  handleCreateNew () {
    this.props.onPageChange('create-character', this.props.mediaId)
  }

  render () {
    if (this.state.showPotential) {
      return (
        <AddExistingCharacter
          token={this.props.token}
          mediaId={this.props.mediaId}
          onError={this.props.onError}
          onComplete={this.handleShowCurrent}
        />
      )
    }

    const addOrLink = (this.props.currentUser || {}).administrator ? (
      <div className='character-list-actions'>
        <SimpleButton theme='create' onClick={this.handleCreateNew}>
          Add new
        </SimpleButton>
        <SimpleButton theme='primary' onClick={this.handleShowPotential}>
          Add existing
        </SimpleButton>
      </div>
    ) : null

    return (
      <CharacterSearch
        mediaId={this.props.mediaId}
        onError={this.props.onError}
        onClick={char => this.props.onPageChange('characters', char.id)}
      >
        {addOrLink}
      </CharacterSearch>
    )
  }
}

export default CharacterList
