import React from 'react'
import './UserReviewListItem.css'

import localName from '../../helpers/localName.js'

// A review summary, displayed within the context of a user.
function UserReviewListItem (props) {
  const e = React.createElement

  return (
    e('div', { className: 'user-review-list-item' },
      e('div', { className: 'user-review-list-item-kind' },
        props.review.media.kind
      ),
      e('button', {
        className: 'user-review-list-item-name',
        onClick: () => props.onPageChange('media', props.review.media.id)
      }, localName(props.review.media)),
      e('button', {
        className: 'user-review-list-item-summary',
        onClick: () => props.onPageChange('reviews', props.review.id)
      }, props.review.summary)
    )
  )
}

export default UserReviewListItem
