import React from 'react'
import './UserDataPage.css'

import SimpleButton from '../../components/SimpleButton/SimpleButton'
import ListImportRow from '../../components/ListImportRow/ListImportRow'
import PleaseWait from '../../components/PleaseWait/PleaseWait'
import UploadFileForm from '../../components/UploadFileForm/UploadFileForm'

import ListEntryApi from '../../api/ListEntry'

// Data import/export stuff.
class UserDataPage extends React.Component {
  constructor (props) {
    super(props)

    this.state = {
      loading: false,
      results: null
    }

    this.handleAnilistUpload = this.handleAnilistUpload.bind(this)
    this.handleClearList = this.handleClearList.bind(this)
    this.handleDataDownload = this.handleDataDownload.bind(this)
  }

  handleAnilistUpload (file) {
    this.setState({ loading: true })
    ListEntryApi.uploadAnilistData(this.props.token, file).then(
      json => this.setState({ loading: false, results: json }),
      json => {
        this.setState({ loading: false }) // Probably a bad file...
        this.props.onError(json)
      }
    )
  }

  handleClearList (kind) {
    if (!window.confirm(`Clear all items on your ${kind} list?`)) { return }

    this.setState({ loading: true })
    ListEntryApi.clear(this.props.token, { kind: kind }).then(
      json => this.setState({ loading: false }),
      json => {
        this.setState({ loading: false })
        this.props.onError(json)
      }
    )
  }

  handleDataDownload () {
    const params = { user: this.props.currentUser.id }

    this.setState({ loading: true })
    ListEntryApi.all(params).then(
      json => {
        this.setState({ loading: false })

        const data = JSON.stringify(json, null, 2)

        const a = document.createElement('a')
        a.download = 'export.json'
        a.href = 'data:application/json,' + encodeURI(data)
        a.click()
      },
      json => {
        this.setState({ loading: false })
        this.props.onError(json)
      }
    )
  }

  render () {
    if (this.state.loading) { return <PleaseWait /> }

    const results = this.state.results ? Object.keys(this.state.results).map(
      (name, idx) => (
        <ListImportRow
          key={idx}
          name={name}
          data={this.state.results[name]}
        />
      )
    ) : null

    return (
      <div className='user-data-page'>
        <div className='user-data-page-title'>
          Import/Export your data
        </div>
        <div className='user-data-page-export'>
          <SimpleButton theme='create' onClick={this.handleDataDownload}>
            Download lists
          </SimpleButton>
        </div>
        <div className='user-data-page-clear-anime'>
          <SimpleButton theme='delete' onClick={() => this.handleClearList('anime')}>
            Clear anime list
          </SimpleButton>
        </div>
        <div className='user-data-page-clear-manga'>
          <SimpleButton theme='delete' onClick={() => this.handleClearList('manga')}>
            Clear manga list
          </SimpleButton>
        </div>
        <div className='user-data-page-import'>
          <UploadFileForm title='Import Anilist GDPR' onSubmit={this.handleAnilistUpload}>
            Importing your <b>gdpr_data.json</b> file will automatically add
            any "missing" entries to your anime/manga lists. You can get it
            from <a href='https://anilist.co/settings/account' target='_blank' rel='noopener noreferrer'>
              this page
            </a>, assuming you are logged into Anilist.
          </UploadFileForm>
        </div>
        <div className='user-data-page-results'>
          {results}
        </div>
      </div>
    )
  }
}

export default UserDataPage
