import React from 'react'
import './StudioCard.css'

import RemoteImage from '../RemoteImage/RemoteImage.js'

// writeme
function StudioCard (props) {
  const e = React.createElement

  return (
    e('button', { className: 'studio-card', onClick: props.onClick },
      e('div', { className: 'studio-card-logo-container' },
        e(RemoteImage, {
          className: 'studio-card-logo-image',
          remotePath: props.studio.logo,
          fallback: 'missing-logo.png',
          alt: 'Logo'
        })
      ),
      e('div', { className: 'studio-card-name' }, props.studio.name)
    )
  )
}

export default StudioCard
