import apiCall from './apiCall.js'

// Handles requests related to viewing or deleting notifications.
class NotificationApi {
  static all (token) {
    return apiCall('GET', 'api/v1/notifications', token)
  }

  static destroy (token, id) {
    return apiCall('DELETE', `api/v1/notifications/${id}`, token)
  }
}

export default NotificationApi
