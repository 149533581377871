import React from 'react'

import EditStatusPostForm from '../EditStatusPostForm/EditStatusPostForm.js'
import StatusPost from '../StatusPost/StatusPost.js'

import StatusPostApi from '../../api/StatusPost.js'

// Basically just a wrapper around a status post or status post editor.
class StatusPostListItem extends React.Component {
  constructor (props) {
    super(props)

    this.state = {
      edit: false
    }

    this.handleEdit = this.handleEdit.bind(this)
    this.handleCancel = this.handleCancel.bind(this)
    this.handleDestroy = this.handleDestroy.bind(this)
    this.handleLikeToggle = this.handleLikeToggle.bind(this)
    this.handleUpdate = this.handleUpdate.bind(this)
  }

  handleEdit () {
    this.setState({ edit: true })
  }

  handleCancel () {
    this.setState({ edit: false })
  }

  handleDestroy () {
    if (!window.confirm('Delete this post?')) { return }

    StatusPostApi.destroy(this.props.token, this.props.post.id).then(
      this.props.onDestroy,
      this.props.onError
    )
  }

  handleLikeToggle () {
    const action = this.props.post.liked ? 'unlike' : 'like'
    StatusPostApi[action](this.props.token, this.props.post.id).then(
      this.props.onUpdate,
      this.props.onError
    )
  }

  handleUpdate (params) {
    StatusPostApi.update(this.props.token, this.props.post.id, params).then(
      json => {
        this.setState({ edit: false })
        this.props.onUpdate(json)
      },
      this.props.onError
    )
  }

  render () {
    const e = React.createElement

    return this.state.edit ? (
      e(EditStatusPostForm, {
        post: this.props.post,
        onCancel: this.handleCancel,
        onSubmit: this.handleUpdate
      })
    ) : (
      e(StatusPost, {
        currentUser: this.props.currentUser,
        post: this.props.post,
        showReplyCount: this.props.showReplyCount,
        onEdit: this.handleEdit,
        onDestroy: this.handleDestroy,
        onPageChange: this.props.onPageChange,
        onLikeToggle: this.handleLikeToggle
      })
    )
  }
}

export default StatusPostListItem
