import React from 'react'
import './PleaseWait.css'

// Used to indicate that something is currently loading.
function PleaseWait (props) {
  const e = React.createElement

  return (
    e('div', { className: 'please-wait' }, 'Loading...')
  )
}

export default PleaseWait
