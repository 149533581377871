import React from 'react'
import './EditMediaTagsPage.css'

import SimpleButton from '../../components/SimpleButton/SimpleButton.js'
import PleaseWait from '../../components/PleaseWait/PleaseWait.js'
import SimpleDropdown from '../../components/SimpleDropdown/SimpleDropdown.js'

import localName from '../../helpers/localName.js'

import MediaApi from '../../api/Media.js'
import TagsApi from '../../api/Tag.js'

// writeme
class EditMediaTagsPage extends React.Component {
  constructor (props) {
    super(props)

    this.state = {
      newTag: '',
      media: null,
      tags: {}
    }

    this.fetchData = this.fetchData.bind(this)
    this.handleNewTagChange = this.handleNewTagChange.bind(this)
    this.handleCreate = this.handleCreate.bind(this)
    this.handleDelete = this.handleDelete.bind(this)
  }

  componentDidMount () {
    this.fetchData()

    TagsApi.all().then(
      json => {
        const nameToId = {}
        json.items.forEach(tag => { nameToId[tag.name] = tag.id })
        this.setState({ tags: nameToId })
      },
      this.props.onError
    )
  }

  fetchData () {
    MediaApi.show(this.props.id).then(
      json => this.setState({ media: json }),
      this.props.onError
    )
  }

  handleNewTagChange (value) { this.setState({ newTag: value }) }

  handleCreate () {
    const tagId = this.state.tags[this.state.newTag]

    const params = {
      mediaTagsAttributes: [{ tagId: tagId }]
    }

    MediaApi.update(this.props.token, this.props.id, params).then(
      () => {
        this.setState({ newTag: '' })
        this.fetchData()
      },
      this.props.onError
    )
  }

  handleDelete (mediaTag) {
    if (!window.confirm(`Remove "${mediaTag.tag.name}"?`)) { return }

    const params = {
      mediaTagsAttributes: [{ id: mediaTag.id, _destroy: true }]
    }

    MediaApi.update(this.props.token, this.props.id, params).then(
      this.fetchData, this.props.onError
    )
  }

  render () {
    const e = React.createElement

    if (!this.state.media) { return e(PleaseWait) }

    const mediaTags = this.state.media.mediaTags.map(mediaTag => (
      e('div', { key: mediaTag.id, className: 'edit-media-tags-page-list-item' },
        e('div', { className: 'edit-media-tags-page-list-item-name' },
          mediaTag.tag.name
        ),
        e(SimpleButton, {
          theme: 'delete',
          onClick: () => this.handleDelete(mediaTag)
        }, 'Remove')
      )
    ))

    return e('div', { className: 'edit-media-tags-page' },
      e('div', { className: 'edit-media-tags-page-name' },
        localName(this.state.media)
      ),
      e('div', { className: 'edit-media-tags-page-list' }, mediaTags),
      e('div', { className: 'edit-media-tags-page-add-new-tag-form' },
        e(SimpleDropdown, {
          value: this.state.newTag,
          options: Object.keys(this.state.tags),
          onChange: this.handleNewTagChange
        }),
        e(SimpleButton, {
          theme: 'create',
          onClick: this.handleCreate
        }, 'Add')
      ),
      e('div', { className: 'edit-media-tags-page-back-button' },
        e(SimpleButton, {
          onClick: () => this.props.onPageChange('media', this.props.id)
        }, 'Back')
      )
    )
  }
}

export default EditMediaTagsPage
