import React from 'react'
import './MediaNameForm.css'

import SimpleButton from '../SimpleButton/SimpleButton'
import SimpleCheckbox from '../SimpleCheckbox/SimpleCheckbox'
import SimpleDropdown from '../SimpleDropdown/SimpleDropdown'
import SimpleInput from '../SimpleInput/SimpleInput'

// import { iso639_1 } from '../../helpers/commonEnums'

// Represents a single "name" row on the Edit Media form.
function MediaNameForm (props) {
  if (props.name._destroy) { return null }

  return (
    <div className='media-name-form'>
      <div className='media-name-form-text'>
        <SimpleInput
          value={props.name.text}
          onChange={props.onTextChange}
        />
      </div>
      <div className='media-name-form-language'>
        <SimpleDropdown
          value={props.name.language}
          options={['de', 'en', 'es', 'fr', 'it', 'ja', 'ko', 'pt', 'ru', 'zh']}
          onChange={props.onLanguageChange}
        />
      </div>
      <div className='media-name-form-variant'>
        <SimpleCheckbox
          label='Romanised?'
          checked={props.name.latin}
          onChange={props.onLatinToggle}
          disabled={!['ja', 'ko', 'ru', 'zh'].includes(props.name.language)}
        />
      </div>
      <SimpleButton theme='delete' onClick={props.onRemove}>
        Remove
      </SimpleButton>
    </div>
  )
}

export default MediaNameForm
