import React from 'react'

import AllCharactersPage from './pages/AllCharactersPage/AllCharactersPage'
import AllModeratorsPage from './pages/AllModeratorsPage/AllModeratorsPage'
import AllReviewsPage from './pages/AllReviewsPage/AllReviewsPage'
import AllStaffPage from './pages/AllStaffPage/AllStaffPage'
import AllStudiosPage from './pages/AllStudiosPage/AllStudiosPage'
import AllTagsPage from './pages/AllTagsPage/AllTagsPage'
import AllUserReportsPage from './pages/AllUserReportsPage/AllUserReportsPage'
import BrowsePage from './pages/BrowsePage/BrowsePage'
import CharacterPage from './pages/CharacterPage/CharacterPage'
import CreateAccountPage from './pages/CreateAccountPage/CreateAccountPage'
import CreateCharacterPage from './pages/CreateCharacterPage/CreateCharacterPage'
import CreateMediaPage from './pages/CreateMediaPage/CreateMediaPage'
import CreateReviewPage from './pages/CreateReviewPage/CreateReviewPage'
import CreateStaffPage from './pages/CreateStaffPage/CreateStaffPage'
import CreateStudioPage from './pages/CreateStudioPage/CreateStudioPage'
import CreateUserReportPage from './pages/CreateUserReportPage/CreateUserReportPage'
import EditMediaPage from './pages/EditMediaPage/EditMediaPage'
import EditMediaTagsPage from './pages/EditMediaTagsPage/EditMediaTagsPage'
import EditAccountPage from './pages/EditAccountPage/EditAccountPage'
import EditCharacterPage from './pages/EditCharacterPage/EditCharacterPage'
import EditProfilePage from './pages/EditProfilePage/EditProfilePage'
import EditReviewPage from './pages/EditReviewPage/EditReviewPage'
import EditStaffPage from './pages/EditStaffPage/EditStaffPage'
import EditStudioPage from './pages/EditStudioPage/EditStudioPage'
import LogInPage from './pages/LogInPage/LogInPage'
import MediaPage from './pages/MediaPage/MediaPage'
import NotFoundPage from './pages/NotFoundPage/NotFoundPage'
import ReviewPage from './pages/ReviewPage/ReviewPage'
import SocialPage from './pages/SocialPage/SocialPage'
import StatusPostPage from './pages/StatusPostPage/StatusPostPage'
import StaffPage from './pages/StaffPage/StaffPage'
import StudioPage from './pages/StudioPage/StudioPage'
import UserPage from './pages/UserPage/UserPage'
import UserAdminPage from './pages/UserAdminPage/UserAdminPage'
import UserDataPage from './pages/UserDataPage/UserDataPage'

// This is used as a "wrapper" around whatever the current page is:
import StandardLayout from './components/StandardLayout/StandardLayout'

// Sort of like a discount React Router...
class SiteRouter extends React.Component {
  constructor (props) {
    super(props)
    this.state = { loading: true }

    this.handleNavigation = this.handleNavigation.bind(this)
    this.handlePageChange = this.handlePageChange.bind(this)
  }

  componentDidMount () {
    this.handlePageChange()
    window.addEventListener('popstate', this.handlePageChange)
  }

  componentWillUnmount () {
    window.removeEventListener('popstate', this.handlePageChange)
  }

  // Called when the back/forward button is pressed (and when the page loads).
  handlePageChange () {
    const bits = this.currentPath().split('/')

    this.setState({
      loading: false,
      pageKind: bits[1] || '',
      pageId: bits[2] || null
    })
  }

  // Called by various child components to navigate to a different page.
  handleNavigation (kind, id) {
    this.props.onClearError()
    this.setState({ pageKind: kind || '', pageId: id || null })

    const p = process.env.REACT_APP_PATHLESS_ROUTING ? '?p=' : ''
    window.history.pushState(null, 'GYARU', `${p}${this.currentPath()}`)

    const newPath = [kind || '', id || ''].filter(s => s).join('/')
    window.history.replaceState(null, 'GYARU', `${p}/${newPath}`)
  }

  // If we can't rely on `/some/path` working, use `?p=/some/path` instead:
  currentPath () {
    if (process.env.REACT_APP_PATHLESS_ROUTING) {
      return new URLSearchParams(window.location.search).get('p') || '/'
    }

    return window.location.pathname
  }

  render () {
    const e = React.createElement

    if (this.state.loading) { return null }

    const id = this.state.pageId

    const props = { ...this.props, onPageChange: this.handleNavigation }
    const propsWithId = { ...props, id: id, key: id }

    let page = null
    switch (this.state.pageKind) {
      case 'media':
        page = id ? e(MediaPage, propsWithId) : e(BrowsePage, props)
        break

      case 'moderators':
        page = e(AllModeratorsPage, props)
        break

      case 'characters':
        page = id ? e(CharacterPage, propsWithId) : e(AllCharactersPage, props)
        break

      case 'create-account':
        page = e(CreateAccountPage, props)
        break

      case 'create-character':
        page = e(CreateCharacterPage, propsWithId) // NOTE: ID = *media* ID here!
        break

      case 'create-media':
        page = e(CreateMediaPage, props)
        break

      case 'create-review':
        page = e(CreateReviewPage, propsWithId) // NOTE: ID = *media* ID here!
        break

      case 'create-staff':
        page = e(CreateStaffPage, props)
        break

      case 'create-studio':
        page = e(CreateStudioPage, props)
        break

      case 'create-user-report':
        page = e(CreateUserReportPage, propsWithId) // ID = status post ID
        break

      case 'edit-account':
        page = e(EditAccountPage, props)
        break

      case 'edit-character':
        page = e(EditCharacterPage, propsWithId)
        break

      case 'edit-media':
        page = e(EditMediaPage, propsWithId)
        break

      case 'edit-media-tags':
        page = e(EditMediaTagsPage, propsWithId)
        break

      case 'edit-profile':
        page = e(EditProfilePage, props)
        break

      case 'edit-review':
        page = e(EditReviewPage, propsWithId)
        break

      case 'edit-staff':
        page = e(EditStaffPage, propsWithId)
        break

      case 'edit-studio':
        page = e(EditStudioPage, propsWithId)
        break

      case 'log-in':
        page = e(LogInPage, props)
        break

      case 'reviews':
        page = id ? e(ReviewPage, propsWithId) : e(AllReviewsPage, props)
        break

      case 'staff':
        page = id ? e(StaffPage, propsWithId) : e(AllStaffPage, props)
        break

      case 'status-posts':
        page = e(StatusPostPage, propsWithId)
        break

      case 'studios':
        page = id ? e(StudioPage, propsWithId) : e(AllStudiosPage, props)
        break

      case 'tags':
        page = e(AllTagsPage, props)
        break

      case 'users':
        page = e(UserPage, propsWithId)
        break

      case 'user-admin':
        page = e(UserAdminPage, propsWithId)
        break

      case 'user-data':
        page = e(UserDataPage, props)
        break

      case 'user-reports':
        page = e(AllUserReportsPage, props)
        break

      case '':
        page = e(SocialPage, props)
        break

      default:
        page = e(NotFoundPage, props)
        break
    }

    return e(StandardLayout, props, page)
  }
}

export default SiteRouter
