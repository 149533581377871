import apiCall from './apiCall.js'
import buildGetQuery from './buildGetQuery.js'

// Handles all list entry requests.
class ListEntry {
  static all (params) {
    const data = buildGetQuery(params)
    return apiCall('GET', 'api/v1/list_entries', null, data)
  }

  static show (id) {
    return apiCall('GET', `api/v1/list_entries/${id}`)
  }

  static create (token, params) {
    const data = { listEntry: params }
    return apiCall('POST', 'api/v1/list_entries', token, data)
  }

  static update (token, id, params) {
    const data = { listEntry: params }
    return apiCall('PATCH', `api/v1/list_entries/${id}`, token, data)
  }

  static destroy (token, id) {
    return apiCall('DELETE', `api/v1/list_entries/${id}`, token)
  }

  static clear (token, params) {
    return apiCall('DELETE', 'api/v1/list_entries/clear', token, params)
  }

  static statuses () {
    return apiCall('GET', 'api/v1/list_entries/statuses')
  }

  static uploadAnilistData (token, file) {
    const params = new FormData()
    if (file) { params.append('gdpr', file) }
    return apiCall('POST', 'api/v1/list_entries/import/anilist', token, params)
  }
}

export default ListEntry
