import React from 'react'
import './LikeList.css'

import toSentence from '../../helpers/toSentence'

// Display a list of users who liked a post.
function LikeList (props) {
  if (props.likes.length === 0) { return null }

  const users = props.likes.map(like => like.name)

  return (
    <div className='like-list'>
      {toSentence(users)} {props.likes.length > 1 ? 'like' : 'likes'} this.
    </div>
  )
}

export default LikeList
