import React from 'react'
import './RecentReviews.css'

import PleaseWait from '../PleaseWait/PleaseWait'
import RecentReviewItem from '../RecentReviewItem/RecentReviewItem'

import ReviewApi from '../../api/Review'

// writeme
class RecentReviews extends React.Component {
  constructor (props) {
    super(props)

    this.state = { items: null }

    this.handleClick = this.handleClick.bind(this)
  }

  componentDidMount () {
    const params = {
      kind: this.props.kind || '',
      sortField: 'dateAdded',
      sortOrder: 'DESC',
      limit: 4
    }

    ReviewApi.all(params).then(
      json => this.setState({ items: json.items }),
      this.props.onError
    )
  }

  handleClick (item) {
    this.props.onPageChange('reviews', item.id)
  }

  render () {
    const items = this.state.items ? this.state.items.map(item => (
      <RecentReviewItem
        key={item.id}
        review={item}
        onClick={() => this.handleClick(item)}
      />
    )) : (
      <PleaseWait />
    )

    return (
      <div className='recent-reviews'>
        <div className='recent-reviews-title'>
          Latest {this.props.kind || 'reviews'} reviews
        </div>
        <div className='recent-reviews-items'>
          {items}
        </div>
      </div>
    )
  }
}

export default RecentReviews
