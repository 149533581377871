import React from 'react'
import './CharacterCard.css'

import RemoteImage from '../RemoteImage/RemoteImage.js'

// writeme
function CharacterCard (props) {
  const appearances = props.character.associatedCharacters

  const info = props.mediaId ? (
    (appearances.find(a => a.media.id === props.mediaId) || {}).kind
  ) : (
    `${appearances.length} associated media`
  )

  return (
    <button className='character-card' onClick={props.onClick}>
      <div className='character-card-name'>{props.character.name}</div>
      <div className='character-card-image-container'>
        <RemoteImage
          className='character-card-image'
          remotePath={props.character.picture}
          fallback='no-thumbnail.png'
          alt='Picture'
        />
      </div>
      <div className='character-card-info'>{info}</div>
    </button>
  )
}

export default CharacterCard
