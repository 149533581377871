import React from 'react'
import './EditAccountPage.css'

import SimpleButton from '../../components/SimpleButton/SimpleButton.js'
import ChangeEmailForm from '../../components/ChangeEmailForm/ChangeEmailForm.js'
import ChangePasswordForm from '../../components/ChangePasswordForm/ChangePasswordForm.js'

import AccountApi from '../../api/Account.js'

// writeme
class EditAccountPage extends React.Component {
  constructor (props) {
    super(props)

    this.state = {}

    this.handleDelete = this.handleDelete.bind(this)
  }

  handleDelete () {
    if (!window.confirm('Are you sure? This cannot be undone!')) { return }

    AccountApi.destroy(this.props.token).then(
      () => {
        this.props.onLogOut() // Clear our local copy of the expired token.
        this.props.onPageChange() // Go back to the main page.
      },
      this.props.onError
    )
  }

  render () {
    const e = React.createElement

    // We briefly stay on this page with no user after account deletion, so:
    if (!this.props.currentUser) { return null }

    return (
      e('div', { className: 'edit-account-page' },

        e('div', { className: 'edit-account-page-section' },
          e('div', { className: 'edit-account-page-section-header' },
            'Change email'
          ),
          e(ChangeEmailForm, {
            token: this.props.token,
            currentUser: this.props.currentUser,
            onChange: this.props.onUserChange,
            onError: this.props.onError
          })
        ),

        e('div', { className: 'edit-account-page-section' },
          e('div', { className: 'edit-account-page-section-header' },
            'Change password'
          ),
          e(ChangePasswordForm, {
            token: this.props.token,
            onError: this.props.onError
          })
        ),

        e('div', { className: 'edit-account-page-section' },
          e('div', { className: 'edit-account-page-section-header' },
            'Delete account'
          ),
          e('div', { className: 'edit-account-page-warning' },
            'Warning: This is permanent, and cannot be undone!'
          ),
          e('div', { className: 'edit-account-page-delete' },
            e(SimpleButton, {
              theme: 'delete',
              onClick: this.handleDelete
            }, 'Delete account')
          )
        )

      )
    )
  }
}

export default EditAccountPage
