import React from 'react'

import EditMediaForm from '../../components/EditMediaForm/EditMediaForm'
import PleaseWait from '../../components/PleaseWait/PleaseWait'

import MediaApi from '../../api/Media'

// writeme
class EditMediaPage extends React.Component {
  constructor (props) {
    super(props)

    this.state = { media: null }

    this.handleSubmit = this.handleSubmit.bind(this)
  }

  componentDidMount () {
    MediaApi.show(this.props.id).then(
      json => this.setState({ media: json }),
      this.props.onError
    )
  }

  handleSubmit (params) {
    MediaApi.update(this.props.token, this.props.id, params).then(
      () => this.props.onPageChange('media', this.props.id),
      this.props.onError
    )
  }

  render () {
    if (!this.state.media) { return <PleaseWait /> }

    return (
      <EditMediaForm
        media={this.state.media}
        onSubmit={this.handleSubmit}
        onCancel={() => this.props.onPageChange('media', this.props.id)}
      />
    )
  }
}

export default EditMediaPage
