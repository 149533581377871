import React from 'react'
import './UserPage.css'

import Avatar from '../../components/Avatar/Avatar'
import MarkdownContent from '../../components/MarkdownContent/MarkdownContent'
import PleaseWait from '../../components/PleaseWait/PleaseWait'
import SimpleButton from '../../components/SimpleButton/SimpleButton'
import StatusPostList from '../../components/StatusPostList/StatusPostList'
import TabGroup from '../../components/TabGroup/TabGroup'
import UserMediaList from '../../components/UserMediaList/UserMediaList'
import UserReviewList from '../../components/UserReviewList/UserReviewList'

import AccountApi from '../../api/Account'
import UserApi from '../../api/User'

// writeme
class UserPage extends React.Component {
  constructor (props) {
    super(props)

    this.state = { user: null }

    this.handleLogOut = this.handleLogOut.bind(this)
  }

  componentDidMount () {
    UserApi.findByName(this.props.token, this.props.id).then(
      json => this.setState({ user: json }),
      this.props.onError
    )
  }

  handleLogOut () {
    if (!window.confirm('Are you sure you want to log out?')) { return }

    AccountApi.logOut(this.props.token).then(
      () => {
        this.props.onLogOut() // Clear our local copy of the expired token.
        this.props.onPageChange() // Go back to the main page.
      },
      this.props.onError
    )
  }

  actions () {
    const e = React.createElement

    if (!this.props.currentUser) {
      // NOT LOGGED IN: No actions available.
      return null
    } else if (this.props.currentUser.id === this.state.user.id) {
      // LOGGED IN, LOOKING AT SELF: Show "edit profile" and "log out" buttons.
      return (
        e('div', { className: 'actions' },
          e(SimpleButton, {
            theme: 'primary',
            onClick: () => this.props.onPageChange('edit-profile')
          }, 'Edit Profile'),
          e(SimpleButton, {
            theme: 'delete',
            onClick: this.handleLogOut
          }, 'Log out')
        )
      )
    } else if (this.props.currentUser.administrator) {
      // LOGGED IN, NOT LOOKING AT SELF, AS AN ADMIN: Show moderation options.
      return (
        e('div', { className: 'actions' },
          e(SimpleButton, {
            theme: 'primary',
            onClick: () => this.props.onPageChange('user-admin', this.props.id)
          }, 'Moderator Tools')
        )
      )
    } else {
      // LOGGED IN, NOT LOOKING AT SELF, NOT AN ADMIN: Nothing for now...
      return null
    }
  }

  render () {
    const e = React.createElement

    if (!this.state.user) { return e(PleaseWait) }

    return (
      e('div', { className: 'user-page' },
        e('div', { className: 'avatar-container' },
          e(Avatar, { user: this.state.user, large: true })
        ),
        e('div', { className: 'name' }, this.state.user.name),
        this.state.user.administrator ? e('div', { className: 'role' }, 'Moderator') : null,
        this.actions(),
        e('div', { className: 'about' },
          e(MarkdownContent, { text: this.state.user.about })
        ),
        e('div', { className: 'status-post-container' },
          e(TabGroup, { labels: ['Status', 'Anime', 'Manga', 'Reviews'] },
            e(StatusPostList, {
              token: this.props.token,
              userId: this.state.user.id,
              currentUser: this.props.currentUser,
              onPageChange: this.props.onPageChange,
              onError: this.props.onError
            }),
            e(UserMediaList, {
              currentUser: this.props.currentUser,
              key: 'anime',
              kind: 'anime',
              token: this.props.token,
              userId: this.state.user.id,
              onPageChange: this.props.onPageChange,
              onError: this.props.onError
            }),
            e(UserMediaList, {
              currentUser: this.props.currentUser,
              key: 'manga',
              kind: 'manga',
              token: this.props.token,
              userId: this.state.user.id,
              onPageChange: this.props.onPageChange,
              onError: this.props.onError
            }),
            e(UserReviewList, {
              token: this.props.token,
              userId: this.state.user.id,
              onPageChange: this.props.onPageChange,
              onError: this.props.onError
            })
          )
        )
      )
    )
  }
}

export default UserPage
