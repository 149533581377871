import React from 'react'
import './NotificationSummary.css'

import Avatar from '../Avatar/Avatar.js'

function userFor (notification) {
  if (notification.statusPost) {
    return notification.statusPost.user
  } else if (notification.like) {
    return notification.like.user
  }

  return {} // Currently doesn't happen, but may in the future.
}

function statusPostFor (notification) {
  if (notification.statusPost) {
    return notification.statusPost
  } else if (notification.like) {
    return notification.like.statusPost
  }

  return {} // Currently doesn't happen, but may in the future.
}

function textFor (notification) {
  switch (notification.reason) {
    case 'reply':
      return 'replied to your post'
    case 'like':
      return 'liked your post'
    case 'mention':
      return 'mentioned you'
    default:
      return '[message missing]'
  }
}

// writeme
function NotificationSummary (props) {
  const e = React.createElement

  const text = textFor(props.notification)
  const user = userFor(props.notification)
  const post = statusPostFor(props.notification)

  return (
    e('div', { className: 'notification-summary' },
      e('button', {
        className: 'notification-summary-icon',
        onClick: () => props.onPageChange('users', user.name)
      }, e(Avatar, { user: user })),
      e('button', {
        className: 'notification-summary-user',
        onClick: () => props.onPageChange('status-posts', post.parentId || post.id)
      }, user.name),
      e('button', {
        className: 'notification-summary-link',
        onClick: () => props.onPageChange('status-posts', post.parentId || post.id)
      }, text),
      e('button', {
        className: 'notification-summary-delete',
        onClick: props.onDestroy
      }, 'Delete')
    )
  )
}

export default NotificationSummary
