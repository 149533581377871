import React from 'react'
import './StatusPostList.css'

import PleaseWait from '../PleaseWait/PleaseWait.js'
import SimpleButton from '../SimpleButton/SimpleButton.js'
import StatusPostListItem from '../StatusPostListItem/StatusPostListItem.js'
import StatusPostEditor from '../StatusPostEditor/StatusPostEditor.js'

import safeConcat from '../../helpers/safeConcat.js'

import StatusPostApi from '../../api/StatusPost.js'

// writeme
class StatusPostList extends React.Component {
  constructor (props) {
    super(props)

    this.state = {
      page: 0,
      posts: null,
      more: false,
      loading: true
    }

    this.fetchData = this.fetchData.bind(this)
    this.handleMore = this.handleMore.bind(this)
    this.handleCreatePost = this.handleCreatePost.bind(this)
    this.handleUpdatePost = this.handleUpdatePost.bind(this)
    this.handleDestroyPost = this.handleDestroyPost.bind(this)
  }

  componentDidMount () {
    this.fetchData()
  }

  fetchData () {
    const perPage = 20

    const params = {
      user: this.props.userId || '',
      offset: this.state.page * perPage,
      limit: perPage
    }

    this.setState({ loading: true })
    StatusPostApi.all(this.props.token, params).then(
      json => {
        const data = safeConcat(this.state.posts, json.items)
        const more = json.items.length === perPage
        this.setState({ posts: data, more: more, loading: false })
      },
      this.props.onError
    )
  }

  handleMore () {
    this.setState(
      { page: this.state.page + 1 },
      this.fetchData
    )
  }

  handleCreatePost (params) {
    StatusPostApi.create(this.props.token, params).then(
      json => {
        this.setState({
          posts: [json].concat(this.state.posts || []),
          postSubmittedAt: Date() // Force-reset the editor.
        })
      },
      this.props.onError
    )
  }

  handleUpdatePost (json) {
    const posts = this.state.posts.map(post => post.id === json.id ? json : post)
    this.setState({ posts: posts })
  }

  handleDestroyPost (json) {
    const posts = this.state.posts.filter(post => post.id !== json.id)
    this.setState({ posts: posts })
  }

  render () {
    const e = React.createElement

    const editor = this.props.token && !this.props.userId ? (
      e(StatusPostEditor, {
        key: this.state.postSubmittedAt,
        onSubmit: this.handleCreatePost
      })
    ) : null

    const posts = this.state.posts ? (
      this.state.posts.map(post => (
        e(StatusPostListItem, {
          key: post.id,
          post: post,
          token: this.props.token,
          currentUser: this.props.currentUser,
          showReplyCount: true,
          onError: this.props.onError,
          onPageChange: this.props.onPageChange,
          onUpdate: this.handleUpdatePost,
          onDestroy: () => this.handleDestroyPost(post)
        })
      ))
    ) : (
      e(PleaseWait)
    )

    const moreContent = this.state.more ? (
      e(SimpleButton, {
        disabled: this.state.loading,
        onClick: this.handleMore
      }, 'More...')
    ) : null

    return (
      e('div', { className: 'status-post-list' },
        e('div', { className: 'status-post-list-head' }, editor),
        e('div', { className: 'status-post-list-body' }, posts),
        e('div', { className: 'status-post-list-foot' }, moreContent)
      )
    )
  }
}

export default StatusPostList
