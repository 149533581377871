import React from 'react'
import './ReviewPage.css'

import SimpleButton from '../../components/SimpleButton/SimpleButton.js'
import MarkdownContent from '../../components/MarkdownContent/MarkdownContent.js'
import PleaseWait from '../../components/PleaseWait/PleaseWait.js'

import canEdit from '../../helpers/canEdit.js'
import localName from '../../helpers/localName.js'

import ReviewApi from '../../api/Review.js'

// Displays a review.
class ReviewPage extends React.Component {
  constructor (props) {
    super(props)

    this.state = { review: null }

    this.handleDelete = this.handleDelete.bind(this)
  }

  componentDidMount () {
    ReviewApi.show(this.props.id).then(
      json => this.setState({ review: json }),
      this.props.onError
    )
  }

  handleDelete () {
    const message = 'Are you sure you want to delete this review?'
    if (!window.confirm(message)) { return }

    ReviewApi.destroy(this.props.token, this.props.id).then(
      () => this.props.onPageChange('reviews'),
      this.props.onError
    )
  }

  render () {
    const e = React.createElement

    if (!this.state.review) { return e(PleaseWait) }

    const goTo = this.props.onPageChange

    const actions = canEdit(this.props.currentUser, this.state.review) ? (
      e('div', { className: 'review-page-actions' },
        e(SimpleButton, {
          theme: 'primary',
          onClick: () => goTo('edit-review', this.props.id)
        }, 'Edit'),
        e(SimpleButton, {
          theme: 'delete',
          onClick: this.handleDelete
        }, 'Delete')
      )
    ) : null

    return (
      e('div', { className: 'review-page' },
        actions,
        e('div', { className: 'review-page-summary' },
          this.state.review.summary
        ),
        e('button', {
          className: 'review-page-media',
          onClick: () => goTo('media', this.state.review.media.id)
        }, localName(this.state.review.media)),
        e('button', {
          className: 'review-page-user',
          onClick: () => goTo('users', this.state.review.user.name)
        }, this.state.review.user.name),
        e('div', { className: 'review-page-content' },
          e(MarkdownContent, { text: this.state.review.content })
        )
      )
    )
  }
}

export default ReviewPage
