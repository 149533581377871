import * as commonmark from 'commonmark'

// Convert markdown text into HTML text.
function parseMarkdown (text) {
  const reader = new commonmark.Parser()
  const writer = new commonmark.HtmlRenderer({ safe: true, softbreak: '<br>' })
  const parsed = reader.parse(text)
  return writer.render(parsed)
}

export default parseMarkdown
