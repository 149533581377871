import React from 'react'
import './SimpleCheckbox.css'

// A simple toggle.
function SimpleCheckbox (props) {
  const e = React.createElement

  const currentState = props.checked ? 'on' : 'off'

  return (
    e('label', { className: `simple-checkbox ${currentState}` },
      e('button', {
        className: 'simple-checkbox-input',
        disabled: props.disabled,
        onClick: props.onChange
      }, currentState),
      e('span', { className: 'simple-checkbox-label' }, props.label)
    )
  )
}

export default SimpleCheckbox
