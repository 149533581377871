import apiCall from './apiCall.js'

// Handles requests related to creating, viewing, or deleting user reports.
class UserReport {
  static all (token) {
    return apiCall('GET', 'api/v1/user_reports', token)
  }

  static create (token, params) {
    return apiCall('POST', 'api/v1/user_reports', token, { userReport: params })
  }

  static destroy (token, id) {
    return apiCall('DELETE', `api/v1/user_reports/${id}`, token)
  }
}

export default UserReport
