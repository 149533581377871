import React from 'react'

import MediaSearch from '../MediaSearch/MediaSearch'
import InputLabel from '../InputLabel/InputLabel'
import SimpleButton from '../SimpleButton/SimpleButton'
import SimpleDropdown from '../SimpleDropdown/SimpleDropdown'

import { associatedMediaKinds } from '../../helpers/commonEnums'

import MediaApi from '../../api/Media'

// writeme
class LinkAssociatedMediaForm extends React.Component {
  constructor (props) {
    super(props)

    this.state = { kind: associatedMediaKinds[0] }

    this.handleKindChange = this.handleKindChange.bind(this)
    this.handleSubmit = this.handleSubmit.bind(this)
  }

  handleKindChange (value) {
    this.setState({ kind: value })
  }

  handleSubmit (media) {
    const params = {
      associatedMediaAttributes: [
        { secondMediaId: media.id, kind: this.state.kind }
      ]
    }

    MediaApi.update(this.props.token, this.props.mediaId, params).then(
      this.props.onComplete,
      this.props.onError
    )
  }

  render () {
    return (
      <MediaSearch
        onError={this.props.onError}
        onClick={this.handleSubmit}
      >
        <div className='link-associated-media-form-actions'>
          <InputLabel label='Kind'>
            <SimpleDropdown
              value={this.state.kind}
              options={associatedMediaKinds}
              onChange={this.handleKindChange}
            />
          </InputLabel>
          <SimpleButton onClick={this.props.onComplete}>Cancel</SimpleButton>
        </div>

      </MediaSearch>
    )
  }
}

export default LinkAssociatedMediaForm
