import React from 'react'
import './StatusPostEditor.css'

import mentionedUsers from '../../helpers/mentionedUsers'

import MarkdownEditor from '../MarkdownEditor/MarkdownEditor.js'
import SimpleCheckbox from '../SimpleCheckbox/SimpleCheckbox.js'

// writeme
class StatusPostEditor extends React.Component {
  constructor (props) {
    super(props)

    const post = this.props.post || {}

    this.state = {
      adult: post.adult || false,
      spoiler: post.spoiler || false
    }

    this.handleAdultToggle = this.handleAdultToggle.bind(this)
    this.handleSpoilerToggle = this.handleSpoilerToggle.bind(this)
    this.handleSubmit = this.handleSubmit.bind(this)
  }

  handleAdultToggle () {
    this.setState({ adult: !this.state.adult })
  }

  handleSpoilerToggle () {
    this.setState({ spoiler: !this.state.spoiler })
  }

  handleSubmit (text) {
    const mentions = mentionedUsers(text).map(name => ({ name: name }))

    this.props.onSubmit({
      adult: this.state.adult,
      spoiler: this.state.spoiler,
      content: text,
      notifiedUsersAttributes: mentions
    })
  }

  render () {
    const e = React.createElement

    return (
      e('div', { className: 'status-post-editor' },
        e(MarkdownEditor, {
          initialText: (this.props.post || {}).content,
          onSubmit: this.handleSubmit
        },
        e('div', { className: 'status-post-editor-footer' },
          e('div', { className: 'status-post-editor-toggles' },
            e(SimpleCheckbox, {
              label: '18+ Content',
              checked: this.state.adult,
              onChange: this.handleAdultToggle
            }),
            e(SimpleCheckbox, {
              label: 'Spoilers',
              checked: this.state.spoiler,
              onChange: this.handleSpoilerToggle
            })
          ),
          e('div', { className: 'status-post-editor-extras' },
            this.props.children
          )
        )
        )
      )
    )
  }
}

export default StatusPostEditor
