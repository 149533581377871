import React from 'react'
import './UserReviewList.css'

import PleaseWait from '../PleaseWait/PleaseWait.js'
import UserReviewListItem from '../UserReviewListItem/UserReviewListItem.js'

import ReviewApi from '../../api/Review.js'

// A lists of reviews authored by a specific user.
class UserReviewList extends React.Component {
  constructor (props) {
    super(props)

    this.state = { reviews: null }
  }

  componentDidMount () {
    ReviewApi.all({ user: this.props.userId }).then(
      json => this.setState({ reviews: json.items }),
      this.props.onError
    )
  }

  render () {
    const e = React.createElement

    if (!this.state.reviews) { return e(PleaseWait) }

    const items = this.state.reviews.map(review => (
      e(UserReviewListItem, {
        key: review.id,
        review: review,
        onPageChange: this.props.onPageChange
      })
    ))

    return e('div', { className: 'user-review-list' }, items)
  }
}

export default UserReviewList
