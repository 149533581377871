import React from 'react'
import './ListImportRow.css'

// Unknown entries appear as "anime 1234" or "manga 5678", so show a link:
function maybeLink(name, data) {
  if (!data.errors.length) { return name }

  const url = `https://anilist.co/${name.replace(' ', '/')}`
  return <a href={url} target='_blank' rel='noopener noreferrer'>{name}</a>
}

// writeme
function ListImportRow (props) {
  const okay = !props.data.errors.length

  return (
    <div className={`list-import-row ${okay ? 'okay' : 'fail'}`}>
      <div className='list-import-row-kind'>{props.data.kind}</div>
      <div className='list-import-row-name'>{maybeLink(props.name, props.data)}</div>
      <div className='list-import-row-status'>{okay ? 'Success' : 'Failure'}</div>
      <div className='list-import-row-errors'>{props.data.errors.join(' / ')}</div>
    </div>
  )
}

export default ListImportRow
