import React from 'react'

import AssociatedMediaList from '../AssociatedMediaList/AssociatedMediaList'
import EditAssociatedMediaForm from '../EditAssociatedMediaForm/EditAssociatedMediaForm'
import LinkAssociatedMediaForm from '../LinkAssociatedMediaForm/LinkAssociatedMediaForm'
import SimpleButton from '../SimpleButton/SimpleButton'

// Essentially a "wrapper" used to switch between the add/edit/show components.
class AssociatedMedia extends React.Component {
  constructor (props) {
    super(props)

    this.state = { mode: 'show' }

    this.handleShowCurrent = this.handleShowCurrent.bind(this)
    this.handleEditCurrent = this.handleEditCurrent.bind(this)
    this.handleLinkAnother = this.handleLinkAnother.bind(this)
  }

  handleShowCurrent () { this.setState({ mode: 'show' }) }
  handleEditCurrent () { this.setState({ mode: 'edit' }) }
  handleLinkAnother () { this.setState({ mode: 'link' }) }

  render () {
    if (this.state.mode === 'link') {
      return (
        <LinkAssociatedMediaForm
          token={this.props.token}
          mediaId={this.props.mediaId}
          onError={this.props.onError}
          onComplete={this.handleShowCurrent}
        />
      )
    } else if (this.state.mode === 'edit') {
      return (
        <EditAssociatedMediaForm
          token={this.props.token}
          mediaId={this.props.mediaId}
          onError={this.props.onError}
          onComplete={this.handleShowCurrent}
        />
      )
    }

    const actions = (this.props.currentUser || {}).administrator ? (
      <div className='associated-media-actions'>
        <SimpleButton theme='create' onClick={this.handleLinkAnother}>
          Add another
        </SimpleButton>
        <SimpleButton theme='primary' onClick={this.handleEditCurrent}>
          Edit associations
        </SimpleButton>
      </div>
    ) : null

    return (
      <AssociatedMediaList
        mediaId={this.props.mediaId}
        onError={this.props.onError}
        onClick={media => this.props.onPageChange('media', media.id)}
      >
        {actions}
      </AssociatedMediaList>
    )
  }
}

export default AssociatedMedia
