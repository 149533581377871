import React from 'react'
import './EditStatusPostForm.css'

import SimpleButton from '../SimpleButton/SimpleButton.js'
import StatusPostEditor from '../StatusPostEditor/StatusPostEditor.js'

// writeme
function EditStatusPostForm (props) {
  const e = React.createElement

  return (
    e('div', { className: 'edit-status-post-form' },
      e(StatusPostEditor, { post: props.post, onSubmit: props.onSubmit },
        e(SimpleButton, { onClick: props.onCancel }, 'Cancel')
      )
    )
  )
}

export default EditStatusPostForm
