import React from 'react'
import './EditProfilePage.css'

import SimpleButton from '../../components/SimpleButton/SimpleButton.js'
import InputLabel from '../../components/InputLabel/InputLabel.js'
import ImagePicker from '../../components/ImagePicker/ImagePicker.js'
import MarkdownEditor from '../../components/MarkdownEditor/MarkdownEditor.js'
import SimpleInput from '../../components/SimpleInput/SimpleInput.js'

import ProfileApi from '../../api/Profile.js'

// Allows the user to change various things about their profile.
class EditProfilePage extends React.Component {
  constructor (props) {
    super(props)

    this.state = {
      name: props.currentUser.name,
      avatar: null
    }

    this.handleNameChange = this.handleNameChange.bind(this)
    this.handleAvatarChange = this.handleAvatarChange.bind(this)
    this.handleSubmit = this.handleSubmit.bind(this)
  }

  handleNameChange (event) {
    this.setState({ name: event.target.value })
  }

  handleAvatarChange (file) {
    this.setState({ avatar: file })
  }

  handleSubmit (text) {
    const params = { name: this.state.name, about: text }
    if (this.state.avatar) { params.avatar = this.state.avatar }

    ProfileApi.update(this.props.token, params).then(
      json => {
        this.props.onUserChange(json) // Update the main "currentUser" data.
        this.props.onPageChange('users', json.name)
      },
      this.props.onError
    )
  }

  render () {
    const e = React.createElement

    return (
      e('div', { className: 'edit-profile-page' },
        e('div', { className: 'edit-profile-page-name' },
          e(InputLabel, { label: 'Name' },
            e(SimpleInput, {
              value: this.state.name,
              onChange: this.handleNameChange
            })
          )
        ),
        e('div', { className: 'edit-profile-page-avatar' },
          e(ImagePicker, {
            width: 160,
            height: 160,
            fileSize: '3MB',
            onChange: this.handleAvatarChange
          })
        ),
        e('div', { className: 'edit-profile-page-about' },
          e(InputLabel, { label: 'About' },
            e(MarkdownEditor, {
              initialText: this.props.currentUser.about,
              onSubmit: this.handleSubmit
            },
            e(SimpleButton, {
              onClick: () => this.props.onPageChange('users', this.props.currentUser.name)
            }, 'Cancel')
            )
          )
        ),
        e('div', { className: 'edit-profile-page-links' },
          e(SimpleButton, {
            theme: 'primary',
            onClick: () => this.props.onPageChange('edit-account')
          }, 'Change Email/Password'),
          e(SimpleButton, {
            theme: 'create',
            onClick: () => this.props.onPageChange('user-data')
          }, 'Import/Export Data')
        )
      )
    )
  }
}

export default EditProfilePage
