import React from 'react'
import './MediaTag.css'

// writeme
function MediaTag (props) {
  const e = React.createElement

  return (
    e('div', {
      className: 'media-tag',
      title: props.mediaTag.tag.description || props.mediaTag.tag.name
    }, props.mediaTag.tag.name)
  )
}

export default MediaTag
