import React from 'react'
import './CharacterSearch.css'

import CharacterCard from '../CharacterCard/CharacterCard'
import InputLabel from '../InputLabel/InputLabel.js'
import PleaseWait from '../PleaseWait/PleaseWait.js'
import SimpleButton from '../SimpleButton/SimpleButton.js'
import SimpleInput from '../SimpleInput/SimpleInput.js'

import safeConcat from '../../helpers/safeConcat.js'

import CharacterApi from '../../api/Character.js'

// writeme
class CharacterSearch extends React.Component {
  constructor (props) {
    super(props)

    this.state = {
      search: '',
      page: 0,
      characters: null,
      more: false,
      loading: true
    }

    this.fetchData = this.fetchData.bind(this)
    this.handleSearchChange = this.handleSearchChange.bind(this)
    this.handleMore = this.handleMore.bind(this)
  }

  componentDidMount () {
    this.fetchData()
  }

  componentWillUnmount () {
    window.clearTimeout(this.typingTimeout)
  }

  fetchData () {
    const perPage = 18

    const params = {
      media: this.props.mediaId || '',
      search: this.state.search,
      offset: this.state.page * perPage,
      limit: perPage
    }

    this.setState({ loading: true })
    CharacterApi.all(params).then(
      json => {
        const data = safeConcat(this.state.characters, json.items)
        const more = json.items.length === perPage
        this.setState({ characters: data, more: more, loading: false })
      },
      this.props.onError
    )
  }

  handleSearchChange (event) {
    this.setState({ search: event.target.value })

    // Wait for a short time, to verify that the user has finished typing:
    window.clearTimeout(this.typingTimeout)
    this.typingTimeout = window.setTimeout(() => {
      this.setState(
        { page: 0, characters: null, more: false },
        this.fetchData
      )
    }, 500)
  }

  handleMore () {
    this.setState({ page: this.state.page + 1 }, this.fetchData)
  }

  render () {
    const items = this.state.characters ? (
      this.state.characters.map(character => (
        <CharacterCard
          key={character.id}
          character={character}
          mediaId={this.props.mediaId}
          onClick={() => this.props.onClick(character)}
        />
      ))
    ) : <PleaseWait />

    const moreContent = this.state.more ? (
      <SimpleButton
        disabled={this.state.loading}
        onClick={this.handleMore}
      >
        More...
      </SimpleButton>
    ) : null

    return (
      <div className='character-search'>
        <div className='character-search-filter'>
          <div className='character-search-filter-search'>
            <InputLabel label='Search'>
              <SimpleInput
                label='Search'
                value={this.state.search}
                autoFocus={true}
                onChange={this.handleSearchChange}
              />
            </InputLabel>
          </div>
          <div className='character-search-extras'>{this.props.children}</div>
        </div>
        <div className='character-search-items'>{items}</div>
        <div className='character-search-more'>{moreContent}</div>
      </div>
    )
  }
}

export default CharacterSearch
