import React from 'react'
import './EditEntryForm.css'

import SimpleButton from '../SimpleButton/SimpleButton.js'
import InputLabel from '../InputLabel/InputLabel.js'
import SimpleDropdown from '../SimpleDropdown/SimpleDropdown.js'
import SimpleInput from '../SimpleInput/SimpleInput.js'

import { entryStatuses } from '../../helpers/commonEnums.js'

import ListEntryApi from '../../api/ListEntry.js'

// writeme
class EditEntryForm extends React.Component {
  constructor (props) {
    super(props)

    this.state = {
      score: props.entry.score,
      status: props.entry.status,
      episodes: props.entry.episodes,
      volumes: props.entry.volumes,
      notes: props.entry.notes
    }

    this.handleScoreChange = this.handleScoreChange.bind(this)
    this.handleStatusChange = this.handleStatusChange.bind(this)
    this.handleEpisodesChange = this.handleEpisodesChange.bind(this)
    this.handleVolumesChange = this.handleVolumesChange.bind(this)
    this.handleNotesChange = this.handleNotesChange.bind(this)
    this.handleSubmit = this.handleSubmit.bind(this)
    this.handleRemove = this.handleRemove.bind(this)
  }

  handleScoreChange (event) { this.setState({ score: event.target.value }) }
  handleStatusChange (value) { this.setState({ status: value }) }
  handleEpisodesChange (event) { this.setState({ episodes: event.target.value }) }
  handleVolumesChange (event) { this.setState({ volumes: event.target.value }) }
  handleNotesChange (event) { this.setState({ notes: event.target.value }) }

  handleSubmit () {
    const params = this.state

    ListEntryApi.update(this.props.token, this.props.entry.id, params).then(
      this.props.onChange,
      this.props.onError
    )
  }

  handleRemove () {
    if (!window.confirm('Remove this item from your list?')) { return }

    ListEntryApi.destroy(this.props.token, this.props.entry.id).then(
      () => this.props.onRemove(this.props.entry),
      this.props.onError
    )
  }

  render () {
    const e = React.createElement

    const kind = this.props.entry.media.kind

    return (
      e('div', { className: 'edit-entry-form' },
        e('div', { className: 'edit-entry-form-episodes' },
          e(InputLabel, { label: kind === 'manga' ? 'Chapters' : 'Episodes' },
            e(SimpleInput, {
              type: 'number',
              min: 1,
              value: this.state.episodes,
              onChange: this.handleEpisodesChange
            })
          )
        ),
        e('div', { className: 'edit-entry-form-volumes' },
          e(InputLabel, { label: 'Volumes' },
            e(SimpleInput, {
              type: 'number',
              min: 1,
              disabled: kind !== 'manga',
              value: this.state.volumes,
              onChange: this.handleVolumesChange
            })
          )
        ),
        e('div', { className: 'edit-entry-form-status' },
          e(InputLabel, { label: 'Status' },
            e(SimpleDropdown, {
              value: this.state.status,
              options: entryStatuses,
              onChange: this.handleStatusChange
            })
          )
        ),
        e('div', { className: 'edit-entry-form-score' },
          e(InputLabel, { label: 'Score' },
            e(SimpleInput, {
              type: 'number',
              min: 0,
              max: 100,
              value: this.state.score,
              onChange: this.handleScoreChange
            })
          )
        ),
        e('div', { className: 'edit-entry-form-notes' },
          e(InputLabel, { label: 'Notes' },
            e(SimpleInput, {
              value: this.state.notes,
              onChange: this.handleNotesChange
            })
          )
        ),
        e('div', { className: 'edit-entry-form-submit' },
          e(SimpleButton, { theme: 'create', onClick: this.handleSubmit },
            'Save changes'
          )
        ),
        e('div', { className: 'edit-entry-form-remove' },
          e(SimpleButton, { theme: 'delete', onClick: this.handleRemove },
            'Remove'
          )
        )
      )
    )
  }
}

export default EditEntryForm
