import React from 'react'
import './RecentMediaItem.css'

import localName from '../../helpers/localName'

// Represents a single item in the "recently-added media" box.
function RecentMediaItem (props) {
  return (
    <button className='recent-media-item' onClick={props.onClick}>
      {localName(props.media)} {props.media.adult ? '🔞' : ''}
    </button>
  )
}

export default RecentMediaItem
