import React from 'react'
import './TagForm.css'

import SimpleButton from '../SimpleButton/SimpleButton.js'
import SimpleInput from '../SimpleInput/SimpleInput.js'

import TagApi from '../../api/Tag.js'

// writeme
class TagForm extends React.Component {
  constructor (props) {
    super(props)

    const tag = props.tag || {}

    this.state = {
      name: tag.name || '',
      description: tag.description || ''
    }

    this.handleNameChange = this.handleNameChange.bind(this)
    this.handleDescriptionChange = this.handleDescriptionChange.bind(this)
    this.handleSubmit = this.handleSubmit.bind(this)
  }

  handleNameChange (event) { this.setState({ name: event.target.value }) }
  handleDescriptionChange (event) { this.setState({ description: event.target.value }) }

  unchanged () {
    const tag = this.props.tag || {}

    return (
      this.state.name === (tag.name || '') &&
      this.state.description === (tag.description || '')
    )
  }

  handleSubmit () {
    if (this.props.tag && this.props.tag.id) {
      TagApi.update(this.props.token, this.props.tag.id, this.state).then(
        this.props.onSubmit, this.props.onError
      )
    } else {
      TagApi.create(this.props.token, this.state).then(
        this.props.onSubmit, this.props.onError
      )
    }
  }

  render () {
    const e = React.createElement

    return (
      e('div', { className: 'tag-form' },
        e('div', { className: 'tag-form-name' },
          e(SimpleInput, {
            value: this.state.name,
            onChange: this.handleNameChange
          })
        ),
        e('div', { className: 'tag-form-description' },
          e(SimpleInput, {
            value: this.state.description,
            onChange: this.handleDescriptionChange
          })
        ),
        e('div', { className: 'tag-form-submit' },
          e(SimpleButton, {
            theme: 'primary',
            disabled: this.unchanged(),
            onClick: this.handleSubmit
          }, 'Save')
        ),
        e('div', { className: 'tag-form-extra' }, this.props.children)
      )
    )
  }
}

export default TagForm
