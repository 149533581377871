import apiCall from './apiCall.js'
import buildGetQuery from './buildGetQuery.js'

// Handles all review requests.
class Review {
  static all (params) {
    const data = buildGetQuery(params)
    return apiCall('GET', 'api/v1/reviews', null, data)
  }

  static show (id) {
    return apiCall('GET', `api/v1/reviews/${id}`)
  }

  static create (token, params) {
    const data = { review: params }
    return apiCall('POST', 'api/v1/reviews', token, data)
  }

  static update (token, id, params) {
    const data = { review: params }
    return apiCall('PATCH', `api/v1/reviews/${id}`, token, data)
  }

  static destroy (token, id) {
    return apiCall('DELETE', `api/v1/reviews/${id}`, token)
  }
}

export default Review
