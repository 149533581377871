import React from 'react'
import './EditStudioForm.css'

import SimpleButton from '../SimpleButton/SimpleButton.js'
import InputLabel from '../InputLabel/InputLabel.js'
import ImagePicker from '../ImagePicker/ImagePicker.js'
import MarkdownEditor from '../MarkdownEditor/MarkdownEditor.js'
import SimpleInput from '../SimpleInput/SimpleInput.js'

// writeme
class EditStudioForm extends React.Component {
  constructor (props) {
    super(props)

    const studio = props.studio || {}

    this.state = {
      name: studio.name || '',
      anidbCode: studio.anidbCode || '',
      anilistCode: studio.anilistCode || '',
      animePlanetCode: studio.animePlanetCode || '',
      kitsuCode: studio.kitsuCode || '',
      malCode: studio.malCode || '',
      logo: null
    }

    this.handleNameChange = this.handleNameChange.bind(this)
    this.handleAnidbCodeChange = this.handleAnidbCodeChange.bind(this)
    this.handleAnilistCodeChange = this.handleAnilistCodeChange.bind(this)
    this.handleAnimePlanetCodeChange = this.handleAnimePlanetCodeChange.bind(this)
    this.handleKitsuCodeChange = this.handleKitsuCodeChange.bind(this)
    this.handleMalCodeChange = this.handleMalCodeChange.bind(this)
    this.handleLogoChange = this.handleLogoChange.bind(this)
    this.handleSubmit = this.handleSubmit.bind(this)
  }

  handleNameChange (event) { this.setState({ name: event.target.value }) }
  handleAnidbCodeChange (event) { this.setState({ anidbCode: event.target.value }) }
  handleAnilistCodeChange (event) { this.setState({ anilistCode: event.target.value }) }
  handleAnimePlanetCodeChange (event) { this.setState({ animePlanetCode: event.target.value }) }
  handleKitsuCodeChange (event) { this.setState({ kitsuCode: event.target.value }) }
  handleMalCodeChange (event) { this.setState({ malCode: event.target.value }) }
  handleLogoChange (file) { this.setState({ logo: file }) }

  handleSubmit (text) {
    const params = { ...this.state, about: text }

    // Only include a "logo" attribute if a new image has been specified:
    if (!params.logo) { delete params.logo }

    this.props.onSubmit(params)
  }

  render () {
    const e = React.createElement

    return (
      e('div', { className: 'edit-studio-form' },
        e('div', { className: 'edit-studio-form-logo' },
          e(ImagePicker, {
            height: 160,
            fileSize: '3MB',
            onChange: this.handleLogoChange
          })
        ),
        e('div', { className: 'edit-studio-form-name' },
          e(InputLabel, { label: 'Name' },
            e(SimpleInput, {
              value: this.state.name,
              onChange: this.handleNameChange
            })
          )
        ),
        e('div', { className: 'edit-studio-form-site-anidb' },
          e(InputLabel, { label: 'AniDB ID' },
            e(SimpleInput, {
              value: this.state.anidbCode,
              onChange: this.handleAnidbCodeChange
            })
          )
        ),
        e('div', { className: 'edit-studio-form-site-anilist' },
          e(InputLabel, { label: 'Anilist ID' },
            e(SimpleInput, {
              value: this.state.anilistCode,
              onChange: this.handleAnilistCodeChange
            })
          )
        ),
        e('div', { className: 'edit-studio-form-site-anime-planet' },
          e(InputLabel, { label: 'Anime Planet ID' },
            e(SimpleInput, {
              value: this.state.animePlanetCode,
              onChange: this.handleAnimePlanetCodeChange
            })
          )
        ),
        e('div', { className: 'edit-studio-form-site-kitsu' },
          e(InputLabel, { label: 'Kitsu ID' },
            e(SimpleInput, {
              value: this.state.kitsuCode,
              onChange: this.handleKitsuCodeChange
            })
          )
        ),
        e('div', { className: 'edit-studio-form-site-mal' },
          e(InputLabel, { label: 'My Anime List ID' },
            e(SimpleInput, {
              value: this.state.malCode,
              onChange: this.handleMalCodeChange
            })
          )
        ),
        e('div', { className: 'edit-studio-form-about' },
          e(InputLabel, { label: 'Description' },
            e(MarkdownEditor, {
              initialText: (this.props.studio || {}).about,
              onSubmit: this.handleSubmit
            },
            e(SimpleButton, { onClick: this.props.onCancel }, 'Cancel')
            )
          )
        )
      )
    )
  }
}

export default EditStudioForm
