import React from 'react'
import './StudioDetails.css'

import RemoteImage from '../RemoteImage/RemoteImage.js'
import MarkdownContent from '../MarkdownContent/MarkdownContent.js'

// writeme
function StudioDetails (props) {
  const e = React.createElement

  return (
    e('div', { className: 'studio-details' },
      props.studio.logo ? (
        e(RemoteImage, {
          className: 'studio-details-logo',
          remotePath: props.studio.logo,
          alt: 'Logo'
        })
      ) : null,

      e('div', { className: 'studio-details-sites' },
        props.studio.anidbCode ? (
          e('a', {
            target: '_blank',
            href: `https://anidb.net/creator/${props.studio.anidbCode}`
          }, 'AniDB')
        ) : null,
        props.studio.anilistCode ? (
          e('a', {
            target: '_blank',
            href: `https://anilist.co/studio/${props.studio.anilistCode}`
          }, 'Anilist')
        ) : null,
        props.studio.animePlanetCode ? (
          e('a', {
            target: '_blank',
            href: `https://www.anime-planet.com/anime/studios/${props.studio.animePlanetCode}`
          }, 'Anime-Planet')
        ) : null,
        props.studio.kitsuCode ? (
          e('a', {
            target: '_blank',
            href: `https://kitsu.io/api/edge/producers/${props.studio.kitsuCode}`
          }, 'Kitsu')
        ) : null,
        props.studio.malCode ? (
          e('a', {
            target: '_blank',
            href: `https://myanimelist.net/anime/producer/${props.studio.malCode}`
          }, 'MyAnimeList')
        ) : null
      ),

      e('div', { className: 'studio-details-about' },
        e(MarkdownContent, { text: props.studio.about })
      )
    )
  )
}

export default StudioDetails
