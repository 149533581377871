import autolinkNames from './autolinkNames'
import parseMarkdown from './parseMarkdown'

// Extract "@username" names from markdown text.
function mentionedUsers (text) {
  const html = document.createElement('div')
  html.innerHTML = parseMarkdown(text)

  const names = autolinkNames(html)
  return Array.prototype.map.call(names, name => name.slice(1))
}

export default mentionedUsers
