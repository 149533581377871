import React from 'react'
import './UploadFileForm.css'

import SimpleButton from '../../components/SimpleButton/SimpleButton'

// writeme
class UploadFileForm extends React.Component {
  constructor (props) {
    super(props)

    this.state = { file: null }

    this.handleFileChange = this.handleFileChange.bind(this)
  }

  handleFileChange (event) {
    this.setState({ file: event.target.files[0] })
  }

  render () {
    return (
      <div className='upload-file-form'>
        <div className='upload-file-form-title'>
          {this.props.title}
        </div>
        <div className='upload-file-form-help-text'>
          {this.props.children}
        </div>
        <input
          className='upload-file-form-input'
          type='file'
          accept='application/json'
          onChange={this.handleFileChange}
        />
        <div className='upload-file-form-submit'>
          <SimpleButton
            theme='primary'
            disabled={!this.state.file}
            onClick={() => this.props.onSubmit(this.state.file)}
          >
            Upload
          </SimpleButton>
        </div>
      </div>
    )
  }
}

export default UploadFileForm
