import React from 'react'
import './ImagePicker.css'

import LocalImage from '../LocalImage/LocalImage.js'

// Allow an image file to be selected (and previewed) by a user.
class ImagePicker extends React.Component {
  constructor (props) {
    super(props)

    this.state = { image: null }

    this.handleChange = this.handleChange.bind(this)
  }

  handleChange (event) {
    const file = event.target.files[0]
    this.props.onChange(file)

    const loader = new FileReader()
    loader.onload = () => this.setState({ image: loader.result })
    loader.readAsDataURL(file)
  }

  render () {
    const e = React.createElement

    // If an "ideal" width/height is given, display it:
    let dimsHint = null
    if (this.props.width && this.props.height) {
      dimsHint = `Ideal size: ${this.props.width}×${this.props.height} pixels`
    } else if (this.props.width) {
      dimsHint = `Ideal width: ${this.props.width} pixels`
    } else if (this.props.height) {
      dimsHint = `Ideal height: ${this.props.height} pixels`
    }

    // If a maximum file-size is specified, we want to display it:
    let sizeHint = null
    if (this.props.fileSize) {
      sizeHint = `Maximum size: ${this.props.fileSize}`
    }

    return (
      e('div', { className: 'image-picker' },
        e(this.state.image ? 'img' : LocalImage, {
          className: 'image-picker-preview',
          width: this.props.width,
          height: this.props.height,
          src: this.state.image || 'no-thumbnail.png',
          alt: 'Preview'
        }),
        e('div', { className: 'image-picker-dims-hint' }, dimsHint),
        e('div', { className: 'image-picker-size-hint' }, sizeHint),
        e('input', {
          className: 'image-picker-input',
          type: 'file',
          accept: 'image/*',
          onChange: this.handleChange
        })
      )
    )
  }
}

export default ImagePicker
