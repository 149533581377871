import React from 'react'
import './AllTagsPage.css'

import SimpleButton from '../../components/SimpleButton/SimpleButton.js'
import PleaseWait from '../../components/PleaseWait/PleaseWait.js'
import TagForm from '../../components/TagForm/TagForm.js'

import TagApi from '../../api/Tag.js'

// Displays a list of all tags.
class AllTagsPage extends React.Component {
  constructor (props) {
    super(props)

    this.state = {
      showNewForm: false,
      tags: null
    }

    this.fetchData = this.fetchData.bind(this)
    this.handleNewFormToggle = this.handleNewFormToggle.bind(this)
    this.handleCreate = this.handleCreate.bind(this)
    this.handleDelete = this.handleDelete.bind(this)
  }

  componentDidMount () {
    this.fetchData()
  }

  fetchData () {
    TagApi.all().then(
      json => this.setState({ tags: json.items }),
      this.props.onError
    )
  }

  handleNewFormToggle () {
    this.setState({ showNewForm: !this.state.showNewForm })
  }

  handleCreate (tag) {
    this.setState({ showNewForm: false })
    this.fetchData()
  }

  handleDelete (tag) {
    if (!window.confirm(`Really delete "${tag.name}"?`)) { return }

    TagApi.destroy(this.props.token, tag.id).then(
      this.fetchData, this.props.onError
    )
  }

  render () {
    const e = React.createElement

    if (!this.state.tags) { return e(PleaseWait) }

    const items = this.state.tags.map(tag => (
      e(TagForm, {
        key: tag.id,
        tag: tag,
        token: this.props.token,
        onSubmit: this.fetchData,
        onError: this.props.onError
      },
      e(SimpleButton, {
        theme: 'delete',
        onClick: () => this.handleDelete(tag)
      }, 'Delete')
      )
    ))

    const newItem = this.state.showNewForm ? (
      e(TagForm, {
        token: this.props.token,
        onSubmit: this.handleCreate,
        onError: this.props.onError
      },
      e(SimpleButton, { onClick: this.handleNewFormToggle }, 'Cancel')
      )
    ) : (
      e(SimpleButton, { theme: 'create', onClick: this.handleNewFormToggle }, 'New')
    )

    return (
      e('div', { className: 'all-tags-page' },
        e('div', { className: 'all-tags-page-items' }, items),
        e('div', { className: 'all-tags-page-new-item' }, newItem)
      )
    )
  }
}

export default AllTagsPage
