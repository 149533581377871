import React from 'react'
import './MediaReviewList.css'

import SimpleButton from '../../components/SimpleButton/SimpleButton.js'
import MediaReviewSummary from '../../components/MediaReviewSummary/MediaReviewSummary.js'
import PleaseWait from '../../components/PleaseWait/PleaseWait.js'

import ReviewApi from '../../api/Review.js'

// writeme
class MediaReviewList extends React.Component {
  constructor (props) {
    super(props)

    this.state = { reviews: null }
  }

  componentDidMount () {
    ReviewApi.all({ media: this.props.id }).then(
      json => this.setState({ reviews: json.items }),
      this.props.onError
    )
  }

  render () {
    const e = React.createElement

    const reviews = this.state.reviews ? this.state.reviews.map(review => (
      e(MediaReviewSummary, {
        key: review.id,
        review: review,
        onPageChange: this.props.onPageChange
      })
    )) : (
      e(PleaseWait)
    )

    const createReview = this.props.token ? (
      e(SimpleButton, {
        theme: 'create',
        onClick: () => this.props.onPageChange('create-review', this.props.id)
      },
      'Create review'
      )
    ) : null

    return (
      e('div', { className: 'media-review-list' },
        e('div', { className: 'media-review-list-items' }, reviews),
        createReview
      )
    )
  }
}

export default MediaReviewList
