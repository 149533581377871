import React from 'react'
import './CharacterActorForm.css'

import InputLabel from '../InputLabel/InputLabel'
import SimpleButton from '../SimpleButton/SimpleButton'
import SimpleDropdown from '../SimpleDropdown/SimpleDropdown'
import SimpleInput from '../SimpleInput/SimpleInput'

// writeme
function CharacterActorForm (props) {
  if (props.role.destroy) { return null }

  return (
    <div className='character-actor-form'>
      <div className='character-actor-form-actor'>
        <InputLabel label='Voice Actor'>
          <SimpleInput value={props.role.staff.name} disabled={true} />
        </InputLabel>
      </div>
      <div className='character-actor-form-language'>
        <InputLabel label='Language'>
          <SimpleDropdown
            value={props.role.language}
            options={['en', 'ja']}
            onChange={props.onChange}
          />
        </InputLabel>
      </div>
      <div className='character-actor-form-remove'>
        <SimpleButton theme='delete' onClick={props.onRemove}>
          Remove
        </SimpleButton>
      </div>
    </div>
  )
}

export default CharacterActorForm
