import React from 'react'

import CharacterSearch from '../../components/CharacterSearch/CharacterSearch'

// writeme
function AllCharactersPage (props) {
  return (
    <CharacterSearch
      onClick={character => props.onPageChange('characters', character.id)}
      onError={props.onError}
    />
  )
}

export default AllCharactersPage
