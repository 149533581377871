// Combine two arrays of records-with-IDs, removing any duplicates:
function safeConcat (oldData, newData) {
  const oldItems = oldData || [] // May be `null` if we have no "old" data yet.
  const allItems = oldItems.concat(newData)

  // The new data may contain items from the "old" list (if any new records are
  // inserted "earlier" between updates), so filter out any duplicate items:
  const ids = allItems.map(record => record.id)
  return allItems.filter((record, idx) => ids.indexOf(record.id) === idx)
}

export default safeConcat
