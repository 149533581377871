import React from 'react'
import './StatusPost.css'

import Avatar from '../Avatar/Avatar'
import LikeList from '../LikeList/LikeList'
import MarkdownContent from '../MarkdownContent/MarkdownContent'

import canEdit from '../../helpers/canEdit'

// writeme
class StatusPost extends React.Component {
  constructor (props) {
    super(props)

    this.state = {
      hidden: this.props.post.adult || this.props.post.spoiler
    }

    this.handleReveal = this.handleReveal.bind(this)
  }

  handleReveal () {
    this.setState({ hidden: false })
  }

  render () {
    const e = React.createElement

    const viewUser = () => this.props.onPageChange('users', this.props.post.user.name)

    const options = {
      weekday: 'long',
      year: 'numeric',
      month: 'numeric',
      day: 'numeric',
      hour: 'numeric',
      minute: 'numeric'
    }

    const createdAt = new Date(this.props.post.createdAt)
    const updatedAt = new Date(this.props.post.updatedAt)
    const formatter = new Intl.DateTimeFormat('default', options)

    // Glitch: Editing a post from "spoiler" to "not spoiler" still hides it:
    const hidden = this.state.hidden && (this.props.post.adult || this.props.post.spoiler)

    const content = hidden ? (
      e('button', { className: 'reveal-spoiler', onClick: this.handleReveal },
        'Contains ',
        this.props.post.adult ? 'adult ' : null,
        this.props.post.spoiler ? 'spoiler ' : null,
        'content; click to reveal...'
      )
    ) : (
      e(MarkdownContent, { text: this.props.post.content })
    )

    const likeList = Array.isArray(this.props.post.likes) ? (
      e(LikeList, { likes: this.props.post.likes })
    ) : null

    const likeCount = Array.isArray(this.props.post.likes) ? (
      this.props.post.likes.length
    ) : (
      this.props.post.likeCount
    )

    const replyCount = Array.isArray(this.props.post.replies) ? (
      this.props.post.replies.length
    ) : (
      this.props.post.replyCount
    )

    let actions = this.props.currentUser ? (
      e('div', { className: 'status-post-actions' },
        e('button', {
          className: 'status-post-report',
          onClick: () => this.props.onPageChange('create-user-report', this.props.post.id)
        }, 'Report')
      )
    ) : null

    if (canEdit(this.props.currentUser, this.props.post)) {
      actions = (
        e('div', { className: 'status-post-actions' },
          e('button', {
            className: 'status-post-edit',
            onClick: this.props.onEdit
          }, 'Edit'),
          e('button', {
            className: 'status-post-delete',
            onClick: this.props.onDestroy
          }, 'Delete')
        )
      )
    }

    return (
      e('div', { className: 'status-post' },
        e('div', { className: 'status-post-user-icon' },
          e(Avatar, { user: this.props.post.user })
        ),
        e('button', { className: 'status-post-user-name', onClick: viewUser },
          this.props.post.user.name
        ),
        e('div', { className: 'status-post-posted-at' },
          formatter.format(createdAt)
        ),
        e('div', { className: 'status-post-edited-at' },
          createdAt.getTime() !== updatedAt.getTime() ? (
            `Edited ${formatter.format(updatedAt)}`
          ) : null
        ),
        e('div', { className: 'status-post-content' },
          content
        ),
        e('div', { className: 'status-post-like-users' },
          likeList
        ),
        actions,
        this.props.showReplyCount ? (
          e('button', {
            className: 'status-post-reply-count',
            onClick: () => this.props.onPageChange('status-posts', this.props.post.id)
          }, `💬 ${replyCount}`)
        ) : null,
        e('button', {
          className: `status-post-like-count ${this.props.post.liked ? 'you-like' : ''}`,
          disabled: !this.props.currentUser,
          onClick: this.props.onLikeToggle
        }, `❤ ${likeCount}`)
      )
    )
  }
}

export default StatusPost
