import React from 'react'
import './MediaDetails.css'

import MarkdownContent from '../../components/MarkdownContent/MarkdownContent.js'
import MediaTag from '../../components/MediaTag/MediaTag.js'

// writeme
function MediaDetails (props) {
  const counter = props.media.kind === 'manga' ? 'chapter' : 'episode'

  const options = { year: 'numeric', month: 'short', day: 'numeric' }
  const startAt = props.media.startDate && new Date(props.media.startDate)
  const endAt = props.media.endDate && new Date(props.media.endDate)
  const i18n = new Intl.DateTimeFormat('default', options)

  const tags = props.media.mediaTags.map(mediaTag => (
    <MediaTag key={mediaTag.id} mediaTag={mediaTag} />
  ))

  return (
    <div className='media-details'>
      <div className='media-details-kind'>
        <div className='media-details-label'>Type</div>
        <div className='media-details-value'>{props.media.kind}</div>
      </div>
      <div className='media-details-subtype'>
        <div className='media-details-label'>Format</div>
        <div className='media-details-value'>{props.media.subtype}</div>
      </div>

      <div className='media-details-country'>
        <div className='media-details-label'>Country</div>
        <div className='media-details-value'>{props.media.country}</div>
      </div>
      <div className='media-details-status'>
        <div className='media-details-label'>Status</div>
        <div className='media-details-value'>{props.media.status}</div>
      </div>

      <div className='media-details-start'>
        <div className='media-details-label'>Start</div>
        <div className='media-details-value'>{startAt && i18n.format(startAt)}</div>
      </div>
      <div className='media-details-end'>
        <div className='media-details-label'>Finish</div>
        <div className='media-details-value'>{endAt && i18n.format(endAt)}</div>
      </div>

      {props.media.studio ? (
        <div className='media-details-studio'>
          <div className='media-details-label'>Studio</div>
          <div className='media-details-value'>{props.media.studio.name}</div>
        </div>
      ) : null}

      {props.media.episodes ? (
        <div className='media-details-episodes'>
          <div className='media-details-count'>{props.media.episodes}</div>
          <div className='media-details-units'>
            {props.media.episodes === 1 ? counter : `${counter}s`}
          </div>
        </div>
      ) : null}

      {props.media.volumes ? (
        <div className='media-details-volumes'>
          <div className='media-details-count'>{props.media.volumes}</div>
          <div className='media-details-units'>
            {props.media.volumes === 1 ? 'volume' : 'volumes'}
          </div>
        </div>
      ) : null}

      <div className='media-details-description'>
        <MarkdownContent text={props.media.about} />
      </div>
      <div className='media-details-tags'>{tags}</div>
    </div>
  )
}

export default MediaDetails
