import React from 'react'
import './NotFoundPage.css'

// Displayed whenever we click on a link that does not exist.
function NotFoundPage (props) {
  const e = React.createElement

  return (
    e('div', { className: 'not-found-page' }, 'Page not found.')
  )
}

export default NotFoundPage
