import React from 'react'
import './LogInPage.css'

// Components:
import SimpleButton from '../../components/SimpleButton/SimpleButton.js'
import InputLabel from '../../components/InputLabel/InputLabel.js'
import SimpleInput from '../../components/SimpleInput/SimpleInput.js'

// APIs:
import AccountApi from '../../api/Account.js'

// writeme
class LogInPage extends React.Component {
  constructor (props) {
    super(props)

    this.state = {
      email: '',
      password: ''
    }

    this.handleEmailChange = this.handleEmailChange.bind(this)
    this.handlePasswordChange = this.handlePasswordChange.bind(this)
    this.handleSubmit = this.handleSubmit.bind(this)
  }

  handleEmailChange (event) {
    this.setState({ email: event.target.value })
  }

  handlePasswordChange (event) {
    this.setState({ password: event.target.value })
  }

  handleSubmit (event) {
    event.preventDefault()

    AccountApi.logIn(this.state.email, this.state.password).then(
      json => {
        this.props.onLogIn(json)
        this.props.onPageChange() // Don't stay on the log-in screen!
      },
      this.props.onError
    )
  }

  render () {
    const e = React.createElement

    return (
      e('form', { className: 'log-in', onSubmit: this.handleSubmit },
        e('div', { className: 'log-in-header' },
          'Log in'
        ),
        e('div', { className: 'log-in-email' },
          e(InputLabel, { label: 'Email' },
            e(SimpleInput, {
              type: 'email',
              value: this.state.email,
              onChange: this.handleEmailChange,
              autoFocus: true
            })
          )
        ),
        e('div', { className: 'log-in-password' },
          e(InputLabel, { label: 'Password' },
            e(SimpleInput, {
              type: 'password',
              value: this.state.password,
              onChange: this.handlePasswordChange
            })
          )
        ),
        e('div', { className: 'log-in-footer' },
          e(SimpleButton, { theme: 'primary' }, 'Log in')
        )
      )
    )
  }
}

export default LogInPage
