import React from 'react'

import EditMediaForm from '../../components/EditMediaForm/EditMediaForm'

import MediaApi from '../../api/Media'

// writeme
class CreateMediaPage extends React.Component {
  constructor (props) {
    super(props)

    this.handleSubmit = this.handleSubmit.bind(this)
  }

  handleSubmit (params) {
    MediaApi.create(this.props.token, params).then(
      json => this.props.onPageChange('media', json.id),
      this.props.onError
    )
  }

  render () {
    return (
      <EditMediaForm
        onSubmit={this.handleSubmit}
        onCancel={() => this.props.onPageChange('media')}
      />
    )
  }
}

export default CreateMediaPage
