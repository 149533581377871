import React from 'react'
import './StudioPage.css'

import SimpleButton from '../../components/SimpleButton/SimpleButton.js'
import MediaSearch from '../../components/MediaSearch/MediaSearch.js'
import PleaseWait from '../../components/PleaseWait/PleaseWait.js'
import StudioDetails from '../../components/StudioDetails/StudioDetails.js'
import TabGroup from '../../components/TabGroup/TabGroup.js'

import canEdit from '../../helpers/canEdit.js'

import StudioApi from '../../api/Studio.js'

// Displays a studio.
class StudioPage extends React.Component {
  constructor (props) {
    super(props)

    this.state = { studio: null }

    this.handleDelete = this.handleDelete.bind(this)
  }

  componentDidMount () {
    StudioApi.show(this.props.id).then(
      json => this.setState({ studio: json }),
      this.props.onError
    )
  }

  handleDelete () {
    const message = 'Are you sure you want to delete this studio?'
    if (!window.confirm(message)) { return }

    StudioApi.destroy(this.props.token, this.props.id).then(
      () => this.props.onPageChange('studios'),
      this.props.onError
    )
  }

  render () {
    const e = React.createElement

    if (!this.state.studio) { return e(PleaseWait) }

    const goTo = this.props.onPageChange

    const actions = canEdit(this.props.currentUser, this.state.studio) ? (
      e('div', { className: 'studio-page-actions' },
        e(SimpleButton, {
          theme: 'primary',
          onClick: () => goTo('edit-studio', this.props.id)
        }, 'Edit'),
        e(SimpleButton, {
          theme: 'delete',
          onClick: this.handleDelete
        }, 'Delete')
      )
    ) : null

    return (
      e('div', { className: 'studio-page' },
        actions,
        e('div', { className: 'studio-page-name' },
          this.state.studio.name
        ),
        e('div', { className: 'studio-page-content' },
          e(TabGroup, { labels: ['General', 'Media'] },
            e(StudioDetails, {
              studio: this.state.studio
            }),
            e(MediaSearch, {
              studioId: this.props.id,
              onError: this.props.onError,
              onClick: media => this.props.onPageChange('media', media.id)
            })
          )
        )
      )
    )
  }
}

export default StudioPage
