import React from 'react'
import './StatusPostPage.css'

import PleaseWait from '../../components/PleaseWait/PleaseWait.js'
import StatusPostListItem from '../../components/StatusPostListItem/StatusPostListItem.js'
import StatusPostEditor from '../../components/StatusPostEditor/StatusPostEditor.js'

import StatusPostApi from '../../api/StatusPost.js'

// Displays a single "status post" thread.
class StatusPostPage extends React.Component {
  constructor (props) {
    super(props)

    this.state = {
      thread: null
    }

    this.fetchData = this.fetchData.bind(this)
    this.handleUpdate = this.handleUpdate.bind(this)
    this.handleAddReply = this.handleAddReply.bind(this)
  }

  componentDidMount () {
    this.fetchData()
  }

  fetchData () {
    StatusPostApi.show(this.props.token, this.props.id).then(
      this.handleUpdate,
      this.props.onError
    )
  }

  handleUpdate (json) {
    this.setState({ thread: json })
  }

  handleAddReply (params) {
    const paramsWithParent = { ...params, parentId: this.props.id }

    StatusPostApi.create(this.props.token, paramsWithParent).then(
      () => {
        this.setState({ postSubmittedAt: Date() }) // Force-reset the editor.
        this.fetchData() // We COULD just append this new post, but...
      },
      this.props.onError
    )
  }

  render () {
    const e = React.createElement

    if (!this.state.thread) { return e(PleaseWait) }

    const replies = this.state.thread.replies.map(reply => (
      e(StatusPostListItem, {
        key: reply.id,
        post: reply,
        token: this.props.token,
        currentUser: this.props.currentUser,
        onError: this.props.onError,
        onPageChange: this.props.onPageChange,
        onUpdate: this.fetchData,
        onDestroy: this.fetchData
      })
    ))

    const newReplyForm = this.props.currentUser ? (
      e(StatusPostEditor, {
        key: this.state.postSubmittedAt,
        onSubmit: this.handleAddReply
      })
    ) : null

    return (
      e('div', { className: 'status-post-page' },
        e('div', { className: 'status-post-page-thread' },
          e(StatusPostListItem, {
            post: this.state.thread,
            token: this.props.token,
            currentUser: this.props.currentUser,
            onError: this.props.onError,
            onPageChange: this.props.onPageChange,
            onUpdate: this.handleUpdate,
            onDestroy: () => this.props.onPageChange()
          })
        ),
        e('div', { className: 'status-post-page-replies' }, replies),
        e('div', { className: 'status-post-page-add-reply' }, newReplyForm)
      )
    )
  }
}

export default StatusPostPage
