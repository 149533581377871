import React from 'react'
import './AllReviewsPage.css'

import PleaseWait from '../../components/PleaseWait/PleaseWait.js'
import ReviewSummary from '../../components/ReviewSummary/ReviewSummary.js'

import ReviewApi from '../../api/Review.js'

// Displays a list of all reviews.
class AllReviewsPage extends React.Component {
  constructor (props) {
    super(props)

    this.state = { reviews: null }
  }

  componentDidMount () {
    ReviewApi.all().then(
      json => this.setState({ reviews: json.items }),
      this.props.onError
    )
  }

  render () {
    const e = React.createElement

    if (!this.state.reviews) { return e(PleaseWait) }

    const items = this.state.reviews.map(review => (
      e(ReviewSummary, {
        key: review.id,
        review: review,
        onPageChange: this.props.onPageChange,
        onError: this.props.onError
      })
    ))

    return e('div', { className: 'all-reviews-page' }, items)
  }
}

export default AllReviewsPage
