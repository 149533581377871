import React from 'react'
import './CharacterActor.css'

// writeme
function CharacterActor (props) {
  return (
    <button className='character-actor' onClick={props.onClick}>
      <div className='character-actor-name'>
        {props.role.staff.name}
      </div>
      <div className='character-actor-language'>
        {props.role.language}
      </div>
    </button>
  )
}

export default CharacterActor
