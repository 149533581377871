import React from 'react'
import './ErrorMessage.css'

function ErrorMessage (props) {
  const e = React.createElement

  if (!props.message) { return null }

  return (
    e('div', { className: 'error-message' },
      e('div', { className: 'text' }, props.message),
      e('button', { className: 'hide', onClick: props.onClose }, 'Close')
    )
  )
}

export default ErrorMessage
