import React from 'react'

import EditStaffForm from '../../components/EditStaffForm/EditStaffForm'
import PleaseWait from '../../components/PleaseWait/PleaseWait'

import StaffApi from '../../api/Staff'

// writeme
class EditStaffPage extends React.Component {
  constructor (props) {
    super(props)

    this.state = {
      staff: null
    }

    this.handleSubmit = this.handleSubmit.bind(this)
  }

  componentDidMount () {
    StaffApi.show(this.props.id).then(
      json => this.setState({ staff: json }),
      this.props.onError
    )
  }

  handleSubmit (params) {
    StaffApi.update(this.props.token, this.props.id, params).then(
      () => this.props.onPageChange('staff', this.props.id),
      this.props.onError
    )
  }

  render () {
    if (!this.state.staff) { return <PleaseWait /> }

    return (
      <EditStaffForm
        staff={this.state.staff}
        onSubmit={this.handleSubmit}
        onCancel={() => this.props.onPageChange('staff', this.props.id)}
      />
    )
  }
}

export default EditStaffPage
