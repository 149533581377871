import apiCall from './apiCall.js'
import buildGetQuery from './buildGetQuery.js'

// Handles all status post requests.
class StatusPost {
  static all (token, params) {
    const data = buildGetQuery(params)
    return apiCall('GET', 'api/v1/status_posts', token, data)
  }

  static show (token, id) {
    return apiCall('GET', `api/v1/status_posts/${id}`, token)
  }

  static create (token, params) {
    const data = { statusPost: params }
    return apiCall('POST', 'api/v1/status_posts', token, data)
  }

  static update (token, id, params) {
    const data = { statusPost: params }
    return apiCall('PATCH', `api/v1/status_posts/${id}`, token, data)
  }

  static destroy (token, id) {
    return apiCall('DELETE', `api/v1/status_posts/${id}`, token)
  }

  static like (token, id) {
    return apiCall('POST', `api/v1/status_posts/${id}/like`, token)
  }

  static unlike (token, id) {
    return apiCall('POST', `api/v1/status_posts/${id}/unlike`, token)
  }
}

export default StatusPost
