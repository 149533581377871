import React from 'react'
import './UserAdminPage.css'

import SimpleButton from '../../components/SimpleButton/SimpleButton.js'
import InputLabel from '../../components/InputLabel/InputLabel.js'
import MarkdownEditor from '../../components/MarkdownEditor/MarkdownEditor.js'
import PleaseWait from '../../components/PleaseWait/PleaseWait.js'
import SimpleCheckbox from '../../components/SimpleCheckbox/SimpleCheckbox.js'
import SimpleInput from '../../components/SimpleInput/SimpleInput.js'

import UserApi from '../../api/User.js'

// writeme
class UserAdminPage extends React.Component {
  constructor (props) {
    super(props)

    this.state = { loading: true }

    this.handleNameChange = this.handleNameChange.bind(this)
    this.handleAdministratorToggle = this.handleAdministratorToggle.bind(this)
    this.handleSubmit = this.handleSubmit.bind(this)
    this.handleDelete = this.handleDelete.bind(this)
    this.handleClearAvatar = this.handleClearAvatar.bind(this)
  }

  componentDidMount () {
    UserApi.findByName(this.props.token, this.props.id).then(
      json => this.setState({
        id: json.id,
        name: json.name,
        about: json.about,
        administrator: json.administrator,
        loading: false
      }),
      this.props.onError
    )
  }

  handleNameChange (event) {
    this.setState({ name: event.target.value })
  }

  handleAdministratorToggle () {
    this.setState({ administrator: !this.state.administrator })
  }

  handleSubmit (text) {
    const params = { ...this.state, about: text }

    UserApi.update(this.props.token, this.state.id, params).then(
      () => this.props.onPageChange('users', this.props.id),
      this.props.onError
    )
  }

  handleDelete () {
    if (!window.confirm('Really delete this account?')) { return }

    UserApi.destroy(this.props.token, this.state.id).then(
      () => this.props.onPageChange(), // Go back to the main page.
      this.props.onError
    )
  }

  handleClearAvatar () {
    if (!window.confirm('Remove the avatar image from this user?')) { return }

    UserApi.update(this.props.token, this.state.id, { avatar: null }).then(
      () => alert('Avatar removed.'),
      this.props.onError
    )
  }

  render () {
    const e = React.createElement

    if (this.state.loading) { return e(PleaseWait) }

    return (
      e('div', { className: 'user-admin-page' },
        e('div', { className: 'user-admin-page-title' }, 'Moderator Tools'),

        e('div', { className: 'user-admin-page-actions' },
          e(SimpleButton, {
            theme: 'create',
            onClick: this.handleClearAvatar
          }, 'Clear Avatar'),
          e(SimpleButton, {
            theme: 'delete',
            onClick: this.handleDelete
          }, 'Delete Account')
        ),
        e('div', { className: 'user-admin-page-name' },
          e(InputLabel, { label: 'Name' },
            e(SimpleInput, {
              autoFocus: true,
              value: this.state.name,
              onChange: this.handleNameChange
            })
          )
        ),
        e('div', { className: 'user-admin-page-about' },
          e(InputLabel, { label: 'About' },
            e(MarkdownEditor, {
              initialText: this.state.about,
              onSubmit: this.handleSubmit
            },
            e('div', { className: 'user-admin-page-editor-extras' },
              e(SimpleCheckbox, {
                label: 'Administrator',
                checked: this.state.administrator,
                onChange: this.handleAdministratorToggle
              }),
              e(SimpleButton, {
                onClick: () => this.props.onPageChange('users', this.props.id)
              }, 'Cancel')
            )
            )
          )
        )
      )
    )
  }
}

export default UserAdminPage
