import React from 'react'

import EditReviewForm from '../../components/EditReviewForm/EditReviewForm.js'
import PleaseWait from '../../components/PleaseWait/PleaseWait.js'

import ReviewApi from '../../api/Review.js'

// writeme
class EditReviewPage extends React.Component {
  constructor (props) {
    super(props)

    this.state = {
      review: null
    }

    this.handleSubmit = this.handleSubmit.bind(this)
  }

  componentDidMount () {
    ReviewApi.show(this.props.id).then(
      json => this.setState({ review: json }),
      this.props.onError
    )
  }

  handleSubmit (params) {
    ReviewApi.update(this.props.token, this.props.id, params).then(
      () => this.props.onPageChange('reviews', this.props.id),
      this.props.onError
    )
  }

  render () {
    const e = React.createElement

    if (!this.state.review) { return e(PleaseWait) }

    return e('div', { className: 'edit-review-page' },
      e(EditReviewForm, {
        review: this.state.review,
        onSubmit: this.handleSubmit,
        onCancel: () => this.props.onPageChange('reviews', this.props.id)
      })
    )
  }
}

export default EditReviewPage
