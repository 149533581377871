import React from 'react'
import './StaffCard.css'

import RemoteImage from '../RemoteImage/RemoteImage.js'

// writeme
function StaffCard (props) {
  return (
    <button className='staff-card' onClick={props.onClick}>
      <div className='staff-card-name'>{props.staff.name}</div>
      <div className='staff-card-image-container'>
        <RemoteImage
          className='staff-card-image'
          remotePath={props.staff.picture}
          fallback='no-thumbnail.png'
          alt='Picture'
        />
      </div>
      <div className='staff-card-info'>N/A</div>
    </button>
  )
}

export default StaffCard
