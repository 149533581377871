import React from 'react'
import './MediaPage.css'

import AssociatedMedia from '../../components/AssociatedMedia/AssociatedMedia'
import CharacterList from '../../components/CharacterList/CharacterList'
import MediaDetails from '../../components/MediaDetails/MediaDetails'
import MediaReviewList from '../../components/MediaReviewList/MediaReviewList'
import PleaseWait from '../../components/PleaseWait/PleaseWait'
import RemoteImage from '../../components/RemoteImage/RemoteImage'
import SimpleButton from '../../components/SimpleButton/SimpleButton'
import TabGroup from '../../components/TabGroup/TabGroup'
import TrackingSiteLink from '../../components/TrackingSiteLink/TrackingSiteLink'
import UserMediaStatus from '../../components/UserMediaStatus/UserMediaStatus'

import canEdit from '../../helpers/canEdit'
import localName from '../../helpers/localName'

import MediaApi from '../../api/Media'

// Displays a media item.
class MediaPage extends React.Component {
  constructor (props) {
    super(props)

    this.state = { media: null }

    this.handleDelete = this.handleDelete.bind(this)
  }

  componentDidMount () {
    MediaApi.show(this.props.id).then(
      json => this.setState({ media: json }),
      this.props.onError
    )
  }

  handleDelete () {
    const message = 'Are you sure you want to delete this from the database?'
    if (!window.confirm(message)) { return }

    MediaApi.destroy(this.props.token, this.props.id).then(
      () => this.props.onPageChange('media'),
      this.props.onError
    )
  }

  render () {
    const e = React.createElement

    if (!this.state.media) { return e(PleaseWait) }

    const crudActions = canEdit(this.props.currentUser, this.state.media) ? (
      e('div', { className: 'media-page-actions' },
        e(SimpleButton, {
          onClick: () => this.props.onPageChange('edit-media-tags', this.props.id)
        }, 'Tags'),
        e(SimpleButton, {
          theme: 'primary',
          onClick: () => this.props.onPageChange('edit-media', this.props.id)
        }, 'Edit'),
        e(SimpleButton, {
          theme: 'delete',
          onClick: this.handleDelete
        }, 'Delete')
      )
    ) : null

    const listOptions = this.props.currentUser ? (
      e('div', { className: 'media-page-list-actions' },
        e(UserMediaStatus, {
          token: this.props.token,
          userId: this.props.currentUser.id,
          mediaId: this.props.id,
          onError: this.props.onError
        })
      )
    ) : null

    const names = this.state.media.names.map(name => (
      e('div', { key: name.id, className: 'media-page-name-item' },
        e('div', { className: 'media-page-name-item-text' }, name.text),
        e('div', { className: 'media-page-name-item-language' }, name.language)
      )
    ))

    return (
      e('div', { className: 'media-page' },
        e(RemoteImage, {
          className: 'media-page-image',
          remotePath: this.state.media.picture,
          fallback: 'no-thumbnail.png',
          alt: 'Thumbnail'
        }),
        e('div', { className: 'media-page-main-name' },
          `${localName(this.state.media)} ${this.state.media.adult ? '🔞' : ''}`
        ),
        crudActions,
        e('div', { className: 'media-page-name-list' },
          names
        ),
        e('div', { className: 'media-page-tracking-site-list' },
          e(TrackingSiteLink, { site: 'anidb', media: this.state.media }),
          e(TrackingSiteLink, { site: 'anilist', media: this.state.media }),
          e(TrackingSiteLink, { site: 'animePlanet', media: this.state.media }),
          e(TrackingSiteLink, { site: 'kitsu', media: this.state.media }),
          e(TrackingSiteLink, { site: 'mal', media: this.state.media })
        ),
        e('div', { className: 'media-page-content' },
          e(TabGroup, { labels: ['General', 'Relations', 'Characters', 'Reviews'] },
            e('div', { className: 'media-page-tab-general' },
              e(MediaDetails, { media: this.state.media })
            ),
            e('div', { className: 'media-page-tab-relations' },
              e(AssociatedMedia, {
                token: this.props.token,
                currentUser: this.props.currentUser,
                mediaId: this.state.media.id,
                onError: this.props.onError,
                onPageChange: this.props.onPageChange
              })
            ),
            e('div', { className: 'media-page-tab-characters' },
              e(CharacterList, {
                token: this.props.token,
                currentUser: this.props.currentUser,
                mediaId: this.state.media.id,
                onError: this.props.onError,
                onPageChange: this.props.onPageChange
              })
            ),
            e('div', { className: 'media-page-tab-reviews' },
              e(MediaReviewList, {
                id: this.props.id,
                token: this.props.token,
                onError: this.props.onError,
                onPageChange: this.props.onPageChange
              })
            )
          )
        ),
        listOptions
      )
    )
  }
}

export default MediaPage
