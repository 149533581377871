import React from 'react'

import StaffSearch from '../../components/StaffSearch/StaffSearch'

// writeme
function AllStaffPage (props) {
  return (
    <StaffSearch
      onClick={staff => props.onPageChange('staff', staff.id)}
      onError={props.onError}
    />
  )
}

export default AllStaffPage
