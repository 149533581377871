import React from 'react'
import './CharacterPage.css'

import CharacterRole from '../../components/CharacterRole/CharacterRole'
import CharacterActor from '../../components/CharacterActor/CharacterActor'
import LinkVoiceActorForm from '../../components/LinkVoiceActorForm/LinkVoiceActorForm'
import MarkdownContent from '../../components/MarkdownContent/MarkdownContent'
import PleaseWait from '../../components/PleaseWait/PleaseWait'
import RemoteImage from '../../components/RemoteImage/RemoteImage'
import SimpleButton from '../../components/SimpleButton/SimpleButton'

import canEdit from '../../helpers/canEdit'

import CharacterApi from '../../api/Character'

// Displays a character.
class CharacterPage extends React.Component {
  constructor (props) {
    super(props)

    this.state = {
      character: null,
      staffSearch: false
    }

    this.handleBrowseVoiceActors = this.handleBrowseVoiceActors.bind(this)
    this.handleCancelVoiceActors = this.handleCancelVoiceActors.bind(this)
    this.handleUpdateVoiceActors = this.handleUpdateVoiceActors.bind(this)
    this.handleDelete = this.handleDelete.bind(this)
  }

  componentDidMount () {
    CharacterApi.show(this.props.id).then(
      json => this.setState({ character: json }),
      this.props.onError
    )
  }

  handleBrowseVoiceActors () {
    this.setState({ staffSearch: true })
  }

  handleCancelVoiceActors () {
    this.setState({ staffSearch: false })
  }

  handleUpdateVoiceActors (character) {
    this.setState({ staffSearch: false, character: character })
  }

  handleDelete () {
    const message = 'Are you sure you want to delete this character?'
    if (!window.confirm(message)) { return }

    CharacterApi.destroy(this.props.token, this.props.id).then(
      () => this.props.onPageChange('characters'),
      this.props.onError
    )
  }

  render () {
    if (!this.state.character) { return <PleaseWait /> }

    const goTo = this.props.onPageChange

    const actions = canEdit(this.props.currentUser, this.state.character) ? (
      <div className='character-page-actions'>
        <SimpleButton onClick={this.handleBrowseVoiceActors} disabled={this.state.staffSearch}>
          Add voice actor
        </SimpleButton>
        <SimpleButton theme='primary' onClick={() => goTo('edit-character', this.props.id)}>
          Edit
        </SimpleButton>
        <SimpleButton theme='delete' onClick={this.handleDelete}>
          Delete
        </SimpleButton>
      </div>
    ) : null

    const roles = this.state.character.associatedCharacters.map(role => (
      <CharacterRole
        key={role.id}
        role={role}
        onClick={() => goTo('media', role.media.id)}
      />
    ))

    const actors = this.state.character.voiceActingRoles.map(role => (
      <CharacterActor
        key={role.id}
        role={role}
        onClick={() => goTo('staff', role.staff.id)}
      />
    ))

    const content = this.state.staffSearch ? (
      <LinkVoiceActorForm
        characterId={this.props.id}
        token={this.props.token}
        onError={this.props.onError}
        onCancel={this.handleCancelVoiceActors}
        onSuccess={this.handleUpdateVoiceActors}
      />
    ) : (
      <MarkdownContent text={this.state.character.about} />
    )

    return (
      <div className='character-page'>
        {actions}
        <RemoteImage
          className='character-page-image'
          remotePath={this.state.character.picture}
          fallback='no-thumbnail.png'
          alt='Thumbnail'
        />
        <div className='character-page-roles'>{roles}</div>
        <div className='character-page-actors'>{actors}</div>
        <div className='character-page-name'>{this.state.character.name}</div>
        <div className='character-page-content'>{content}</div>
      </div>
    )
  }
}

export default CharacterPage
