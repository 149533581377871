import React from 'react'
import './AddExistingCharacter.css'

import CharacterSearch from '../CharacterSearch/CharacterSearch'
import InputLabel from '../InputLabel/InputLabel'
import SimpleButton from '../SimpleButton/SimpleButton'
import SimpleDropdown from '../SimpleDropdown/SimpleDropdown'

import { associatedCharacterKinds } from '../../helpers/commonEnums'

import CharacterApi from '../../api/Character'

// writeme
class AddExistingCharacter extends React.Component {
  constructor (props) {
    super(props)

    this.state = { role: associatedCharacterKinds[0] }

    this.handleRoleChange = this.handleRoleChange.bind(this)
    this.handleSubmit = this.handleSubmit.bind(this)
  }

  handleRoleChange (value) {
    this.setState({ role: value })
  }

  handleSubmit (character) {
    const params = {
      associatedCharactersAttributes: [
        { mediaId: this.props.mediaId, kind: this.state.role }
      ]
    }

    CharacterApi.update(this.props.token, character.id, params).then(
      this.props.onComplete,
      this.props.onError
    )
  }

  render () {
    return (
      <CharacterSearch
        onError={this.props.onError}
        onClick={this.handleSubmit}
      >
        <div className='add-existing-character-actions'>
          <InputLabel label='Role'>
            <SimpleDropdown
              value={this.state.role}
              options={associatedCharacterKinds}
              onChange={this.handleRoleChange}
            />
          </InputLabel>
          <SimpleButton onClick={this.props.onComplete}>Cancel</SimpleButton>
        </div>

      </CharacterSearch>
    )
  }
}

export default AddExistingCharacter
