import React from 'react'
import './MediaCard.css'

import LocalImage from '../LocalImage/LocalImage'
import RemoteImage from '../RemoteImage/RemoteImage'

import localName from '../../helpers/localName'

// Used on the browse page.
function MediaCard (props) {
  const score = props.media.score === null ? 'No score' : `Score: ${props.media.score}%`

  const image = props.media.adult ? (
    <LocalImage
      className='media-card-image'
      src='adult-content.png'
      alt='Thumbnail'
    />
  ) : (
    <RemoteImage
      className='media-card-image'
      remotePath={props.media.picture}
      fallback='no-thumbnail.png'
      alt='Thumbnail'
    />
  )

  const details = props.association ? (
    <React.Fragment>
      <div className='media-card-assocation'>{props.association}</div>
      <div className='media-card-kind'>{props.media.kind}</div>
    </React.Fragment>
  ) : (
    <React.Fragment>
      <div className='media-card-country'>{props.media.country}</div>
      <div className='media-card-subtype'>{props.media.subtype}</div>
      <div className='media-card-score'>{score}</div>
    </React.Fragment>
  )

  return (
    <div className='media-card'>
      <button className='media-card-title' onClick={props.onClick}>
        {localName(props.media)}
      </button>
      <div className='media-card-image-container'>
        {image}
      </div>
      <div className='media-card-details'>
        {details}
      </div>
    </div>
  )
}

export default MediaCard
