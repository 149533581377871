import React from 'react'
import './AllUserReportsPage.css'

import PleaseWait from '../../components/PleaseWait/PleaseWait.js'
import UserReportSummary from '../../components/UserReportSummary/UserReportSummary.js'

import UserReportApi from '../../api/UserReport.js'

// writeme
class AllUserReportsPage extends React.Component {
  constructor (props) {
    super(props)

    this.state = { reports: null }

    this.fetchData = this.fetchData.bind(this)
    this.handleDismiss = this.handleDismiss.bind(this)
  }

  componentDidMount () {
    this.fetchData()
  }

  fetchData () {
    UserReportApi.all(this.props.token).then(
      json => this.setState({ reports: json.items }),
      this.props.onError
    )
  }

  handleDismiss (report) {
    if (!window.confirm('Dismiss this report?')) { return }

    UserReportApi.destroy(this.props.token, report.id).then(
      this.fetchData, this.props.onError
    )
  }

  render () {
    const e = React.createElement

    if (!this.state.reports) { return e(PleaseWait) }

    const userReports = this.state.reports.map(report => (
      e(UserReportSummary, {
        key: report.id,
        report: report,
        onPageChange: this.props.onPageChange,
        onDismiss: () => this.handleDismiss(report)
      })
    ))

    return e('div', { className: 'all-user-reports-page' }, userReports)
  }
}

export default AllUserReportsPage
