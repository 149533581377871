import React from 'react'

import EditStudioForm from '../../components/EditStudioForm/EditStudioForm.js'

import StudioApi from '../../api/Studio.js'

// writeme
class CreateStudioPage extends React.Component {
  constructor (props) {
    super(props)

    this.state = {}

    this.handleSubmit = this.handleSubmit.bind(this)
  }

  handleSubmit (params) {
    StudioApi.create(this.props.token, params).then(
      json => this.props.onPageChange('studios', json.id),
      this.props.onError
    )
  }

  render () {
    const e = React.createElement

    return e('div', { className: 'create-studio-page' },
      e(EditStudioForm, {
        onSubmit: this.handleSubmit,
        onCancel: () => this.props.onPageChange('studios')
      })
    )
  }
}

export default CreateStudioPage
