import React from 'react'
import './RecentReviewItem.css'

import localName from '../../helpers/localName'

// Represents a single item in the "latest reviews" box.
function RecentReviewItem (props) {
  return (
    <button className='recent-review-item' onClick={props.onClick}>
      <div className='recent-review-item-media-name'>
        {localName(props.review.media)} {props.review.media.adult ? '🔞' : ''}
      </div>
      <div className='recent-review-item-user-name'>
        {props.review.user.name}
      </div>
      <div className='recent-review-item-summary'>
        {props.review.summary}
      </div>
    </button>
  )
}

export default RecentReviewItem
