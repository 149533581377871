import React from 'react'

import MediaSearch from '../../components/MediaSearch/MediaSearch.js'

// General media search page.
function BrowsePage (props) {
  const e = React.createElement

  return (
    e(MediaSearch, {
      onError: props.onError,
      onClick: media => props.onPageChange('media', media.id)
    })
  )
}

export default BrowsePage
