import React from 'react'
import './UserMediaListItem.css'

import EditEntryForm from '../EditEntryForm/EditEntryForm.js'

import localName from '../../helpers/localName.js'

// Represents a single item on a list of user media.
class UserMediaListItem extends React.Component {
  constructor (props) {
    super(props)

    this.state = { expanded: false }

    this.handleExpand = this.handleExpand.bind(this)
    this.handleChange = this.handleChange.bind(this)
  }

  handleExpand () {
    this.setState({ expanded: !this.state.expanded })
  }

  handleChange (json) {
    this.setState({ expanded: false })
    this.props.onChange(json)
  }

  // TODO: Maybe move this into a separate "helper" file?
  entryProgress () {
    const entry = this.props.entry
    const media = entry.media

    if (entry.volumes) {
      const s = Number(media.volumes || entry.volumes) === 1 ? '' : 's'
      const kind = 'volume'
      return { kind: kind + s, current: entry.volumes, total: media.volumes }
    } else if (entry.episodes) {
      const s = Number(media.episodes || entry.episodes) === 1 ? '' : 's'
      const kind = entry.media.kind === 'manga' ? 'chapter' : 'episode'
      return { kind: kind + s, current: entry.episodes, total: media.episodes }
    }

    return {} // No progress (in either episodes/chapters or volumes) yet...
  }

  render () {
    const e = React.createElement

    const entry = this.props.entry

    const css = this.state.expanded ? 'on' : 'off'

    // This is relevant if a user is logged in and looking at their own page:
    const expandButton = this.props.allowEdit ? (
      e('button', {
        className: `user-media-list-item-expand ${css}`,
        onClick: this.handleExpand
      }, this.state.expanded ? 'Cancel' : 'Edit')
    ) : null

    // We don't want to show an editor ALL the time, so hide it until needed:
    const editor = this.state.expanded ? (
      e('div', { className: 'user-media-list-item-body' },
        e(EditEntryForm, {
          token: this.props.token,
          entry: entry,
          onError: this.props.onError,
          onChange: this.handleChange,
          onRemove: this.props.onRemove
        })
      )
    ) : null

    const progress = this.entryProgress()

    return (
      e('div', { className: 'user-media-list-item' },
        e('div', { className: 'user-media-list-item-head' },
          e('button', {
            className: 'user-media-list-item-name',
            onClick: () => this.props.onPageChange('media', entry.media.id)
          }, localName(entry.media)),
          e('div', { className: 'user-media-list-item-score' },
            entry.score
          ),
          e('div', { className: 'user-media-list-item-progress' },
            e('div', { className: 'current' }, progress.current),
            e('div', { className: 'total' }, progress.total),
            e('div', { className: 'kind' }, progress.kind)
          ),
          e('div', { className: 'user-media-list-item-notes' },
            entry.notes
          ),
          expandButton
        ),
        editor
      )
    )
  }
}

export default UserMediaListItem
