import React from 'react'

import MediaCard from '../MediaCard/MediaCard'
import PleaseWait from '../PleaseWait/PleaseWait'

import MediaApi from '../../api/Media'

// writeme
class AssociatedMediaList extends React.Component {
  constructor (props) {
    super(props)

    this.state = { associatedMedia: null }
  }

  componentDidMount () {
    MediaApi.associatedMedia(this.props.mediaId).then(
      json => this.setState({ associatedMedia: json }),
      this.props.onError
    )
  }

  render () {
    if (!this.state.associatedMedia) { return <PleaseWait /> }

    const items = this.state.associatedMedia.map(link => (
      <MediaCard
        key={link.id}
        media={link.media}
        association={link.kind}
        onClick={() => this.props.onClick(link.media)}
      />
    ))

    return (
      <div className='associated-media-list'>
        {items}
        {this.props.children}
      </div>
    )
  }
}

export default AssociatedMediaList
