/* eslint prefer-promise-reject-errors: 0 */

// A generic API call. Used internally by various other API "helper" classes.
function apiCall (verb, path, jwt, data) {
  const server = process.env.REACT_APP_API_SERVER

  return new Promise((resolve, reject) => {
    const xhr = new XMLHttpRequest()

    // GET requests have no body, so any params must be part of the URL:
    let url = [server, path].join('/')
    if (data instanceof URLSearchParams) { url = [url, data].join('?') }

    xhr.open(verb, url)
    if (jwt) { xhr.setRequestHeader('Authorization', jwt) }

    if (!data || data instanceof URLSearchParams) {
      xhr.send() // No request body; the params (if any) are part of the URL.
    } else if (data instanceof FormData) {
      xhr.send(data) // Binary file uploads (such as avatar images) use this.
    } else {
      xhr.setRequestHeader('Content-Type', 'application/json')
      xhr.send(JSON.stringify(data)) // Unlike FormData, this is simply text.
    }

    xhr.onload = () => {
      const json = JSON.parse(xhr.responseText || 'null')
      if (xhr.status < 200 || xhr.status >= 300) { reject(json) }

      const token = xhr.getResponseHeader('Authorization')
      resolve(token ? { ...json, token: token } : json)
    }

    // Mimic { "error": "You need to sign in or sign up before continuing." }
    xhr.onerror = () => reject({ error: 'Could not connect to server.' })
    xhr.onabort = () => reject({ error: 'Request aborted.' })
    xhr.ontimeout = () => reject({ error: 'Request timed out.' })
  })
}

export default apiCall
