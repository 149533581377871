import React from 'react'
import './EditMediaForm.css'

import SimpleButton from '../SimpleButton/SimpleButton'
import SimpleCheckbox from '../SimpleCheckbox/SimpleCheckbox'
import InputLabel from '../InputLabel/InputLabel'
import ImagePicker from '../ImagePicker/ImagePicker'
import MarkdownEditor from '../MarkdownEditor/MarkdownEditor'
import MediaNameForm from '../MediaNameForm/MediaNameForm'
import SimpleDropdown from '../SimpleDropdown/SimpleDropdown'
import SimpleInput from '../SimpleInput/SimpleInput'

import {
  animeSubtypes, mangaSubtypes, mediaCountries, mediaKinds, mediaStatuses
} from '../../helpers/commonEnums'

import StudioApi from '../../api/Studio'

// writeme
class EditMediaForm extends React.Component {
  constructor (props) {
    super(props)

    const names = props.media ? props.media.names.map(name => ({
      id: name.id,
      text: name.text,
      language: name.language,
      latin: name.latin || false,
      _destroy: false
    })) : [this.newName()]

    const media = props.media || {}

    this.state = {
      names: names,
      country: media.country || mediaCountries[0],
      status: media.status || mediaStatuses[0],
      startDate: media.startDate || '',
      endDate: media.endDate || '',
      episodes: media.episodes || '',
      volumes: media.volumes || '',
      anidbCode: media.anidbCode || '',
      anilistCode: media.anilistCode || '',
      animePlanetCode: media.animePlanetCode || '',
      kitsuCode: media.kitsuCode || '',
      malCode: media.malCode || '',
      adult: media.adult || false,
      kind: media.kind || mediaKinds[0],
      subtype: media.subtype || animeSubtypes[0],
      studioName: (media.studio || {}).name || '',
      studios: {},
      picture: null
    }

    this.handleAddName = this.handleAddName.bind(this)
    this.handleRemoveName = this.handleRemoveName.bind(this)

    this.handleNameTextChange = this.handleNameTextChange.bind(this)
    this.handleNameLanguageChange = this.handleNameLanguageChange.bind(this)
    this.handleNameLatinToggle = this.handleNameLatinToggle.bind(this)

    this.handleStudioChange = this.handleStudioChange.bind(this)
    this.handleCountryChange = this.handleCountryChange.bind(this)
    this.handleStatusChange = this.handleStatusChange.bind(this)
    this.handleStartDateChange = this.handleStartDateChange.bind(this)
    this.handleEndDateChange = this.handleEndDateChange.bind(this)
    this.handleEpisodesChange = this.handleEpisodesChange.bind(this)
    this.handleVolumesChange = this.handleVolumesChange.bind(this)
    this.handleAnidbCodeChange = this.handleAnidbCodeChange.bind(this)
    this.handleAnilistCodeChange = this.handleAnilistCodeChange.bind(this)
    this.handleAnimePlanetCodeChange = this.handleAnimePlanetCodeChange.bind(this)
    this.handleKitsuCodeChange = this.handleKitsuCodeChange.bind(this)
    this.handleMalCodeChange = this.handleMalCodeChange.bind(this)
    this.handlePictureChange = this.handlePictureChange.bind(this)
    this.handleAdultToggle = this.handleAdultToggle.bind(this)
    this.handleSubtypeChange = this.handleSubtypeChange.bind(this)
    this.handleKindChange = this.handleKindChange.bind(this)
    this.handleSubmit = this.handleSubmit.bind(this)
  }

  newName () {
    return {
      text: '',
      language: 'en',
      latin: false,
      _destroy: false
    }
  }

  componentDidMount () {
    StudioApi.all().then(
      json => {
        const nameToId = {}
        json.items.forEach(studio => { nameToId[studio.name] = studio.id })
        this.setState({ studios: nameToId })
      },
      this.props.onError
    )
  }

  handleAddName () {
    this.setState({ names: this.state.names.concat(this.newName()) })
  }

  handleRemoveName (index) {
    const newNames = this.state.names.map((name, num) => (
      index === num ? { ...name, _destroy: true } : name
    ))

    this.setState({ names: newNames })
  }

  handleNameTextChange (index, value) {
    const newNames = this.state.names.map((name, num) => (
      index === num ? { ...name, text: value } : name
    ))

    this.setState({ names: newNames })
  }

  handleNameLanguageChange (index, value) {
    const newNames = this.state.names.map((name, num) => (
      index === num ? { ...name, language: value, variant: '' } : name
    ))

    this.setState({ names: newNames })
  }

  handleNameLatinToggle (index) {
    const newNames = this.state.names.map((name, num) => (
      index === num ? { ...name, latin: !name.latin } : name
    ))

    this.setState({ names: newNames })
  }

  handleStudioChange (value) { this.setState({ studioName: value }) }
  handleCountryChange (value) { this.setState({ country: value }) }
  handleStatusChange (value) { this.setState({ status: value }) }
  handleStartDateChange (event) { this.setState({ startDate: event.target.value }) }
  handleEndDateChange (event) { this.setState({ endDate: event.target.value }) }
  handleEpisodesChange (event) { this.setState({ episodes: event.target.value }) }
  handleVolumesChange (event) { this.setState({ volumes: event.target.value }) }
  handleAnidbCodeChange (event) { this.setState({ anidbCode: event.target.value }) }
  handleAnilistCodeChange (event) { this.setState({ anilistCode: event.target.value }) }
  handleAnimePlanetCodeChange (event) { this.setState({ animePlanetCode: event.target.value }) }
  handleKitsuCodeChange (event) { this.setState({ kitsuCode: event.target.value }) }
  handleMalCodeChange (event) { this.setState({ malCode: event.target.value }) }
  handlePictureChange (file) { this.setState({ picture: file }) }
  handleAdultToggle () { this.setState({ adult: !this.state.adult }) }
  handleSubtypeChange (value) { this.setState({ subtype: value }) }

  handleKindChange (value) {
    if (value === 'manga') {
      this.setState({ kind: value, subtype: mangaSubtypes[0], anidbCode: '', studioName: '' })
    } else {
      this.setState({ kind: value, subtype: animeSubtypes[0], volumes: '' })
    }
  }

  handleSubmit (text) {
    const params = { ...this.state, about: text }

    // The API wants a studio ID, rather than a name, so:
    delete params.studios
    delete params.studioName
    params.studioId = this.state.studios[this.state.studioName] || ''

    // The API uses a different label for the "names" array:
    delete params.names
    params.namesAttributes = this.state.names

    // Only include a "picture" attribute if a new image has been specified:
    if (!params.picture) { delete params.picture }

    this.props.onSubmit(params)
  }

  render () {
    const about = (this.props.media || {}).about
    const formats = this.state.kind === 'anime' ? animeSubtypes : mangaSubtypes
    const episodesLabel = this.state.kind === 'manga' ? 'Chapters' : 'Episodes'

    const names = this.state.names.map((name, idx) => (
      <MediaNameForm
        key={idx}
        name={name}
        onTextChange={(e) => this.handleNameTextChange(idx, e.target.value)}
        onLanguageChange={(value) => this.handleNameLanguageChange(idx, value)}
        onLatinToggle={() => this.handleNameLatinToggle(idx)}
        onRemove={() => this.handleRemoveName(idx)}
      />
    ))

    return (
      <div className='edit-media-form'>
        <div className='edit-media-form-picture'>
          <ImagePicker
            width={240}
            height={360}
            fileSize='3MB'
            onChange={this.handlePictureChange}
          />
        </div>
        <div className='edit-media-form-name'>
          <InputLabel label='Names'>
            <div className='edit-media-form-name-list'>{names}</div>
          </InputLabel>
        </div>
        <div className='edit-media-form-kind'>
          <InputLabel label='Kind'>
            <SimpleDropdown
              value={this.state.kind}
              options={mediaKinds}
              onChange={this.handleKindChange}
            />
          </InputLabel>
        </div>
        <div className='edit-media-form-subtype'>
          <InputLabel label='Format'>
            <SimpleDropdown
              value={this.state.subtype}
              options={formats}
              onChange={this.handleSubtypeChange}
            />
          </InputLabel>
        </div>
        <div className='edit-media-form-country'>
          <InputLabel label='Country'>
            <SimpleDropdown
              value={this.state.country}
              options={mediaCountries}
              onChange={this.handleCountryChange}
            />
          </InputLabel>
        </div>
        <div className='edit-media-form-studio'>
          <InputLabel label='Studio'>
            <SimpleDropdown
              value={this.state.studioName}
              options={Object.keys(this.state.studios)}
              allowClear={true}
              disabled={this.state.kind !== 'anime'}
              onChange={this.handleStudioChange}
            />
          </InputLabel>
        </div>
        <div className='edit-media-form-status'>
          <InputLabel label='Status'>
            <SimpleDropdown
              value={this.state.status}
              options={mediaStatuses}
              onChange={this.handleStatusChange}
            />
          </InputLabel>
        </div>
        <div className='edit-media-form-start-date'>
          <InputLabel label='Start date'>
            <SimpleInput
              type='date'
              value={this.state.startDate}
              onChange={this.handleStartDateChange}
            />
          </InputLabel>
        </div>
        <div className='edit-media-form-end-date'>
          <InputLabel label='End date'>
            <SimpleInput
              type='date'
              value={this.state.endDate}
              onChange={this.handleEndDateChange}
            />
          </InputLabel>
        </div>
        <div className='edit-media-form-episodes'>
          <InputLabel label={episodesLabel}>
            <SimpleInput
              type='number'
              min={1}
              value={this.state.episodes}
              onChange={this.handleEpisodesChange}
            />
          </InputLabel>
        </div>
        <div className='edit-media-form-volumes'>
          <InputLabel label='Volumes'>
            <SimpleInput
              type='number'
              min={1}
              value={this.state.volumes}
              disabled={this.state.kind !== 'manga'}
              onChange={this.handleVolumesChange}
            />
          </InputLabel>
        </div>
        <div className='edit-media-form-site-anidb'>
          <InputLabel label='AniDB ID'>
            <SimpleInput
              disabled={this.state.kind !== 'anime'}
              value={this.state.anidbCode}
              onChange={this.handleAnidbCodeChange}
            />
          </InputLabel>
        </div>
        <div className='edit-media-form-site-anilist'>
          <InputLabel label='Anilist ID'>
            <SimpleInput
              value={this.state.anilistCode}
              onChange={this.handleAnilistCodeChange}
            />
          </InputLabel>
        </div>
        <div className='edit-media-form-site-anime-planet'>
          <InputLabel label='Anime Planet ID'>
            <SimpleInput
              value={this.state.animePlanetCode}
              onChange={this.handleAnimePlanetCodeChange}
            />
          </InputLabel>
        </div>
        <div className='edit-media-form-site-kitsu'>
          <InputLabel label='Kitsu ID'>
            <SimpleInput
              value={this.state.kitsuCode}
              onChange={this.handleKitsuCodeChange}
            />
          </InputLabel>
        </div>
        <div className='edit-media-form-site-mal'>
          <InputLabel label='My Anime List ID'>
            <SimpleInput
              value={this.state.malCode}
              onChange={this.handleMalCodeChange}
            />
          </InputLabel>
        </div>
        <div className='edit-media-form-about'>
          <InputLabel label='Description'>
            <MarkdownEditor initialText={about} onSubmit={this.handleSubmit}>
              <div className='edit-media-form-markdown-editor-footer'>
                <SimpleCheckbox
                  label='18+ Content'
                  checked={this.state.adult}
                  onChange={this.handleAdultToggle}
                />
                <SimpleButton theme='create' onClick={this.handleAddName}>
                  Add name
                </SimpleButton>
                <SimpleButton onClick={this.props.onCancel}>
                  Cancel
                </SimpleButton>
              </div>
            </MarkdownEditor>
          </InputLabel>
        </div>
      </div>
    )
  }
}

export default EditMediaForm
