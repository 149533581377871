import React from 'react'
import './StaffPage.css'

import MarkdownContent from '../../components/MarkdownContent/MarkdownContent'
import PleaseWait from '../../components/PleaseWait/PleaseWait'
import RemoteImage from '../../components/RemoteImage/RemoteImage'
import SimpleButton from '../../components/SimpleButton/SimpleButton'

import canEdit from '../../helpers/canEdit'

import StaffApi from '../../api/Staff'

// Displays a staff.
class StaffPage extends React.Component {
  constructor (props) {
    super(props)

    this.state = { staff: null }

    this.handleDelete = this.handleDelete.bind(this)
  }

  componentDidMount () {
    StaffApi.show(this.props.id).then(
      json => this.setState({ staff: json }),
      this.props.onError
    )
  }

  handleDelete () {
    const message = 'Are you sure you want to delete this person?'
    if (!window.confirm(message)) { return }

    StaffApi.destroy(this.props.token, this.props.id).then(
      () => this.props.onPageChange('staff'),
      this.props.onError
    )
  }

  render () {
    if (!this.state.staff) { return <PleaseWait /> }

    const goTo = this.props.onPageChange

    const actions = canEdit(this.props.currentUser, this.state.staff) ? (
      <div className='staff-page-actions'>
        <SimpleButton theme='primary' onClick={() => goTo('edit-staff', this.props.id)}>
          Edit
        </SimpleButton>
        <SimpleButton theme='delete' onClick={this.handleDelete}>
          Delete
        </SimpleButton>
      </div>
    ) : null

    return (
      <div className='staff-page'>
        {actions}
        <RemoteImage
          className='staff-page-image'
          remotePath={this.state.staff.picture}
          fallback='no-thumbnail.png'
          alt='Thumbnail'
        />
        <div className='staff-page-name'>
          {this.state.staff.name}
        </div>
        <div className='staff-page-content'>
          <MarkdownContent text={this.state.staff.about} />
        </div>
      </div>
    )
  }
}

export default StaffPage
