import apiCall from './apiCall.js'
import buildFormData from './buildFormData.js'

// Handles all profile-change requests.
class Profile {
  static show (token) {
    return apiCall('GET', 'api/v1/profile', token)
  }

  static update (token, params) {
    const data = buildFormData('user', params)
    return apiCall('PATCH', 'api/v1/profile', token, data)
  }
}

export default Profile
