import React from 'react'
import './NavBar.css'

import Avatar from '../Avatar/Avatar.js'
import LocalImage from '../LocalImage/LocalImage.js'
import SimpleButton from '../SimpleButton/SimpleButton.js'
import NotificationIcon from '../NotificationIcon/NotificationIcon.js'
import UserReportIcon from '../UserReportIcon/UserReportIcon.js'

function NavBar (props) {
  const e = React.createElement

  const actions = props.currentUser ? (
    e('div', { className: 'user-actions' },
      props.currentUser.administrator ? (
        e(UserReportIcon, {
          token: props.token,
          onError: props.onError,
          onClick: () => props.onPageChange('user-reports')
        })
      ) : null,
      e('button', {
        className: 'current-user',
        onClick: () => props.onPageChange('users', props.currentUser.name)
      },
      e(Avatar, { user: props.currentUser }),
      e('div', { className: 'name' }, props.currentUser.name)
      ),
      e(NotificationIcon, {
        token: props.token,
        onError: props.onError,
        onPageChange: props.onPageChange
      })
    )
  ) : (
    e('div', { className: 'user-actions' },
      e(SimpleButton, {
        theme: 'primary',
        onClick: () => props.onPageChange('create-account')
      }, 'Create account'),
      e(SimpleButton, {
        theme: 'primary',
        onClick: () => props.onPageChange('log-in')
      }, 'Log in')
    )
  )

  return (
    e('div', { className: 'nav-bar' },
      e('button', { className: 'brand', onClick: () => props.onPageChange() },
        e(LocalImage, { src: 'logo.png', alt: 'GYARU' })
      ),
      e('div', { className: 'links' },
        e('button', { className: 'link', onClick: () => props.onPageChange() },
          'Social'
        ),
        e('button', { className: 'link', onClick: () => props.onPageChange('media') },
          'Media'
        ),
        e('button', { className: 'link', onClick: () => props.onPageChange('characters') },
          'Characters'
        ),
        e('button', { className: 'link', onClick: () => props.onPageChange('staff') },
          'People'
        ),
        e('button', { className: 'link', onClick: () => props.onPageChange('studios') },
          'Studios'
        ),
        e('button', { className: 'link', onClick: () => props.onPageChange('reviews') },
          'Reviews'
        )
      ),
      actions
    )
  )
}

export default NavBar
