import React from 'react'
import './ChangeEmailForm.css'

import SimpleButton from '../SimpleButton/SimpleButton.js'
import InputLabel from '../InputLabel/InputLabel.js'
import SimpleInput from '../SimpleInput/SimpleInput.js'

import AccountApi from '../../api/Account.js'

// writeme
class ChangeEmailForm extends React.Component {
  constructor (props) {
    super(props)

    this.state = {
      email: props.currentUser.email || '',
      password: ''
    }

    this.handleEmailChange = this.handleEmailChange.bind(this)
    this.handlePasswordChange = this.handlePasswordChange.bind(this)
    this.handleSubmit = this.handleSubmit.bind(this)
  }

  handleEmailChange (event) {
    this.setState({ email: event.target.value })
  }

  handlePasswordChange (event) {
    this.setState({ password: event.target.value })
  }

  handleSubmit (event) {
    event.preventDefault()

    const params = {
      email: this.state.email,
      current_password: this.state.password
    }

    AccountApi.update(this.props.token, params).then(
      () => {
        this.setState({ password: '' })
        this.props.onChange({ ...this.props.currentUser, email: params.email })
      },
      this.props.onError
    )
  }

  render () {
    const e = React.createElement

    return (
      e('form', { className: 'change-email-form', onSubmit: this.handleSubmit },
        e('div', { className: 'change-email-form-email' },
          e(InputLabel, { label: 'Email address' },
            e(SimpleInput, {
              type: 'email',
              value: this.state.email,
              onChange: this.handleEmailChange
            })
          )
        ),
        e('div', { className: 'change-email-form-password' },
          e(InputLabel, { label: 'Confirm password' },
            e(SimpleInput, {
              type: 'password',
              value: this.state.password,
              onChange: this.handlePasswordChange
            })
          )
        ),
        e('div', { className: 'change-email-form-submit' },
          e(SimpleButton, {
            theme: 'primary',
            disabled: this.state.email === this.props.currentUser.email
          }, 'Save')
        )
      )
    )
  }
}

export default ChangeEmailForm
